import React from "react";
import { bmiValues, CHALLENGE, ChallengeTeamdashboardStatus } from "../challengeConstants";
import './BmiBar.scss';

const BmiBar = ({
    teamDashboardStatus,
    bmiValue,
    bmiMinValue = bmiValues.minValue,
    bmiMaxValue = bmiValues.maxValue,
    bmiClassname,
    bmiTitle
}) => {
    return (
        <div className="bmi-range-slider">
            <fieldset className="slider-container">
                <input type="range" id="input-slider" className={`input-slider ${teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team ? 'disable-slider' : 'active-slider'}`} min={bmiMinValue} max={bmiMaxValue}
                    step="1" value={bmiValue} />
            </fieldset>
            <div className="bmi-slider-value">
                <label htmlFor="input-slider" className={`bmi-slider-count ${teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team ? 'disable-color' : `${bmiClassname}`}`}>
                    {bmiValue}
                </label>
                <span className={`bmi-slider-unit ${teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team ? 'disable-color' : 'active-color'}`}>{`${bmiTitle}`}</span>
            </div>
        </div>
    )
}

export default BmiBar;
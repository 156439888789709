import { BUDDY_ME_BADGES_MILESTONE } from "../../challengeConstants";
const points = [
  {
    index: 0,
    top: "51",
    left: "43.5",
    subPoint: [
      { top: "51", left: "43.5" },
      { top: "51", left: "43.5" },
      { top: "51", left: "43.5" },
    ],
    type: "main",
    label: "amway",
    totalStep: 0,
  },
  {
    index: 1,
    top: '50.5',
    left: '38.5',
    subPoint: [
      { top: "50.5", left: "39.4" },
      { top: "51.3", left: "40.3" },
      { top: "52", left: "41" },
    ],
    type: "pin",
    totalStep: 500,
  },
  {
    index: 2,
    top: "37",
    left: "36.5",
    subPoint: [
      { top: "37", left: "36.5" },
      { top: "37", left: "36.5" },
      { top: "37", left: "36.5" },
    ],
    type: "main",
    label: BUDDY_ME_BADGES_MILESTONE.badge1,
    totalStep: 100000,
    badgeY: 78,
    badgeX: 54,
    height: 170,
  },
  {
    index: 3,
    top: "49.5",
    left: "28.5",
    subPoint: [
      { top: "49.8", left: "29.1" },
      { top: "50.2", left: "30" },
      { top: "50.2", left: "30.8" },
    ],
    type: "pin",
    totalStep: 100500,
  },
  {
    index: 4,
    top: "42.5",
    left: "27.8",
    subPoint: [
      { top: "44.2", left: "27.8" },
      { top: "46", left: "27.8" },
      { top: "47.5", left: "28" },
    ],
    type: "pin",
    totalStep: 133000,
  },
  {
    index: 5,
    top: "35.5",
    left: "27.8",
    subPoint: [
      { top: "37.5", left: "27.8" },
      { top: "39.5", left: "27.8" },
      { top: "41.5", left: "27.8" },
    ],
    type: "pin",
    totalStep: 166000,
  },
  {
    index: 6,
    top: "18",
    left: "33.45",
    subPoint: [
      { top: "18", left: "33.45" },
      { top: "18", left: "33.45" },
      { top: "18", left: "33.45" },
    ],
    type: "main",
    label: BUDDY_ME_BADGES_MILESTONE.badge2,
    totalStep: 200000,
    badgeY: 113,
    badgeX: 52,
    height: 200,
  },
  {
    index: 7,
    top: "20.2",
    left: "25.8",
    subPoint: [
      { top: "20.8", left: "26.8" },
      { top: "22.6", left: "27.7" },
      { top: "24.5", left: "27.9" },
    ],
    type: "pin",
    totalStep: 200500,
  },
  {
    index: 8,
    top: "20.2",
    left: "22.5",
    subPoint: [
      { top: "20.2", left: "23.5" },
      { top: "20.2", left: "24.7" },
      { top: "20.2", left: "25.8" },
    ],
    type: "pin",
    totalStep: 233000,
  },
  {
    index: 9,
    top: "20.2",
    left: "18.5",
    subPoint: [
      { top: "20.2", left: "19.7" },
      { top: "20.2", left: "21" },
      { top: "20.2", left: "22.2" },
    ],
    type: "pin",
    totalStep: 266000,
  },
  {
    index: 10,
    top: "9.5",
    left: "12.9",
    subPoint: [
      { top: "9.5", left: "12.9" },
      { top: "9.5", left: "12.9" },
      { top: "9.5", left: "12.9" },
    ],
    type: "main",
    label: BUDDY_ME_BADGES_MILESTONE.badge3,
    totalStep: 300000,
    badgeY: 78,
    badgeX: 54,
    height: 180,
  },
  {
    index: 11,
    top: "6.2",
    left: "14.5",
    subPoint: [
      { top: "8.2", left: "13.7" },
      { top: "10.2", left: "13.7" },
      { top: "12.2", left: "13.7" },
    ],
    type: "pin",
    totalStep: 300500,
  },
  {
    index: 12,
    top: "4.2",
    left: "22",
    subPoint: [
      { top: "4.2", left: "20" },
      { top: "4.2", left: "18" },
      { top: "4.2", left: "16" },
    ],
    type: "pin",
    totalStep: 320000,
  },
  {
    index: 13,
    top: "15.2",
    left: "24.5",
    subPoint: [
      { top: "12.2", left: "24.5" },
      { top: "9.2", left: "24.5" },
      { top: "6.2", left: "24.5" },
    ],
    type: "pin",
    totalStep: 340000,
  },
  {
    index: 14,
    top: "21.2",
    left: "30.3",
    subPoint: [
      { top: "17.5", left: "30" },
      { top: "17", left: "28" },
      { top: "17", left: "26" },
    ],
    type: "pin",
    totalStep: 360000,
  },
  {
    index: 15,
    top: "27",
    left: "46",
    subPoint: [
      { top: "27", left: "43.5" },
      { top: "27", left: "41" },
      { top: "27", left: "38.5" },
    ],
    type: "pin",
    totalStep: 380000,
  },
  {
    index: 16,
    top: "20.5",
    left: "51.5",
    subPoint: [
      { top: "20.5", left: "51.5" },
      { top: "20.5", left: "51.5" },
      { top: "20.5", left: "51.5" },
    ],
    type: "main",
    label: BUDDY_ME_BADGES_MILESTONE.badge4,
    totalStep: 400000,
    badgeY: 72,
    badgeX: 71,
    height: 170,
  },
  {
    index: 17,
    top: "31",
    left: "62.2",
    subPoint: [
      { top: "29", left: "62" },
      { top: "27.5", left: "60.9" },
      { top: "27.5", left: "59.5" },
    ],
    type: "pin",
    totalStep: 400500,
  },
  {
    index: 18,
    top: "40",
    left: "62.2",
    subPoint: [
      { top: "37.5", left: "62.2" },
      { top: "35", left: "62.2" },
      { top: "32.5", left: "62.2" },
    ],
    type: "pin",
    totalStep: 433000,
  },
  {
    index: 19,
    top: "50",
    left: "62.2",
    subPoint: [
      { top: "47.5", left: "62.2" },
      { top: "45", left: "62.2" },
      { top: "42.5", left: "62.2" },
    ],
    type: "pin",
    totalStep: 466000,
  },
  {
    index: 20,
    top: "50.2",
    left: "64.7",
    subPoint: [
      { top: "50.2", left: "64.7" },
      { top: "50.2", left: "64.7" },
      { top: "50.2", left: "64.7" },
    ],
    type: "main",
    label: BUDDY_ME_BADGES_MILESTONE.badge5,
    totalStep: 500000,
    badgeY: 117,
    badgeX: 54,
    height: 200,
  },
  {
    index: 21,
    top: "72.1",
    left: "67.2",
    subPoint: [
      { top: "72", left: "64.9" },
      { top: "71", left: "62.5" },
      { top: "67.5", left: "62.3" },
    ],
    type: "pin",
    totalStep: 500500,
  },
  {
    index: 22,
    top: "72",
    left: "77.5",
    subPoint: [
      { top: "72", left: "75" },
      { top: "72", left: "72.5" },
      { top: "72", left: "70" },
    ],
    type: "pin",
    totalStep: 525000,
  },
  {
    index: 23,
    top: "71",
    left: "86.5",
    subPoint: [
      { top: "72", left: "84" },
      { top: "72", left: "81" },
      { top: "72", left: "78" },
    ],
    type: "pin",
    totalStep: 550000,
  },
  {
    index: 24,
    top: "58",
    left: "86.5",
    subPoint: [
      { top: "62", left: "86.5" },
      { top: "66", left: "86.5" },
      { top: "70", left: "86.5" },
    ],
    type: "pin",
    totalStep: 575000,
  },
  {
    index: 25,
    top: '43.2',
    left: '92',
    subPoint: [
      { top: "43.2", left: "92" },
      { top: "43.2", left: "92" },
      { top: "43.2", left: "92" },
    ],
    type: "main",
    label: BUDDY_ME_BADGES_MILESTONE.badge6,
    totalStep: 600000,
    badgeY: 58,
    badgeX: -100,
    height: 200,
  },
];

export default points;

import { commonConstant } from '../common';
import config from '../config';
import axios from 'axios';
import { fetchLbcTokenApi } from './promotion/auth.service';

export const activityLogService = {
    createActivityLog
}

async function createActivityLog(activityLog){
    const tokenResponse = await fetchLbcTokenApi({});
    const activitylog =  await axios.post(`${config.API_URL}/activitylogs`, {
        activityTag: activityLog.activityTag,
        actionType: activityLog.actionType,
        userType: activityLog.userType,
        mid: activityLog.mid,
        displayName: activityLog.displayName,
        amwayNumber: activityLog.amwayNumber
    },{
        headers: {
          'Authorization': `Bearer ${tokenResponse?.token}`
        }
      })
        .catch((e) => {
            if(e.response){
                return e.response;
            }
            else{
                return e.message;
            }
        });
    return activitylog;
}
import React, { useState } from 'react';
import './HomeStepsCard.scss';
import {
  HOME_GOAL_CARD,
  HOME_STEPS_CARD,
} from '../../../../common/logListDataDummy/logListData';
import StepsCard from '../../../../common/stepsCard/StepsCard';
import { useHistory } from 'react-router-dom';
import { commonConstant } from '../../../../common';
import { useSelector } from 'react-redux';
import {
  getCalorieByTotalSteps,
  getDistanceCovered,
} from '../../../../utill.func';

const TARGET_STEP = 10000;
const HomeStepsCard = ({
  exerciseLogs,
  bodyKeyId,
  handleAutoSyncToggle,
}) => {
  const history = useHistory();
  const [stepsCardCollapse, setstepsCardsCollapse] = useState(true);
  const { bodyKeyRecords } = useSelector(
    (state) => state.challengeBodyKey,
  );
  const stackedClass = !stepsCardCollapse ? 'stacked' : '';
  const collapsedView = stepsCardCollapse ? 'collapse' : '';
  const exerciseStacks = exerciseLogs?.length === 1 ? 'exercise-stack' : '';
  const exerciseStack = exerciseLogs?.length === 2 ? 'exercise-stack-2' : '';
  const redirectToStepsScreen = () => {
    if (bodyKeyId) {
      history.push(commonConstant.pathHealthnWellnessAutoSyncStep);
      handleAutoSyncToggle(false);
    } else {
      handleAutoSyncToggle(true);
    }
  };
  const handleCardCollapse = () => {
    stackedClass !== 'stacked'
      ? setstepsCardsCollapse(!stepsCardCollapse)
      : history.push(commonConstant.pathHnwExerciseLogHome);
  };

  const handleArrowCollapse = () => {
    setstepsCardsCollapse(!stepsCardCollapse);
  };

  const redirectToExerciseLogHome = () => {
    history.push(commonConstant.pathHnwExerciseLogHome);
  };

  const accSteps =
    bodyKeyRecords?.length &&
    bodyKeyRecords.reduce(
      (previousValue, currentValue) => {
        previousValue.stepCount += parseInt(currentValue?.stepCount);
        previousValue.stepKcal += getCalorieByTotalSteps(
          currentValue?.stepCount,
          2,
        );
        previousValue.stepDistance += getDistanceCovered(
          currentValue?.stepCount,
          2,
        );
        return previousValue;
      },
      {
        stepCount: 0,
        stepKcal: 0,
        stepDistance: 0,
      },
    );

  // percentage
  const accumulatedSteps = {
    ...accSteps,
    perCount: (parseInt(accSteps.stepCount) * 100) / TARGET_STEP || 0,
  };

  return (
    <div className="home-steps-container">

      <div className="vertical-container">
        <ul
          className={`card-stacks ${stackedClass} ${exerciseStacks} ${exerciseStack}`}
        >
          <li className="stack stack-1">
            <ul className="cards-down">
              {HOME_STEPS_CARD(accumulatedSteps).map((data, index) => (
                <li
                  className={`card card-1`}
                  onClick={redirectToStepsScreen}
                  key={`HOME_STEPS_CARD_${index}`}
                >
                  <StepsCard
                    data={data}
                    collapsedView={collapsedView}
                    id="steps"
                  />
                </li>
              ))}

              {HOME_GOAL_CARD(exerciseLogs).map((data, index) => (
                <li
                  className={`card card-${index + 2}`}
                  onClick={
                    stepsCardCollapse
                      ? handleCardCollapse
                      : redirectToExerciseLogHome
                  }
                  key={`HOME_GOAL_CARD_${index}`}
                >
                  <StepsCard
                    data={data}
                    exerciseGoal
                    id={`exercise-${index}`}
                  />
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <div className="slide-up" id="slideUpButton">
          <img
            src="/images/healthAndWellness/icon/chevron-up.svg"
            alt=""
            onClick={handleArrowCollapse}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeStepsCard;

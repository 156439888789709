import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";

const FoodBadgeDetail = () => <div>
  <p>
    {CHALLENGE.badges.FoodMissionBadgeHeading}<br />
    {CHALLENGE.badges.FoodCalorieSubHeading}
  </p>
  <p className="badge-note">{CHALLENGE.badges.FoodNoteText}
  </p>
</div>

export default FoodBadgeDetail;
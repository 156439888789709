import React from 'react';
import { CustomCalendar } from '../../../../common';
import './ChallengeCalendar.scss';

const ChallengeCalendar = ({ data, ...props }) => {
  const dataArray = data?.map((dailyLog) => (
    { data1: 100, date: new Date(dailyLog.date), isInverted: dailyLog?.status }
  )) || [];

  return <CustomCalendar
    showNavigation
    colorCircledark="#8AC440"
    colorCirclelight="#8AC440"
    data={dataArray} {...props} />
}

export default ChallengeCalendar

import React, { useEffect, useState } from "react";
import "./ChallengeVideoCard.scss";
import { ChallengeVideoStatus, rectBadgeClassname, VIDEO_CARD_TEXT } from "../../../../common/challengeConstants";
import { formatVideoDuration, convertVideoDurationToSeconds, convertMinutesToSeconds, calculateVideoStatusTag } from "../../../../utill.func";
import { RectBadge } from "../../../../common";

const ChallengeVideoCard = ({ videoData, openModal }) => {

  const [progressPercentage, setProgressPercentage] = useState(0);
  const [videoStatusTag, setVideoStatusTag] = useState(ChallengeVideoStatus.yet_to_watch);

  const stoppedAtInSeconds = convertMinutesToSeconds(videoData?.stoppedAt);
  const totalDurationInSeconds = convertVideoDurationToSeconds(videoData?.youtubeDetails?.duration);

  const duration = formatVideoDuration(videoData?.youtubeDetails?.duration);
  const duration_text = VIDEO_CARD_TEXT.duration_text;
  const videoProgress = videoData?.stoppedAt !== 0 ? 'bg-fill' : 'bg-unfill';

  useEffect(() => {
    const vdoPercent = Math.round((stoppedAtInSeconds/totalDurationInSeconds) * 100);
    setProgressPercentage(vdoPercent);
    if(!videoData?.stoppedAt) {
      setProgressPercentage(100);
    }
  }, [stoppedAtInSeconds, totalDurationInSeconds, videoData?.stoppedAt])

  useEffect(() => {
    const videoTag  = calculateVideoStatusTag(videoData?.status, videoData?.stoppedAt);
    setVideoStatusTag(videoTag);
  }, [videoData?.status, videoData?.stoppedAt]);

  const handleClick = () => {
    openModal(
      videoData?.videoId );
  };

  return (
    <>
      <div className="quiz-card-single" onClick={handleClick}>
        <div className="quiz-img-container">
          <img
            src={videoData?.youtubeDetails?.thumbnail}
            className="youtube-cover-img"
            alt="Video Thumbnail"
          />
          <div className="play-icon">
            <img src={VIDEO_CARD_TEXT.play_icon_url} className="play-img" alt="Play Icon" />
          </div>
          <div className="video-status-tag-wrapper">
            <RectBadge badgeClassname={rectBadgeClassname[videoStatusTag]}>
              <div className='rect-badge-title'>{rectBadgeClassname[videoStatusTag]?.title}</div>
            </RectBadge>
          </div>
        </div>
        <div className="quiz-card-description">
            <div className="progress">
              <div
                className={`progress-bar ${videoProgress}`}
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          <div className="quiz-card-title">{`${videoData?.youtubeDetails?.title}`}</div>
          <div className="quiz-card-time">
            <span className="quiz-duration">{duration}</span><span>{` ${duration_text}`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeVideoCard;

import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import commonConstant from '../../../common/commonConstant';
import { PlacePicker, SelectorAddress } from '../../../helpers';
import {
  createAddress, getAddress,
  getDistrict,
  getProvince,
  getSubdistrict
} from '../../../services/promotion/address.service';
import { INSTALLMENT_TYPE, message } from '../../../services/promotion/utils.service';
import './CreatePromotionAddress.scss';
import { liffGetProfile } from '../../../services/promotion/auth.service';

export const history = createBrowserHistory();
const { pathPromotionAddressList, pathCartPromotion } = commonConstant;

const { liff } = window;

const GeocodeapiKey = process.env.REACT_APP_GOOGLE_API_GEOCODE_KEY;
//! Waiting Google Api Key
const defaultLang = 'th';
const defaultRegion = 'th';
Geocode.setApiKey(GeocodeapiKey);
Geocode.enableDebug();

export const CreatePromotionAddress = (props) => {
  const [contactPersonName, setContactPersonName] = useState('');
  const [addr, setAddr] = useState('');
  const [province, setProvice] = useState('');
  const [district, setDistrict] = useState('');
  const [subDistrict, setSubDistrict] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [openMap, setOpenMap] = useState(false);
  const [provinceList, setProvicesList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isNotAllField, setIsNotAllField] = useState(true);
  const [phone, setPhone] = useState('');
  const [redirect, setRedirect] = useState();
  const [addressList, setAddressList] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [validatePhone, setValidatePhone] = useState(false)
  const [showModal, setShowModal] = useState();
  const [validateName, setValidateName] = useState(false);

  const genlistData = (data) => {
    const list = [];
    data.map((item) =>
      list.push({ value: item.thName, label: item.thName, data: item }),
    );
    return list;
  };

  const genlistDidtrictData = (data) => {
    const list = [];
    data.map((item) =>
      list.push({
        value: item.thName,
        label: (
          <div>
            <span style={{ marginRight: '10px' }}>{item.thName}</span>
            <span
              style={{
                color: '#c5c9d3',
                textAlign: 'end',
                fontSize: '0.5em',
              }}
            >
              {item.province.thName}
            </span>
          </div>
        ),
        data: item,
      }),
    );
    return list;
  };

  const genlistSubdistrictData = (data) => {
    const list = [];
    data.map((item) =>
      list.push({
        value: item.thName,
        label: (
          <div>
            <span style={{ marginRight: '10px' }}>{item.thName}</span>
            <span
              style={{
                color: '#c5c9d3',
                textAlign: 'end',
                fontSize: '0.5em',
              }}
            >
              {item.district.thName},{item.province.thName}
            </span>
          </div>
        ),
        data: item,
      }),
    );
    return list;
  };

  useEffect(() => {
    fetchPreData();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (subDistrict) {
      const districtData = subDistrict.district;
      const provinceData = subDistrict.province;
      if (districtData && provinceData) {
        fillDataAll(districtData, provinceData, subDistrict.zipcode);
      }
    }
  }, [subDistrict]);

  useEffect(() => {
    if (district) {
      const provinceData = district.province;
      fillProvince(provinceData);
    }
  }, [district]);

  useEffect(() => {
    if (
      province &&
      district &&
      subDistrict &&
      addr?.length > 0 &&
      contactPersonName?.length > 0 &&
      phone?.length === 10 &&
      validatePhone === false &&
      validateName === false
    ) {
      setIsNotAllField(false);
    } else {
      setIsNotAllField(true);
    }
  }, [province, district, subDistrict, addr, contactPersonName, phone, validatePhone, validateName]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      const addrArr = addr.split(' ');
      const data = {
        contactPersonName,
        line1: addrArr.slice(0, 4).join(' '),
        line2: addrArr.slice(4, 8).join(' '),
        line3: addrArr.slice(8, 12).join(' '),
        line4: addrArr.slice(12, addrArr.length).join(' '),
        province: province.thName,
        district: district.thName,
        subDistrict: subDistrict.thName,
        zipcode,
        fullAddress: `${addr} ${subDistrict.thName} ${district.thName} ${province.thName} ${zipcode}`,
        refProvince: province.refCode,
        phone,
      };
      const haveAddressAlready = addressList.find(
        (addr) =>
          addr.fullAddress === data.fullAddress &&
          addr.contactPersonName === data.contactPersonName &&
          addr.phone === data.phone,
      );
      if (haveAddressAlready) {
        alert(message.error.alreadyAddress);
        setLoading(false);
      } else {
        if (customerData) {
          sessionStorage.setItem('address', JSON.stringify(data));
          setRedirect(pathCartPromotion);
        } else {
          const urlParams = queryString.parse(props.location.search);
          const result = await createAddress(data);
          let path = pathPromotionAddressList;
          if(urlParams && (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) && (urlParams.cartId)) {
            path = `${pathPromotionAddressList}?isInstallment=${urlParams.isInstallment}&cartId=${urlParams.cartId}`
          }
          if(result.success) {
            return window.location.href = path;
          }
          // result.success && setRedirect(path);
        }
      }
    } catch (error) {
      alert(message.error.somethingWrong);
      console.log(error);
    }
  };

  const fetchPreData = async () => {
    const province = await fetchProvice('');
    const district = await fetchDistrict('');
    const subdistrict = await fetchSubdistrict('');
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    if (customerFOA) {
      setCustomerData(customerFOA);
    } else {
      await fetchAddress();
    }
    if (province && district && subdistrict) {
      setLoading(false);
    }
  };

  const fetchProvice = async (keyword) => {
    const newProvinceData = await getProvince(keyword);
    const newProvinces = newProvinceData && genlistData(newProvinceData.data);
    setProvicesList(newProvinces);
    return newProvinces;
  };

  const fetchDistrict = async (keyword) => {
    const newDistrictData = await getDistrict(keyword);
    const newDistrict =
      newDistrictData && genlistDidtrictData(newDistrictData.data);
    setDistrictList(newDistrict);
    return newDistrict;
  };

  const fetchSubdistrict = async (keyword) => {
    const newSubdistrictData = await getSubdistrict(keyword);
    const newSubdistrict =
      newSubdistrictData && genlistSubdistrictData(newSubdistrictData.data);
    setSubdistrictList(newSubdistrict);
    return newSubdistrict;
  };

  const fetchAddress = async () => {
    const lineProfile = await liffGetProfile();
    const { data } = await getAddress(lineProfile.userId);
    setAddressList(data);
    return data;
  };

  const fillDataAll = async (district, province, zipcode) => {
    const districtNewList = await fetchDistrict(district.thName);
    if (districtNewList) setDistrict(district);
    const provinceNewList = await fetchProvice(province.thName);
    if (provinceNewList) setProvice(province);
    setZipcode(zipcode);
    setLoading(false);
  };

  const fillProvince = async (province) => {
    const provinceNewList = await fetchProvice(province.thName);
    if (provinceNewList) setProvice(province);
  };

  const getValueByType = (addressComponent, type) => {
    return addressComponent.find((obj) => obj.types.indexOf(type) !== -1);
  };

  const getStreetByMap = (addressComponent) => {
    const street = getValueByType(addressComponent, 'street_number');
    const route = getValueByType(addressComponent, 'route');
    return `${street?.long_name || ''} ${route?.long_name && route.long_name !== 'Unnamed Road'
      ? route.long_name
      : ''
      }`.trim();
  };

  const getSubDistrictByMap = (addressComponent) => {
    const sub = addressComponent.find(
      (obj) => obj.types.indexOf('sublocality_level_2') !== -1,
    );
    const sublocal = addressComponent.find(
      (obj) => obj.types.indexOf('sublocality_level_1') !== -1,
    );
    const locality = addressComponent.find(
      (obj) => obj.types.indexOf('locality') !== -1,
    );
    const realSub = sub ? sub : sublocal ? sublocal : locality;
    return realSub?.long_name.replace(/แขวง|ตำบล/gi, '').trim() || '';
  };

  const handlePlaceSelect = async (place) => {
    const { address_components } = place;
    if (!place) return;
    const streetPlace = getStreetByMap(address_components);
    const subdistrictPlace = getSubDistrictByMap(address_components);
    const zipcode = getValueByType(address_components, 'postal_code');
    const subList = await fetchSubdistrict(subdistrictPlace);
    const foundSub =
      subList.length > 0 &&
      subList.find((item) => {
        if (
          item.value === subdistrictPlace &&
          item.data.zipcode === zipcode.long_name
        ) {
          return item;
        }
      });
    setOpenMap(false);
    if (foundSub) {
      setAddr(streetPlace);
      setSubDistrict(foundSub.data);
      fillDataAll(
        foundSub.data.district,
        foundSub.data.province,
        foundSub.data.zipcode,
      );
    }
  };

  const validatePhoneNumber = (value) => {
    const stPhone = value.toString()
    if (stPhone.slice(0, 1) !== '0') {
      setValidatePhone(true)
    } else {
      setValidatePhone(false)
    }
  }

  if (openMap) {
    return <PlacePicker onPlaceSelect={handlePlaceSelect} />;
  }

  if (redirect === pathPromotionAddressList || redirect === pathCartPromotion) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            reload: true,
          },
        }}
      />
    );
  }

  const getCurrentLocale = () => {
    Geocode.setLanguage(defaultLang);
    Geocode.setRegion(defaultRegion);

    navigator.geolocation.getCurrentPosition(
      function (position) {
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (response) => {
            const address = response.results[0];
            handlePlaceSelect(address);
          },
          () => {
            setShowModal('Something went wrong please try again later!');
          },
        );
      },
      function onError(error) {
        switch (error.code) {
          case 1:
            setShowModal(
              'กรุณาอนุญาตการเข้าถึงสถานที่ตั้ง แล้วลองใหม่อีกครั้ง',
            );
            break;
          case 2:
            setShowModal(
              'ไม่สามารถเข้าถึงสถานที่ตั้งของคุณได้ กรุณาลองใหม่อีกครั้ง',
            );
            break;
          case 3:
            setShowModal(
              'ไม่สามารถเข้าถึงสถานที่ตั้งของคุณได้ กรุณาลองใหม่อีกครั้ง',
            );
            break;

          default:
            setShowModal(
              `code: ${error.code}\ren` + `message: ${error.message}\n`,
            );
            break;
        }
      },
      { timeout: 7000 },
    );
  };

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="promotion-create-address-container-new scroll">
        <div className="title">เพิ่มที่อยู่</div>
        <div className="body">
          <div className="address-search-container">
            <div className="address-search-title">
              <img src="/images/promotion/icon_search.png" />
              <div className="address-search-title-text">ค้นหาที่อยู่</div>
            </div>
            <div className="location-container">
              <div className="search-bar-container">
                <div className="search-bar">
                  <input className="search-input-box" placeholder="ค้นหาจากแผนที่" disabled></input>
                </div>
              </div>
              <div className="map-button-align">
                <span className="map-button" onClick={() => setOpenMap(true)}>
                  <div className="map-button-text">แผนที่</div>
                </span>
              </div>
            </div>
            <div className="autofill-button-container">
              <img
                style={{ width: 20, paddingBottom: 5 }}
                src="/images/promotion/icon-my-location.png"
              />
              <span className="autofill-button-text" onClick={() => { getCurrentLocale() }}>ใช้ตำแหน่งปัจจุบัน</span>
            </div>
          </div>
          <div className="address-fields-container">
            <div className="address-fields-title">
              <img src="/images/promotion/icon_address_fields.png" />
              <div className="address-fields-title-text">ที่อยู่ในการจัดส่ง</div>
            </div>
            <div>
              <label className="label-address">ชื่อผู้รับสินค้า</label>
              <div className="input-bars">
                <input
                  className="input-address"
                  value={contactPersonName}
                  placeholder={'กรอกชื่อผู้รับสินค้า'}
                  onChange={(e) => {
                    const regEx = /[^\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z\s]/g;
                    !regEx.test(e.target.value) && setContactPersonName(e.target.value);
                  }}
                />
              </div>
              {validateName ? (
                <p className="alert-message">กรุณากรอกชื่อให้ถูกต้อง</p>
              ) : (
                <></>
              )}
              <label className="label-address">เบอร์โทรศัพท์ผู้รับสินค้า</label>
              <div className="input-bars">
                <input
                  className="input-address"
                  type="tel"
                  value={phone}
                  placeholder={'084-xxx-xxxx'}
                  maxLength={10}
                  onChange={(e) => {
                    const regEx = /^\d*\.?\d*$/;
                    validatePhoneNumber(e.target.value);
                    regEx.test(e.target.value) && setPhone(e.target.value);
                  }}
                />
              </div>
              {validatePhone ? <p className="alert-message">กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง</p> : <></>}
              <label className="label-address">ที่อยู่</label>
              <div className="input-bars">
                <input
                  className="input-address"
                  value={addr}
                  placeholder={'บ้านเลขที่'}
                  maxLength={50}
                  onChange={(e) => {
                    const regEx = /[^\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z\d\s\(\)\/\-\*\.,]/g;
                    !regEx.test(e.target.value) && setAddr(e.target.value)
                  }}
                />
              </div>
              <label className="label-address">แขวง</label>
              <SelectorAddress
                option={subdistrictList}
                onChange={setSubDistrict}
                fetch={fetchSubdistrict}
                stateValue={subDistrict}
                placeholder={'เลือกแขวง'}
              />
              <label className="label-address">เขต</label>
              <SelectorAddress
                option={districtList}
                onChange={setDistrict}
                fetch={fetchDistrict}
                stateValue={district}
                placeholder={'เลือกเขต'}
              />
              <label className="label-address">จังหวัด</label>
              <SelectorAddress
                option={provinceList}
                onChange={setProvice}
                fetch={fetchProvice}
                stateValue={province}
                placeholder={'เลือกจังหวัด'}
              />
              <label className="label-address">รหัสไปรษณีย์</label>
              <div className="input-bars">
                <input
                  className="input-address"
                  type="number"
                  value={zipcode}
                  placeholder={'กรอกรหัสไปรษณีย์'}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="address-panel-footer">
          <button className="select-address-footer font-larger"
            onClick={onSubmit}
            disabled={isNotAllField}
          >
            {isNotAllField ?
              <span className="select-address-text-disabled">ซื้อเลย</span>
              : <span className="select-address-text-active">ซื้อเลย</span>
            }
          </button>
        </div>
      </footer>
    </section>
  );
};

import React from 'react';
import { AFTER_TYPE, getPhotoName } from '../../challengeConstants';

export default function MyPictureCardIdle({
  photoPositionObj,
  beforeOrAfter,
  handleImageUpload,
  isAfterImageUpload
}) {
  const disableAfterUpload = beforeOrAfter.type === AFTER_TYPE && !isAfterImageUpload;
  const image = photoPositionObj.image(disableAfterUpload ? "_grey" : "")
  return (
    <div
      className="picture-col"
      onClick={() => {
        if (disableAfterUpload) {
          return;
        }
        handleImageUpload(
          getPhotoName(beforeOrAfter.type, beforeOrAfter.position)
        )
      }
      }
    >
      <img src={image} alt={image} />
      <p className={disableAfterUpload ? "grey-color" : "black-color"}>
        {photoPositionObj.uploadPictureThai}
        <br />
        {photoPositionObj.positionImageThai} ({beforeOrAfter.typeThai})
      </p>
    </div>
  );
}

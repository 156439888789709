import { createBrowserHistory } from 'history';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import commonConstant from '../../../common/commonConstant';
import { getAddress } from '../../../services/promotion/address.service';
import {
  getCart,
  getCartInstallment,
  getCartPublic,
  getPhoneFormat,
  getPriceFormat,
  INSTALLMENT_TYPE,
  message,
  updateDeliveryCart,
} from '../../../services/promotion/utils.service';
import Liff from '../../modal/Liff';
import { AddressFooter } from '../../promotion/Footer/AddressFooter';
import OrderSummary from '../OrderSummary';
import './PromotionAddressList.scss';
import { liffGetProfile } from '../../../services/promotion/auth.service';

export const history = createBrowserHistory();

const { liff } = window;

const { pathCreatePromotionAddress } = commonConstant;

export const PromotionAddressList = (props) => {
  const [addressList, setAddressList] = useState([]);
  const [selectedAddr, setSelectedAddr] = useState();
  const [selectedPhone, setSelectedPhone] = useState();
  const [cartAddress, setCartAddress] = useState();
  const [loading, setLoading] = useState(true);
  const [validatePhone, setValidatePhone] = useState(false);
  const [phone, setPhone] = useState('');
  const [cartEntries, setCartEntries] = useState();
  const [isOpenLiff, setIsOpenLiff] = useState(false);
  const [isInstallment, setIsInstallment] = useState(INSTALLMENT_TYPE.FALSE);

  const maxCount = 6;

  useEffect(() => {
    if (!_.isEmpty(addressList)) {
      const primaryAddr = _.find(addressList, { defaultAddress: true });
      setSelectedAddr(primaryAddr.id);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedAddr) submitAddress();
  }, [selectedAddr]);

  const fetchData = async () => {
    liff.ready.then(async () => {
      const lineProfile = await liffGetProfile();
      const result = await getAddress(lineProfile.userId);
      let cart=props.location.state?.cart?props.location.state?.cart:null;
      if(!cart){
        cart = await fetchCartData();
      }
      setCartEntries(cart);
      const cartAddress = cart[0].deliveryMethod.address
        ? cart[0].deliveryMethod.address
        : {};
      const phone = cart[0]?.deliveryMethod?.deliveryContactNumber;
      updateStateUsingCartAddress(cartAddress, phone);
      result && setAddressList(result.data);
      setLoading(false);
    });
  };

  const fetchCartData = async () => {
    let cart = null;
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    const guid = sessionStorage.getItem('guid');
    const urlParams = queryString.parse(props.location.search);
    setIsInstallment(urlParams.isInstallment);
    if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
      const cartId = urlParams.cartId;
      const zippCart = await getCartInstallment(cartId);
      cart = [zippCart];
    } else if (customerFOA) {
      cart = await getCartPublic(guid);
    } else {
      cart = await getCart();
    }
    return cart;
  };

  const submitAddress = async () => {
    try {
      let result = null;
      setLoading(true);
      const { userId } = await liffGetProfile();
      const urlParams = queryString.parse(props.location.search);
      if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
        if (!urlParams.cartId) {
          alert(message.error.cart);
        }
        const isInstallment = urlParams.isInstallment === INSTALLMENT_TYPE.TRUE;
        result = await updateDeliveryCart(
          userId,
          selectedAddr,
          selectedPhone ? selectedPhone : '',
          urlParams.cartId,
          isInstallment,
        );
      } else {
        let cartId;
        let fullAddress;
        const isInstallment = urlParams.isInstallment === INSTALLMENT_TYPE.TRUE;
        if(cartEntries){
          cartId=  cartEntries[0]?.id;
          fullAddress=  cartEntries[0]?.deliveryMethod?.address?.fullAddress;
        }
        result = await updateDeliveryCart(
          userId,
          selectedAddr,
          selectedPhone ? selectedPhone : '',
          cartId,
          isInstallment,
          fullAddress,
        );
      }
      setCartEntries([result.data]);
      const cartAddress = result.data?.deliveryMethod?.address
        ? result.data?.deliveryMethod?.address
        : {};
      const phone = result.data?.deliveryMethod?.deliveryContactNumber;
      updateStateUsingCartAddress(cartAddress, phone);
      setLoading(false);
      return result.success;
    } catch (error) {
      alert(message.error.somethingWrong);
      setLoading(false);
    }
  };

  const updateStateUsingCartAddress = (cartAddress, phone) => {
    setCartAddress(cartAddress);
    setPhone(phone);
  };

  const setPhoneNumber = async () => {
    if (+phone) {
      if (phone.length === 1 && phone !== '0') phone = '0'.concat(phone);
    }
    if (phone.length === 10) {
      try {
        let result = null;
        const { userId } = await liffGetProfile();

        setLoading(true);
        const urlParams = queryString.parse(props.location.search);
        if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
          if (!urlParams.cartId) {
            alert(message.error.cart);
          }
          const isInstallment =
            urlParams.isInstallment === INSTALLMENT_TYPE.TRUE;
          result = await updateDeliveryCart(
            userId,
            null,
            phone,
            urlParams.cartId,
            isInstallment,
          );
          setCartEntries([result.data]);
          setLoading(false);
          return;
        }
        result = await updateDeliveryCart(userId, null, phone);
        setCartEntries([result.data]);
        setLoading(false);
      } catch (error) {
        alert(message.error.somethingWrong);
        setLoading(false);
      }
    }
  };

  const redirectToAddAddress = () => {
    let path = pathCreatePromotionAddress;
    if (isInstallment === INSTALLMENT_TYPE.TRUE) {
      path = `${pathCreatePromotionAddress}?isInstallment=${isInstallment}&cartId=${cartEntries[0]?.id}`;
    }
    return (window.location.href = path);
  };

  const validatePhoneNumber = (value) => {
    const stPhone = value.toString();
    if (stPhone.slice(0, 1) !== '0') {
      setValidatePhone(true);
    } else {
      setValidatePhone(false);
    }
  };

  const renderCartContents = () => {
    // TODO: need to check for piff and zip cart when implemented
    return (
      <OrderSummary
        cartData={cartEntries[0]}
        entries={cartEntries[0].entries}
        totalPrice={getTotalPrice()}
        totalPvBv={cartEntries[0].amwayValue}
        deliveryCost={getDeliveryCost()}
        discount={getDiscount()}
      />
    );
  };

  const getTotalPrice = () => {
    if (!cartEntries) {
      return <></>;
    }
    const cart = cartEntries[0];
    const subTotalPrice = cart.subTotal.filter(
      (value) => value.label === 'รวมค่าสินค้า',
    )[0];

    const price = getPriceFormat(subTotalPrice.value.price);
    return `฿ ${price}`;
  };

  const getDeliveryCost = () => {
    if (!cartEntries) {
      return <></>;
    }
    const cart = cartEntries[0];
    const subTotalPrice = cart.subTotal.filter(
      (value) => value.label === 'ค่าจัดส่ง',
    )[0];

    const price = getPriceFormat(subTotalPrice.value.price);
    return `฿ ${price}`;
  };

  const getDiscount = () => {
    if (!cartEntries) {
      return <></>;
    }
    const cart = cartEntries[0];
    const subTotalPrice = cart.subTotal.filter(
      (value) => value.label === 'ส่วนลดทั้งหมด',
    )[0];

    const price = getPriceFormat(subTotalPrice.value.price);
    if (price == 0) {
      return `฿ ${price}`;
    } else {
      return `฿ ${price.substring(1)}`;
    }
  };

  const openSummary = () => {
    setIsOpenLiff(true);
  };

  const addAble = addressList ? addressList.length < maxCount : false;

  const updateAddressListSelectionToFalse = () => {
    addressList.map((addr) => (addr.isSelected = false));
  };

  const fetchAddressOnUniqueId = () => {
    return addressList.find(
      (addr) =>
        addr?.id === cartAddress?.publicKey ||
        addr.addressBookId === cartAddress?.publicKey,
    );
  };

  const fetchAddressOnContactId = () => {
    return addressList.find(
      (addr) => addr?.contactId === cartAddress?.contactId,
    );
  };

  const fetchAddressOnMultiAttributes = () => {
    return addressList.find(
      (addr) =>
        addr?.fullAddress === cartAddress?.fullAddress &&
        addr?.contactPersonName === cartAddress?.contactPersonName &&
        addr?.phone === cartAddress?.phone,
    );
  };

  const getAddrRadioBtnView = (selected) => {
    return selected ? (
      <div className={`radio-outer-circle-new`}>
        <div className={`radio-inner-circle-new `} />
      </div>
    ) : (
      <div className={`radio-outer-circle-new unselected`}>
        <div className={`radio-inner-circle-new unselected-circle`} />
      </div>
    );
  };

  const renderAddressList = () => {
    if (!addressList.length) {
      return <></>;
    } else {
      updateAddressListSelectionToFalse();
      const uniqueIdAddr = fetchAddressOnUniqueId();
      if (uniqueIdAddr) {
        uniqueIdAddr.isSelected = true;
      } else {
        const contactIdAddr = fetchAddressOnContactId();
        if (contactIdAddr) {
          contactIdAddr.isSelected = true;
        } else {
          const addr = fetchAddressOnMultiAttributes();
          if (addr) {
            addr.isSelected = true;
          }
        }
      }

      return (
        <div className="addresses-wrapper">
          <div style={{ overflow: 'scroll' }}>
            {addressList.length > 0 &&
              addressList.map((addr, idx) => {
                return (
                  <div
                    key={idx}
                    className="address-container-new box"
                    style={
                      addr.isSelected
                        ? { borderColor: '#38539A' }
                        : { borderColor: '#E4E4E4' }
                    }
                    onClick={() => {
                      setSelectedAddr(addr?.id);
                      setSelectedPhone(addr?.phone);
                    }}
                  >
                    {getAddrRadioBtnView(addr.isSelected)}
                    <div className="address-items">
                      <div className="contact-person-name">
                        {addr.contactPersonName}
                      </div>
                      <div className="address-fields-text">
                        {addr.fullAddress}
                      </div>
                      <div className="address-fields-text">
                        {addr.phone ? `โทร: ${getPhoneFormat(addr.phone)}` : ''}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <section>
        <div style={{ maxWidth: '100%', overflowX: 'hidden' }}>
          <LoadingOverlay
            text="Loading"
            spinner
            active={loading}
            styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
          />
          <div className="promotion-summary-address-list-container scroll">
            <div className="title">สรุปคำสั่งซื้อ</div>
            <div className="body">
              <div style={{ padding: '15px', minHeight: '75vh' }}>
                <div className="address-fields-title">
                  <div className="address-title-alignment">
                    <img src="/images/promotion/icon_address_fields.png" />
                    <div className="address-fields-title-text">
                      ที่อยู่ในการจัดส่ง
                    </div>
                  </div>
                  {addAble ? (
                    <div onClick={redirectToAddAddress}>
                      <span className="plus-icon">+ </span>
                      <span className="add-address">เพิ่มที่อยู่</span>
                    </div>
                  ) : (
                    <div>
                      <span className="plus-icon">+ </span>
                      <span className="add-address disabled">เพิ่มที่อยู่</span>
                    </div>
                  )}
                </div>
                <label className="address-phone-title">
                  กรุณากรอกเบอร์โทรศัพท์ของคุณ
                </label>
                <div className="phone-number-container">
                  <div className="phone-input">
                    <input
                      className="input-phone"
                      type="tel"
                      value={phone ? phone : ''}
                      placeholder={'084-xxx-xxxx'}
                      maxLength={10}
                      onChange={(e) => {
                        const regEx = /^\d*\.?\d*$/;
                        validatePhoneNumber(e.target.value);
                        e.target.value === 0 || e.target.value === ''
                          ? setPhone('')
                          : regEx.test(e.target.value) &&
                            setPhone(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: '10px' }}>
                    {phone?.length === 10 && phone?.startsWith(0) ? (
                      <span
                        className="change-phone active-button"
                        onClick={() => setPhoneNumber()}
                      >
                        <span className="change-phone-text-active">
                          เปลี่ยน
                        </span>
                      </span>
                    ) : (
                      <span className="change-phone inactive-button">
                        <span className="change-phone-text inactive-text">
                          เปลี่ยน
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                {validatePhone ? (
                  <p className="alert-message">
                    กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง
                  </p>
                ) : (
                  <></>
                )}
                {renderAddressList()}
              </div>
            </div>
            <div className="fixed-text-bottom">
              * คุณสามารถเพิ่มที่อยู่ได้สูงสุด {maxCount} รายการ
              <br />
              หากต้องการแก้ไขหรือเพิ่มที่อยู่ กรุณาติดต่อ 02-725-8000
            </div>
          </div>
          <Liff
            title={'รายละเอียดคำสั่งซื้อ'}
            onClose={() => setIsOpenLiff(false)}
            isOpen={isOpenLiff}
          >
            <div className="scroll" style={{ height: '70vh' }}>
              {cartEntries && renderCartContents()}
            </div>
            <div className="order-summary-panel-footer">
              <div>
                <button
                  className="select-order-summary-footer font-larger"
                  onClick={() => setIsOpenLiff(false)}
                >
                  <span className="select-address-text">ตกลง</span>
                </button>
              </div>
            </div>
          </Liff>
          <footer>
            <AddressFooter
              cart={cartEntries}
              isInstallment={isInstallment === INSTALLMENT_TYPE.TRUE}
              haveAddress={cartAddress}
              openSummary={() => openSummary()}
            />
          </footer>
        </div>
      </section>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { BadgeRectCard } from "../../../../common";
import { ALL_STEPS_BADGES_BUDDYME, CHALLENGE, maxFoodVdoCalorieBadges } from "../../../../common/challengeConstants";
import { updateStepsBadgeList } from "../../../../utill.func";
import './ChallengeMyBadgesList.scss';

const ChallengeMyBadgesList = ({ totalSteps, activeBadges, partyId, redirectToBadgeScreen, redirectToBadgeDetail}) => {

    const [badgeList, setBadgeList] = useState([]);

    useEffect(() => {
        setBadgeList(updateStepsBadgeList(ALL_STEPS_BADGES_BUDDYME, activeBadges));
    },[activeBadges])

    const renderHeading = () => {
        return (
            <div className="badge-container-heading">
                <span>{CHALLENGE.myDashboard.missionSectionHeading}</span>
                <span className="heading-number">{maxFoodVdoCalorieBadges}</span>
                <span>{CHALLENGE.myDashboard.missionSectionSubHeading}</span>
            </div>
        )
    }

    return (
        <div className="my-badge-list-container">
            {renderHeading()}
            <div className="challenge-card-body" onClick={() => redirectToBadgeScreen()}>
                <BadgeRectCard
                    title={CHALLENGE.myDashboard.fitPerformer.toUpperCase()}
                    badges={badgeList}
                    values={totalSteps}
                    maxBadgeValue={CHALLENGE.myDashboard.maxStepsForTeam}
                    unit={CHALLENGE.myDashboard.stepsUnit}
                    redirectToBadgeDetail={redirectToBadgeDetail}
                    stepsBadgeCount={activeBadges}
                />
            </div>
        </div>
    )

}

export default ChallengeMyBadgesList;
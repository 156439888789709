import React from 'react';
import * as variables from './CircularChart.scss';

export default function CircularChart({
  radius = 14,
  strokeWidth = 3.5,
  data1Id,
  track1Value = 0,
  track2Value = 0,
  trackTotal = track1Value + track2Value,
  color1dark,
  color1light,
  color2dark,
  color2light,
  fill,
  isInverted,
  showOverflow = false
}) {
  const percentCalculation = (input, total) => (input * 100) / total;

  if (showOverflow) {
    color1dark = variables.noDataLight;
    color1light = variables.noDataDark;
  };

  return (
    <div className={`circular-chart${isInverted ? ' --inverted' : ''}`}>
      <svg>
        <defs>
          <linearGradient id={data1Id} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: color1dark, stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: color1light, stopOpacity: 1 }}
            />
          </linearGradient>
          <linearGradient id="data2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: color2light, stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: color2dark, stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <circle
          cx={`${+radius + strokeWidth}`}
          cy={`${+radius + strokeWidth}`}
          r={radius}
          stroke={variables.bmigrey}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={`${+radius + strokeWidth}`}
          cy={`${+radius + strokeWidth}`}
          r={radius}
          stroke={`url(#${data1Id})`}
          strokeWidth={strokeWidth}
          style={{
            '--percent1': percentCalculation(track1Value, trackTotal),
            '--percent2': percentCalculation(track2Value, trackTotal),
            fill
          }}
        />
        <circle
          cx={`${+radius + strokeWidth}`}
          cy={`${+radius + strokeWidth}`}
          r={radius}
          stroke="url(#data2)"
          strokeWidth={strokeWidth}
          style={{ '--percent2': percentCalculation(track2Value, trackTotal) }}
        />
      </svg>
    </div>
  );
}

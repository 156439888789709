import AxiosHealthWellness from '.';
import config from '../../config';
import { ActiveAssessment } from '../../model/AssesmentList.model';
import {
  Assessment,
  AssessmentAnswer,
  AssessmentDetailData,
  AssessmentHistoryLog
} from '../../model/Assessment.model';
import { AssessmentProductsList } from '../../model/AssessmentProductsList.model';
import { Customer } from '../../model/Customer.model';
import { CustomerCart } from '../../model/CustomerCart.model';
import { CustomerPolicy } from '../../model/CustomerPolicy.model';
import { Policy } from '../../model/Policy.model';
import { Profile } from '../../model/Profile.model';
import { ASSESSMENT_QUESTION_TEMPLATE, GENDER, HNW_ASSESSMENT_PATH_KEY, ASSESSMENT_PATH_KEY, policyTitle } from '../../common/commonConstant';
import { BodyKeyChallenge } from '../../model/BodyKeyChallenge.model';
import AxiosInterceptor from '../axiosInterceptor.service';

export const clearFacadeToken = () => {
  sessionStorage.removeItem('facadeToken');
};

export const setFacadeToken = async (accessToken) => {
  return sessionStorage.setItem('facadeToken', JSON.stringify(accessToken));
};

export const isNotValidSessionABOMember = (facadeToken, customer) => {
  return (
    !facadeToken ||
    facadeToken?.account?.accountType?.toLowerCase() !==
    customer.type.toLowerCase()
  );
};

export const getFacadeToken = async () => {
  const facadeToken = sessionStorage.getItem('facadeToken');
  return facadeToken && facadeToken !== 'undefined'
    ? JSON.parse(facadeToken)
    : null;
};

export const getCustomerGender = (customerData) => {
  let gender = '';
  const customer = customerData ? customerData : JSON.parse(sessionStorage.getItem('customer'));
  gender = customer?.gender?.toLowerCase();
  gender = gender ? GENDER[gender] : '';
  return gender;
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
}

export const getCustomerAge = (customerData) => {
  const customer = customerData ? customerData : JSON.parse(sessionStorage.getItem('customer'));
  const currentDate = new Date();
  const birthDateInTH = new Date(
    (typeof date === 'string'
      ? new Date(customer?.birthday)
      : customer?.birthday
    )?.toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }),
  );
  let age = currentDate.getFullYear() - birthDateInTH.getFullYear();
  const month = currentDate.getMonth() - birthDateInTH.getMonth();
  if (
    month < 0 ||
    (month === 0 && currentDate.getDate() < birthDateInTH.getDate())
  ) {
    age--;
  }
  return age;
};

/* ================= Handling common service structure for H&W ================ */
// --------------------------- HOMEPAGE API'S START --------------------------- //

export const fetchCustomerBmi = async () => {
  const response = await AxiosHealthWellness.get(
    `${config.API_URL}/customers/bmi`,
  );
  return new Profile(response?.data);
};

export const fetchCustomerMonthlyBmi = async (startDate, endDate) => {
  const response = await AxiosHealthWellness.get(
    `${process.env.REACT_APP_API_URL}/customers/bmi-data?startDate=${startDate}&endDate=${endDate}`,
  );
  if (response && response.data) {
    return response.data.map((el) => new Profile(el));
  }
  return [];
};

export const updateCustomerBmi = async (healthInfo) => {
  const response = await AxiosHealthWellness.patch(
    `${config.API_URL}/customers/update-bmi`,
    healthInfo,
  );
  return new Profile(response?.data);
};

export const getAssessmentQuestions = (assessmentQuestions) => {
  const answeredQuestions = assessmentQuestions?.hw_assessments.filter(
    (question) => !question.isAnswerGiven,
  );
  const mergedQuestions = Object.values(ASSESSMENT_QUESTION_TEMPLATE).reduce(
    (acc, template) => {
      const questions = answeredQuestions.filter(
        (question) =>
          question.question_type ===
          Object.keys(ASSESSMENT_QUESTION_TEMPLATE)[template.progress_tracker],
      );
      const chunkedQuestions = [];
      for (let i = 0; i < questions.length; i += template.question_count) {
        chunkedQuestions.push(questions.slice(i, i + template.question_count));
      }
      return acc.concat(chunkedQuestions);
    },
    [],
  );

  return mergedQuestions;
};

// -------------------------- ASSESSMENT API'S START --------------------------- //

export const fetchAssessmentScore = async ({ answerId, assessmentId }) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/hwAssessment/hwAssessmentResult?answer_id=${answerId}&assessment_id=${assessmentId}`,
    );
    const assessmentScore = new Assessment(response?.data?.data);
    return assessmentScore;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAssessmentProducts = async (productIds) => {
  try {
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/products/hwAssessmentProducts`,
      { uid: customer?._id, productIds },
    );
    return new AssessmentProductsList(response.data.productData);
  } catch (err) {
    console.log(err);
  }
};

export const fetchPifCart = async (fields) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/promotion/cart`,
      {
        params: {
          fields,
        },
      },
    );
    return new CustomerCart(response?.data?.data);
  } catch (err) {
    console.log(err);
  }
};

export const addProductToCart = async (cartId, productData) => {
  try {
    let product = setQuantityToProduct(productData);
    product.quantity = 1;
    const orderEntries = [];
    if (product?.kitEntries?.length !== 0) {
      const data = {
        product: {
          id: product?.id,
        },
        quantity: 1,
        alias: product?.id,
      };
      orderEntries.push(data);
      for (const kitEntry of product?.kitEntries) {
        if (kitEntry?.product?.baseOptions?.length !== 0) {
          const baseOptions = kitEntry?.product?.baseOptions[0];
          for (const option of baseOptions?.options) {
            if (option?.quantity !== 0) {
              const data = {
                product: {
                  id: option.id,
                },
                quantity: option.quantity,
                kitEntryCode: kitEntry.kitEntryCode,
              };
              orderEntries.push(data);
            }
          }
        } else {
          const data = {
            product: {
              id: kitEntry.product.id,
            },
            quantity: kitEntry.configuredQty,
            kitEntryCode: kitEntry.kitEntryCode,
          };
          orderEntries.push(data);
        }
      }
    } else {
      const data = {
        product: {
          id: product?.id,
        },
        quantity: 1,
      };
      orderEntries.push(data);
    }
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/promotion/cart/item`,
      { cartId, orderEntries },
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const setQuantityToProduct = (product) => {
  const newList = [];
  product.quantity = 0;
  if (product?.kitEntries?.length > 0) {
    newList.push(
      product?.kitEntries?.map((kitEntry) => {
        const configuredQty = kitEntry?.configuredQty;
        if (kitEntry?.product?.baseOptions?.length > 0) {
          const baseOptions = kitEntry?.product?.baseOptions[0];
          let newBaseOptionList = [];
          newBaseOptionList.push(
            baseOptions?.options?.map((option, index) => {
              if (kitEntry?.product?.baseProduct) {
                if (baseOptions?.selectedId) {
                  const quantity =
                    baseOptions?.selectedId === option?.id ? configuredQty : 0;
                  option.quantity = quantity;
                  option.lastQuantity = quantity;
                }
              } else {
                option.quantity = index === 0 ? configuredQty : 0;
                option.lastQuantity = index === 0 ? configuredQty : 0;
              }
              return option;
            }),
          );
        }
        return kitEntry;
      }),
    );
  }
  return product;
};

export const fetchHnWAssessmentList = async () => {
  try {
    const result = await AxiosHealthWellness.get(
      `${config.API_URL}/hwAssessment/allHwAssessment`,
    );
    const assessmentList = new Assessment(result?.data?.data[0]);
    return assessmentList;
  } catch (error) {
    console.log(error);
  }
};

export const fetchHnWAssessment = async (assessment_id) => {
  const customer = JSON.parse(sessionStorage.getItem('customer'));
  const assessment_status = 'PENDING';
  try {
    const result = await AxiosHealthWellness.get(
      `${config.API_URL}/hwAssessment/hwassessmentQuestion?assessment_id=${assessment_id}&customer_id=${customer?._id}&assessmentsStatus=${assessment_status}`,
    );
    const assessmentData = new Assessment(result?.data?.data);
    const assessmentQuestion = getAssessmentQuestions(assessmentData);
    assessmentData.hw_assessments = assessmentQuestion;
    return assessmentData;
  } catch (err) {
    console.log(err);
  }
};

export const saveAssessmentAnswer = async (
  assessment_id,
  assessment_data,
  answer_id = '',
  assessment_status,
) => {
  const customer = JSON.parse(sessionStorage.getItem('customer'));
  const assessmentData = new AssessmentAnswer(
    assessment_id,
    assessment_data,
    customer?._id,
    answer_id,
    assessment_status,
  );
  try {
    const result = await AxiosHealthWellness.post(
      `${config.API_URL}/hwAssessment/hwAnswer`,
      assessmentData,
    );
    return result?.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchHnWAssessmentDetail = async (assessment_id) => {
  try {
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const result = await AxiosHealthWellness.get(
      `${config.API_URL}/hwAssessment/hwassessmentDetail?assessment_id=${assessment_id}&customer_id=${customer?._id}`,
    );
    const assessmentData = new AssessmentDetailData(result?.data?.data);
    return assessmentData;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAssessmentDetail = async (answer_id) => {
  try {
    const result = await AxiosHealthWellness.delete(
      `${config.API_URL}/hwAssessment/deletePendingQuestion?answer_id=${answer_id}`,
    );
    return result?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchHnWAssessmentHistoryLog = async () => {
  try {
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/hwAssessment/hwAssessmentHistory?customer_id=${customer?._id}`,
    );
    const sortedData = response?.data?.data.sort((a, b) => {
      return new Date(b.updatedDate) - new Date(a.updatedDate);
    });
    return sortedData.map((log) => new AssessmentHistoryLog(log));
  } catch (error) {
    console.log(error);
  }
};

// ------------------ASSESSMENT API'S END----------------------------------- //

export const getProfiles = async () => {
  const result = await AxiosHealthWellness.get('/json/home-profile.json');
  return result.data;
};

export const getAssessmentList = async () => {
  try {
    const result = await AxiosHealthWellness.get(
      `${config.API_URL}/assessment/activeList`,
    );
    const assessment = [];
    const data = result?.data?.data ? result.data.data : [];
    if (data.hwAssessment) {
      data.hwAssessment.forEach((el) =>
        assessment.push(new ActiveAssessment(el, HNW_ASSESSMENT_PATH_KEY)),
      );
    }
    if (data.assessment) {
      data.assessment.forEach((el) =>
        assessment.push(new ActiveAssessment(el, ASSESSMENT_PATH_KEY)),
      );
    }
    return assessment;
  } catch (err) {
    console.error(err);
  }
}

export const getChallenges = async (payload) => {
  const result = await AxiosHealthWellness.post(`${process.env.REACT_APP_API_URL}/bodykey-challenge/challenge_score`, payload);
  return new BodyKeyChallenge(result?.data);
}

export const getCustomer = async () => {
  const result = await AxiosHealthWellness.get(
    `${process.env.REACT_APP_API_URL}/customers`,
  );
  sessionStorage.setItem('customer', JSON.stringify(result.data));
  return new Customer(result.data);
};

export const updateTnC = async () => {
  await AxiosHealthWellness.patch(
    `${process.env.REACT_APP_API_URL}/customers/update-tnc-ack`,
  );
};

export const getPolicies = async () => {
  try {
    const result = await AxiosHealthWellness.get(
      `${process.env.REACT_APP_API_URL}/privacy/get-privacy-policies`,
    );
    const data = result?.data ? result.data : [];
    return data.map((el) => new Policy(el));
  } catch (e) {
    console.error(e);
  }
};

export const getCustomerPolicy = async () => {
  try {
    const result = await AxiosHealthWellness.get(
      `${process.env.REACT_APP_API_URL}/privacy/get-customers-privacy`,
    );
    const data = result?.data ? result.data : [];
    return data.map((el) => new CustomerPolicy(el));
  } catch (e) {
    console.error(e);
  }
};

export const updateCustomerPolicy = async (payload) => {
  try {
    const result = await AxiosHealthWellness.post(
      `${process.env.REACT_APP_API_URL}/privacy/update-customers-privacy`,
      payload,
    );
    return result?.data ? new CustomerPolicy(result.data) : null;
  } catch (e) {
    console.error(e);
  }
};

export const deleteHWProfile = async () => {
  try {
    const response = await AxiosHealthWellness.delete(
      `${process.env.REACT_APP_API_URL}/customers/deleteProfile`,
    );
    return response?.success;
  } catch (e) {
    console.error(e);
  }
};

export const getPolicyStatus = async (selectedPolicy) => {
  try {
    const customerpolicy = await getCustomerPolicy();
    const checkIfPolicyExists = selectedPolicy && customerpolicy.length &&
      customerpolicy.find((el) => el.privacies === selectedPolicy.id);
    return !(!customerpolicy.length || (checkIfPolicyExists && !checkIfPolicyExists.status));
  } catch (e) {
    throw e;
  }
};

export const updateProfilePicture = async (payload) => {
  try {
    const result = await AxiosInterceptor.put(
      `${process.env.REACT_APP_API_URL}/customers/profile/update`,
      payload,
    );
    return result?.data;
  }
  catch (e) {
    throw e;
  }
}
import React, { useState, useRef, useEffect } from "react";
import './ChallengeMyReward.scss';
import ChallengeRewardCard from "../../../../common/challengeRewardCard/ChallengeRewardCard";
import { CHALLENGE, CHALLENGE_REWARD_DETAIL } from "../../../../common/challengeConstants";
import ChallengeNavButton from "../../../../common/challengeNavButton/ChallengeNavButton";

import Liff from "../../../modal/Liff";
import ChallengeMyRewardModal from "../challengeMyRewardModal/ChallengeMyRewardModal";
import { NoResultFound } from "../../../../common";
import { getDataFromSession } from "../../../../utill.func";

const ChallengeMyReward = ({ userRewardData, teamRewardData }) => {
    const challengeData = getDataFromSession('challengeData');
    const eventType = challengeData?.eventType;
    const [rewardModal, setRewardModal] = useState(false);
    const [rewardModalData, setRewardModalData] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const modalRef = useRef(null);
    const handleTabButtonChange = (index) => {
        setActiveIndex(index);
    };
    const rewardDataPopup = (data) => {
        setRewardModalData(data);
        setRewardModal(true);
    }
    const isTeamTab = activeIndex == 1 ? 'team-tab' : '';
    const handleClickOutsideModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setRewardModal(false);
        }
    };
    const rewardDetails = (rewardData, isTeamTab) => {
        return (
            rewardData?.length  ?
                <div className="challenge-my-reward-container-section">
                    {rewardData?.map((reward_data) => {
                        return (<ChallengeRewardCard rewardData={reward_data} openRewardModal={rewardDataPopup} isTeamTab={isTeamTab} />)
                    })}
                </div> :
                <NoResultFound
                    emptyImg={CHALLENGE.reward.noRewardImage}
                    header={CHALLENGE.reward.noRewardTitle}
                />
        )
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideModal);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideModal);
        };
    }, []);
    return (
        <div className={`challenge-my-reward-container ${rewardModal ? ' --no_scroll' : ''}`}>
            <div className="challenge-my-reward-container-header">{CHALLENGE.reward.activityRewards}</div>
            <div className="challenge-my-reward-container-nav">
                <ChallengeNavButton tabList={eventType === CHALLENGE.type.ME.toLowerCase() ? [CHALLENGE_REWARD_DETAIL.HOME_TABS_LIST[0]] : CHALLENGE_REWARD_DETAIL.HOME_TABS_LIST}
                    onTabButtonChange={handleTabButtonChange} />
            </div>

            {activeIndex == 0 ? rewardDetails(userRewardData) : rewardDetails(teamRewardData, isTeamTab)}

            {rewardModal &&
                <Liff
                    isOpen={rewardModal}
                    onClose={() => setRewardModal(false)}
                >
                    <div className="scroll" ref={modalRef}>
                        <ChallengeMyRewardModal rewardModalData={rewardModalData} />
                    </div>
                </Liff>
            }
        </div>
    )
}

export default ChallengeMyReward
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { commonConstant } from '../../../common';
import { Checkbox } from '../../../helpers';
import { getEntriesFromCart } from '../../../services/promotion/bundle.service';
import { getCustomerFOA } from '../../../services/promotion/foa.service';
import {
  addProductsToCart,
  deleteCartItems,
  deleteCartItemsPublic,
  getCart,
  getCartInstallment,
  getCartPublic,
  INSTALLMENT_TYPE, isCartABOType, message,
  PAYMENT_OPTION,
  RULE_BASED,
  SOP_BASIC,
  SOP_BEYOND,
  isCartABOMemberType
} from '../../../services/promotion/utils.service';
import BundleProductItem from '../PromotionTemplate/BundleProductItem';
import Product, { ProductItemType } from '../PromotionTemplate/Product';
import { ABO_MEMBER, FOA_MEMBER, UpdateState } from './PromotionProduct';
import { liffGetProfile } from '../../../services/promotion/auth.service'
import { connect } from 'react-redux';
import { getCartData } from '../../../actions/cartAction';

export const history = createBrowserHistory();
const { liff } = window;
export class RemovePromotion extends Component {
  cartProductkitEntries = [];

  constructor(props) {
    super(props);
    this.state = {
      cart: props.location.state?.cart?props.location.state.cart:null,
      pifCart: null,
      zippCart: null,
      selected: [],
      loading: false,
      modalIsOpen: false,
      isSelectAll: false,
      productEntries: [],
      pifProductEntries: [],
      zippProductEntries: [],
      selectedPaymentTab: null,
    };

    this.onDelete = this.onDelete.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.goToCart = this.goToCart.bind(this);
    this.setProductSelected = this.setProductSelected.bind(this);
    this.renderSvgForCheckbox = this.renderSvgForCheckbox.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();

    if (
      this.state.cart &&
      this.state.cart.length &&
      this.state.cart[0].entries
    ) {
      for (const entry of this.state.cart[0].entries) {
        if (entry.kitEntryCode) {
          await this.setProductSelected(
            entry.product.id,
            entry.quantity,
            UpdateState.INIT,
            entry.kitEntryCode,
          );
        }
      }
    }
  }

  async fetchData() {
    await this.fetchPIFCart();
    await this.fetchZIPPCart();

    const urlParams = queryString.parse(this.props.location.search);
    if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
      this.setState({
        cart: this.state.zippCart,
        productEntries: this.state.zippProductEntries,
        selectedPaymentTab: PAYMENT_OPTION.ZIPP,
        selected: [],
        loading: false,
      });
    } else {
      this.setState({
        cart: this.state.pifCart,
        productEntries: this.state.pifProductEntries,
        selectedPaymentTab: PAYMENT_OPTION.PIF,
        selected: [],
        loading: false,
      });
    }
  }

  async fetchPIFCart() {
    this.setState({ loading: true });
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    const guid = sessionStorage.getItem('guid');
    let cart = this.state.cart;
    if(!cart){
    cart = !customerFOA ? await getCart() : await getCartPublic(guid);
    }
    let productEntries = getEntriesFromCart(cart[0]);
    console.log('productEntries1: ', productEntries);
    productEntries = this.setQuantityToProduct(productEntries);
    console.log('productEntries2: ', productEntries);
    this.setState({
      pifCart: cart,
      loading: false,
      pifProductEntries: productEntries,
    });
  }

  async fetchZIPPCart() {
    this.setState({ loading: true });
    const urlParams = queryString.parse(this.props.location.search);
    if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
      if (!urlParams.cartId) {
        alert(message.error.cart);
      }
      const cart = await getCartInstallment(urlParams.cartId);
      let productEntries = cart.entries;
      productEntries = this.setQuantityToProduct(productEntries);
      this.setState({
        zippCart: [cart],
        loading: false,
        zippProductEntries: productEntries,
      });
    } else {
      this.setState({
        cart: null,
        productEntries: [],
        selectedPaymentTab: PAYMENT_OPTION.ZIPP,
        selected: [],
        loading: false,
      });
    }
  }

  setQuantityToProduct(entries) {
    const newRelatedProducts = entries.map((entry) => {
      entry.product.quantity = entry.quantity;
      if (entry.product.kitEntries.length > 0) {
        entry.product.kitEntries.map((kitEntry) => {
          if (kitEntry.product.baseOptions.length > 0) {
            const baseOptions = kitEntry.product.baseOptions[0];
            baseOptions.options.map((option, index) => {
              option.quantity = 0;
              option.lastQuantity = 0;
            });
          }
        });
      }
      return entry;
    });
    return newRelatedProducts;
  }

  async setProductSelected(productId, quantity, updateState, kitEntryCode) {
    const productEntries = this.state.productEntries.map((entry) => {
      if (entry.product.id === productId) {
        entry.product.quantity = quantity;
      }
      if (entry.product.kitEntries.length > 0) {
        entry.product.kitEntries.map((kitEntry) => {
          if (kitEntryCode.indexOf(entry.alias) >= 0) {
            kitEntry.kitEntryCodeAlias = kitEntryCode;
            if (kitEntry.product.baseOptions.length > 0) {
              const baseOptions = kitEntry.product.baseOptions[0];
              baseOptions.options.map((option) => {
                if (updateState === UpdateState.FINISH) {
                  option.lastQuantity = option.quantity;
                } else if (option.id === productId) {
                  if (updateState === UpdateState.CHANGE) {
                    option.quantity = quantity;
                  }
                  if (updateState === UpdateState.INIT) {
                    option.quantity = quantity;
                    option.lastQuantity = quantity;
                  }
                  if (updateState === UpdateState.CLEAR) {
                    option.quantity = option.lastQuantity;
                  }
                }
              });
            }
          }
        });
      }
      return entry;
    });
    this.setState({ productEntries });
    if (updateState === UpdateState.FINISH) {
      const productEntry = productEntries.find(
        (value) => value.alias === kitEntryCode,
      );

      const cart = this.state.cart ? this.state.cart[0] : null;
      try {
        this.setState({ loading: true });
        const urlParams = queryString.parse(this.props.location.search);

        const addProductResponse = await addProductsToCart(cart.id, [
          productEntry.product,
        ]);

        if (addProductResponse.error) {
          alert(addProductResponse.error[0].th_message);
          this.setState({ loading: false });
          return;
        }

        const isInstallment = urlParams.isInstallment === INSTALLMENT_TYPE.TRUE;

        await deleteCartItems(cart.id, [productEntry.id], isInstallment);

        this.setState({
          productEntries: [],
        });

        window.location.reload();

        return;
      } catch (error) {
        console.log(error);
        alert(message.error.somethingWrong);
        window.location.reload();
        return;
      }
    }
  }

  handleSelect(id) {
    const index = this.state.selected.indexOf(id);
    if (index >= 0) {
      this.state.selected.splice(index, 1);
    } else {
      this.state.selected.push(id);
    }
    const cart = this.state.cart ? this.state.cart[0] : null;
    const productEntries = cart.entries.filter(
      (entry) =>
        !this.cartProductkitEntries.includes(entry.product.id) &&
        entry.isGiveAway !== true,
    );

    const isSelectAll =
      this.state.selected.length === this.state.productEntries.length;
    return this.setState({ selected: this.state.selected, isSelectAll });
  }

  addkitEntriesTocartProduct(id) {
    if (!this.cartProductkitEntries.includes(id)) {
      this.cartProductkitEntries.push(id);
    }
  }

  handleSelectAll() {
    this.state.isSelectAll = !this.state.isSelectAll;
    const { isSelectAll } = this.state;
    if (isSelectAll) {
      const cart = this.state.cart ? this.state.cart[0] : null;
      const productEntries = cart.entries.filter(
        (value) => value.isGiveAway !== true && !value.kitEntryCode,
      );

      const ids = [];
      productEntries.filter((entry) => {
        if (!this.cartProductkitEntries.includes(entry.id)) {
          ids.push(entry.id);
        }
      });

      this.setState({ isSelectAll: true, selected: ids });
    } else {
      this.setState({ isSelectAll: false, selected: [] });
    }
  }

  async onDelete() {
    const cart = this.state.cart ? this.state.cart[0] : null;
    const productEntries = cart.entries.filter(
      (value) => value.isGiveAway !== true && !value.kitEntryCode,
    );
    let { selected } = this.state;
    const filterSOP = cart.entries.filter((entry) => {
      return entry['hasSopEntries'] && !entry['kitEntryCode'];
    });
    for (const [index, item] of filterSOP.entries()) {
      const hasSelected = this.state.selected.includes(item.id);
      if (!hasSelected) continue;
      filterSOP[index]['isDeleted'] = true;
    }
    try {
      const cart = this.state.cart ? this.state.cart[0] : null;
      const { userId } = await liffGetProfile();

      if (selected.length === 0) {
        return alert('กรุณาเลือกอย่างน้อย 1 รายการเพื่อลบ');
      }

      this.setState({ loading: true });
      const urlParams = queryString.parse(this.props.location.search);
      const isInstallment = urlParams.isInstallment === INSTALLMENT_TYPE.TRUE;
      const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
      const guid = sessionStorage.getItem('guid');
      for (const selectedItem of selected.sort((a, b) => a - b).reverse()) {
        const selectedFilterSOP = filterSOP.filter(
          (sop) => sop.id == selectedItem,
        );
        !customerFOA
          ? await deleteCartItems(
              cart.id,
              [selectedItem],
              isInstallment,
              userId,
              selectedFilterSOP,
            )
          : await deleteCartItemsPublic(
              cart.id,
              [selectedItem],
              isInstallment,
              userId,
              selectedFilterSOP,
              guid,
            );
      }
      await this.props.getCartData("FULL");
      this.setState({ loading: false });
      this.goToCart();
    } catch (error) {
      console.log(error);
      alert(message.error.somethingWrong);
      this.setState({ loading: false });
    }
  }

  displayItem2() {
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return <></>;
    }

    return this.state.productEntries.map((entry, index) => {
      if (entry.kitEntryCode || entry.isGiveAway) {
        return <></>;
      }

      const {
        totalPrice,
        quantity,
        amwayValue,
        kitProductTotalPriceInclTax,
        qualifiedSubscriptions,
      } = entry;
      const {
        id,
        sku,
        stock,
        prices,
        kitEntries,
        identifier,
        galleryImages,
        type,
      } = entry.product;

      const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
      const isBundletItem = kitEntries.length > 0;
      let customerFOA = sessionStorage.getItem('customerFOAData');
      let aboPrice = prices.find((value) => value.type === ABO_MEMBER);
      let foaPrice = prices.find((value) => value.type === FOA_MEMBER);
      aboPrice = parseInt(aboPrice.price);
      foaPrice = parseInt(foaPrice.price);
      const isSOPProduct = entry.hasSopEntries;
      const isRuleBased =
        isSOPProduct && type && type.toLowerCase() === RULE_BASED.toLowerCase();
      const parentQualification =
        qualifiedSubscriptions && qualifiedSubscriptions.length
          ? qualifiedSubscriptions[0]
          : null;
      let isSOPBeyondEntry = false;
      let isSOPBasicEntry = false;
      let sopTagMsg = '';
      let tagClr = '';
      let sopChildEntries = [];
      let totalSopChildQty = 0;

      if (
        isRuleBased &&
        kitProductTotalPriceInclTax &&
        kitProductTotalPriceInclTax.price &&
        parseInt(kitProductTotalPriceInclTax.price) > 0
      ) {
        aboPrice = kitProductTotalPriceInclTax.price;
        foaPrice = kitProductTotalPriceInclTax.price;
      }

      if (isRuleBased && identifier) {
        isSOPBeyondEntry = identifier
          .toLowerCase()
          .includes(SOP_BEYOND.toLowerCase());
        isSOPBasicEntry = identifier
          .toLowerCase()
          .includes(SOP_BASIC.toLowerCase());

        sopTagMsg = isSOPBeyondEntry
          ? 'SOP บียอนด์'
          : isSOPBasicEntry
          ? 'SOP เบสิค'
          : '';
        tagClr = isSOPBeyondEntry
          ? 'beyond-clr'
          : isSOPBasicEntry
          ? 'basic-clr'
          : '';
      } else if (entry.hasSopEntries) {
        sopTagMsg = 'SOP Flex';
        tagClr = 'flex-clr';
      }

      if (
        isRuleBased &&
        (isSOPBeyondEntry || isSOPBasicEntry) &&
        parentQualification
      ) {
        sopChildEntries = this.getSopChildEntries(parentQualification);
        totalSopChildQty = sopChildEntries.reduce((sum, entry) => {
          return sum + entry.quantity;
        }, 0);
      }

      const { accountType = '' } = this.state.cart[0]?.account;
      const isAboType = isCartABOType(accountType);
      const isABOMemberType = isCartABOMemberType(accountType);

      if (isBundletItem) {
        const props = {
          id: id,
          img: img,
          key: index,
          code: sku,
          alias: entry.alias,
          amwayValue: isAboType ? amwayValue : null,
          stock: stock,
          total: quantity,
          title: identifier,
          hasSopEntries: isSOPProduct,
          kitEntries: kitEntries,
          quantity: entry.quantity,
          price: parseInt(totalPrice.price),
          totalPrice: this.state.totalPrice,
          setTotalPrice: this.setTotalprice,
          productItemType: ProductItemType.REMOVE,
          totalSelected: this.state.totalSelected,
          setTotalSelected: this.setTotalSelected,
          setProductSelected: this.setProductSelected,
        };
        // return <BundleProductItem key={entry.alias} {...props} />;

        return (
          <div className="remove-promotion bundle" key={index}>
            <section
              className="flex card-product-container mb-16"
              style={{ backgroundColor: 'transparent' }}
            >
              <Checkbox
                checked={this.state.selected.includes(entry.id)}
                onClick={() => this.handleSelect(entry.id)}
                svg={this.renderSvgForCheckbox()}
              />
              <BundleProductItem {...props} />
            </section>
          </div>
        );
      }

      const props = {
        img: img,
        code: sku,
        amwayValue: isAboType ? amwayValue : null,
        id: entry.id,
        stock: stock,
        price: isABOMemberType ? aboPrice : foaPrice,
        cartId: cart.id,
        title: identifier,
        hasSopEntries: isSOPProduct,
        total: entry.quantity,
        // callback: this.onUpdateCart,
        productItemType: ProductItemType.REMOVE,
        totalPrice: parseInt(totalPrice.price),
        sopTagMsg: sopTagMsg,
        tagClr: tagClr,
        sopChildEntries: sopChildEntries,
        totalSopChildQty: totalSopChildQty,
      };

      return (
        <div className="remove-promotion" key={index}>
          <section
            className="flex card-product-container mb-16"
            style={{ backgroundColor: 'transparent' }}
          >
            <Checkbox
              checked={this.state.selected.includes(entry.id)}
              onClick={() => this.handleSelect(entry.id)}
              svg={this.renderSvgForCheckbox()}
            />
            <Product {...props} />
          </section>
        </div>
      );
    });
  }

  getSopChildEntries(qualifiedSubscriptions) {
    const childEntries = [];
    const entries = [];
    const { cycleSequence, elementSequence, subscriptionIdentifier } =
      qualifiedSubscriptions;
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return entries;
    }
    cart.entries.forEach((entry, index) => {
      if (
        entry.hasSopEntries &&
        entry.kitEntryCode &&
        entry.qualifiedSubscriptions &&
        entry.qualifiedSubscriptions.length
      ) {
        const subscription = entry.qualifiedSubscriptions[0];
        const childCycleSequence = subscription.cycleSequence;
        const childElementSequence = subscription.elementSequence;
        const childSubscriptionIdentifier = subscription.subscriptionIdentifier;

        if (
          cycleSequence === childCycleSequence &&
          elementSequence === childElementSequence &&
          subscriptionIdentifier === childSubscriptionIdentifier
        ) {
          childEntries.push(entry);
        }
      }
    });

    if (childEntries && childEntries.length) {
      childEntries.forEach((entry, index) => {
        const { galleryImages, identifier } = entry.product;
        const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
        entries.push({ img, identifier, quantity: entry.quantity });
      });
    }

    return entries;
  }

  goToCart() {
    const urlParams = queryString.parse(this.props.location.search);
    if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
      if (!urlParams.cartId) {
        alert(message.error.cart);
      }
      let path = `${commonConstant.pathCartPromotion}?isInstallment=TRUE&cartId=${urlParams.cartId}`;
      return this.props.history.push(path)
    }
    this.props.history.push(commonConstant.pathCartPromotion)
  }

  async renderSelectedPaymentCart(paymentType) {
    if (paymentType === PAYMENT_OPTION.ZIPP) {
      await this.fetchZIPPCart();
      this.setState({
        cart: this.state.zippCart,
        productEntries: this.state.zippProductEntries,
        selectedPaymentTab: PAYMENT_OPTION.ZIPP,
        selected: [],
        loading: false,
      });
    } else if (paymentType === PAYMENT_OPTION.PIF) {
      await this.fetchPIFCart();
      this.setState({
        cart: this.state.pifCart,
        productEntries: this.state.pifProductEntries,
        selectedPaymentTab: PAYMENT_OPTION.PIF,
        selected: [],
        loading: false,
      });
    }

    if (
      this.state.cart &&
      this.state.cart.length &&
      this.state.cart[0].entries
    ) {
      for (const entry of this.state.cart[0].entries) {
        if (entry.kitEntryCode) {
          this.setProductSelected(
            entry.product.id,
            entry.quantity,
            UpdateState.INIT,
            entry.kitEntryCode,
          );
        }
      }
    }
  }

  renderCartSelectionTabBar() {
    const pifCartEntriesLength = this.state.pifProductEntries.length
      ? this.state.pifProductEntries.length
      : 0;
    const zippCartEntriesLength = this.state.zippProductEntries.length
      ? this.state.zippProductEntries.length
      : 0;

    return (
      <div className="cartTabBar">
        <div
          className="cartPaymentSelectionTab"
          onClick={() => this.renderSelectedPaymentCart(PAYMENT_OPTION.PIF)}
        >
          <div className="cartPaymentSelectionText">
            ชำระเต็มจำนวน ({pifCartEntriesLength})
          </div>
          <div
            className="selectedCartTabBar"
            style={
              this.state.selectedPaymentTab === PAYMENT_OPTION.ZIPP
                ? { visibility: 'hidden' }
                : {}
            }
          ></div>
        </div>
        <div
          className="cartPaymentSelectionTab"
          onClick={() => this.renderSelectedPaymentCart(PAYMENT_OPTION.ZIPP)}
        >
          <div className="cartPaymentSelectionText">
            ผ่อนชำระ ({zippCartEntriesLength})
          </div>
          <div
            className="selectedCartTabBar"
            style={
              this.state.selectedPaymentTab === PAYMENT_OPTION.PIF
                ? { visibility: 'hidden' }
                : {}
            }
          ></div>
        </div>
      </div>
    );
  }

  async gotoShopping() {
    let msgText = 'โปรโมชั่น';
    const liffProfile = await liffGetProfile();
    const getCustomerProfile = await getCustomerFOA(liffProfile.userId);
    if (
      getCustomerProfile.type === 'foa' ||
      getCustomerProfile.type === 'public'
    ) {
      msgText = 'สินค้าแนะนำ';
    }

    liff
      .sendMessages([
        {
          type: 'text',
          text: msgText,
        },
      ])
      .then(() => {
        console.log('message sent', msgText);
        liff.closeWindow();
      })
      .catch((err) => {
        console.log('error', err);
        liff.closeWindow();
      });
  }

  isCartEmpty() {
    return (
      !this.state.loading &&
      (!this.state.cart || this.state.cart[0].entries.length === 0)
    );
  }

  renderEmptyCart() {
    return (
      <>
        {this.renderCartSelectionTabBar()}
        <div className="empty-cart-div">
          <div className="empty-cart-container">
            <img
              className="empty-cart-img"
              src="/images/promotion/cart_not_found.png"
            />
            <div className="empty-cart-msg-div">
              <div className="empty-cart-label">ตะกร้าว่าง</div>
              <div className="empty-cart-productmsg">
                คุณยังไม่มีสินค้าในตะกร้า
              </div>
            </div>
            <button className="btn-shop-now" onClick={this.gotoShopping}>
              ไปช็อปเลย
            </button>
          </div>
        </div>
      </>
    );
  }

  renderSvgForCheckbox() {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ top: '0px', left: '0px' }}
      >
        <g id="icon-check">
          <path
            id="Icon-check (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4793 3.53588C14.7356 3.80021 14.7291 4.22227 14.4647 4.47858L6.21475 12.4786C5.95616 12.7293 5.54515 12.7293 5.28655 12.4786L1.53655 8.84222C1.27223 8.5859 1.26574 8.16384 1.52205 7.89952C1.77837 7.6352 2.20043 7.6287 2.46475 7.88502L5.75065 11.0713L13.5366 3.52138C13.8009 3.26507 14.2229 3.27156 14.4793 3.53588Z"
            fill="#FAFAFA"
          />
        </g>
      </svg>
    );
  }

  renderCartItems() {
    if (this.isCartEmpty()) {
      return <section>{this.renderEmptyCart()}</section>;
    }

    return (
      <>
        {this.renderCartSelectionTabBar()}
        <div
          className="product-panel scroll-auto"
          style={{ height: 'calc((100vh - 52px) * 0.90)' }}
        >
          <div className="flex-between font-small pb-10">
            <span className="product-list-label">รายการสินค้า</span>
            <span className="link-text underline font-stock" onClick={this.goToCart}>
              ยกเลิก
            </span>
          </div>
          {this.displayItem2()}
        </div>
        <div
          className="remove-promotion footer"
          style={{ height: 'calc((100vh - 52px) * 0.10)' }}
        >
          <div
            className="flex-between font-medium"
            style={{ width: '100%', height: '100%', alignItems: 'center' }}
          >
            <div className="flex-between font-medium">
              <Checkbox
                containerClassname="remove-all"
                checked={this.state.isSelectAll}
                onClick={this.handleSelectAll}
                svg={this.renderSvgForCheckbox()}
              />
              <div
                className="remove-all-product"
                style={{ margin: 'auto', paddingLeft: 4 }}
              >
                เลือกทั้งหมด
              </div>
            </div>
            <button
              className="btn-remove"
              onClick={this.onDelete}
              disabled={this.state.selected.length === 0}
              style={
                this.state.selected.length === 0
                  ? { backgroundColor: '#D9D9D9', color: '#9EA0A3'}
                  : {}
              }
            >{`ลบ (${this.state.selected.length})`}</button>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <section>
        <LoadingOverlay
          text="Loading"
          spinner
          active={this.state.loading}
          styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />
        {this.renderCartItems()}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.cartApi.data,
    loading: state.cartApi.loading,
    error: state.cartApi.error,
  };
};

const mapDispatchToProps = (dispatch)=> {
  return {
    getCartData: (fields) => dispatch(getCartData(fields)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RemovePromotion);

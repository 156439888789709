import React from "react";
import "./QuizHeader.scss";
import { ChallengeHeader } from "../../../../common";

const QuizHeader = (props) => {
    const { leftIcon, rightIcon, isSticky, handleLeftIconRedirection, handleRightIconRedirection } = props;

    return (
        <div className='quiz-header-bottom'>
            <ChallengeHeader
                leftIcon={leftIcon}
                badgeClassname={null}
                challengeDate={null}
                rightIcon={null}
                isSticky={isSticky}
                leftIconRedirect={handleLeftIconRedirection} />
        </div>
    )
}

export default QuizHeader;
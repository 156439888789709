import React from "react";
import { TeamRectCard } from "../../../../common";
import { BADGE_ACTIVE_ICON, CHALLENGE } from "../../../../common/challengeConstants";
import { roundOffDecimalVal, removeMinusFromString } from "../../../../utill.func";
import './TeamCompositionChange.scss';
import variables from '../../../../common/commonConstant.scss';

const TeamCompositionChange = ({
    compositionData, showWeightOutTitle
}) => {
    const {
        totalTeamFat,
        totalTeamMuscle,
        totalTeamWeight,
        bmiInRangeCount,
        teamFatChange,
        teamMuscleChange,
        teamWeightChange,
        teamDashboardStatus,
        teamFatChangeIcon,
        teamMuscleChangeIcon,
        teamWeightChangeIcon,
    } = compositionData;

    return (
        <div className="composition-card-container">
            <div className="composition-change-heading">{CHALLENGE.teamDashboard.teamCompositionHeading}</div>
            {showWeightOutTitle? (<div className="composition-change-message">{CHALLENGE.teamDashboard.teamCompositionFinalWeighing}</div>): (<div className="composition-change-message">
                {CHALLENGE.teamDashboard.teamCompositionMessage}<br/>{CHALLENGE.teamDashboard.teamCompositionSubMessage}
                </div>) }
            <div className="align-half-cards">
                <div className="challenge-card-body w-50">
                    <TeamRectCard compCard
                        compositionHeading={CHALLENGE.teamDashboard.teamWeightHeading}
                        headingText={totalTeamWeight ? roundOffDecimalVal(totalTeamWeight) : '-'}
                        rectTitle={roundOffDecimalVal(removeMinusFromString(teamWeightChange))}
                        unit={CHALLENGE.teamDashboard.kilogram}
                        teamDashboardStatus={teamDashboardStatus}
                        badgeClassname={teamWeightChangeIcon}
                        compositionChange={teamWeightChange}
                    />
                </div>
                <div className="challenge-card-body w-50">
                    <TeamRectCard compCard
                        compositionHeading={CHALLENGE.teamDashboard.teamFatHeading}
                        headingText={totalTeamFat ? roundOffDecimalVal(totalTeamFat) : '-'}
                        rectTitle={roundOffDecimalVal(removeMinusFromString(teamFatChange))}
                        unit={CHALLENGE.teamDashboard.kilogram}
                        teamDashboardStatus={teamDashboardStatus}
                        badgeClassname={teamFatChangeIcon}
                        compositionChange={teamFatChange}
                    />
                </div>
            </div>
            <div className="align-half-cards">
                <div className="challenge-card-body w-50">
                    <TeamRectCard compCard
                        compositionHeading={CHALLENGE.teamDashboard.teamMuscleHeading}
                        headingText={totalTeamMuscle ? roundOffDecimalVal(totalTeamMuscle) : '-'}
                        rectTitle={roundOffDecimalVal(removeMinusFromString(teamMuscleChange))}
                        unit={CHALLENGE.teamDashboard.kilogram}
                        teamDashboardStatus={teamDashboardStatus}
                        badgeClassname={teamMuscleChangeIcon}
                        compositionChange={teamMuscleChange}
                        muscle
                    />
                </div>
                <div className="challenge-card-body w-50">
                    <TeamRectCard compCard
                        compositionHeading={CHALLENGE.teamDashboard.bmi}
                        headingText={bmiInRangeCount}
                        subHeadingText={BADGE_ACTIVE_ICON}
                        rectTitle={CHALLENGE.teamDashboard.bmiTag}
                        teamDashboardStatus={teamDashboardStatus}
                        bmiBadgeIconColor={variables.bmiNormalText}
                    />
                </div>
            </div>
        </div>
    )
}

export default TeamCompositionChange;
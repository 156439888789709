import React from 'react';
import './MyDashboardStepsLoader.scss';

const MyDashboardStepsLoader = ({ className = null }) => {
  return (
    <div className={`my-dashboard-step-container ${className || ''}`}>
      <div className="steps-skeleton-container">
        <div className={`steps-skeleton-card-container ${className || ''}`}>
          <div className="skeleton-card-image skeleton"></div>
        </div>
      </div>
    </div>
  );
};

export default MyDashboardStepsLoader;

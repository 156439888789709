import React, { useState } from "react";
import { useEffect } from "react";
import { CHALLENGE, ChallengeTeamdashboardStatus, WEIGHT_IN_OUT_BUTTONS } from "../../../../common/challengeConstants";
import ChallengeNavButton from "../../../../common/challengeNavButton/ChallengeNavButton";
import { challengeOngoingStatus, roundOffDecimalVal } from "../../../../utill.func";
import './ChallengeMyCompositionCard.scss';

const ChallengeMyCompositionCard = ({ registerUserData, weightOutUserData, teamDashboardStatus, hasUserWeighOut, afterWeightOutPeriod }) => {

    const [registeredData, setRegisteredData] = useState(registerUserData);
    const handleWeightOutClass = !registeredData ? 'bmi-green' : 'active-color';
    const isChallengeOngoing = challengeOngoingStatus(teamDashboardStatus, ChallengeTeamdashboardStatus.ongoing);


    useEffect(() => {
        if(isChallengeOngoing || afterWeightOutPeriod) {
            setRegisteredData(registerUserData);
        }
    },[teamDashboardStatus]);


    const setData = (index) => {
        const value = (index == 0) ? true: false;
        const data = value ? registerUserData : weightOutUserData;
        setRegisteredData(data);
    }

    const handleDisableAndActiveTabs = () => {
        return teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team
            ? 'disable-color'
            : handleWeightOutClass;
    }
    
    const getValue = (key) => {
        return registeredData?.[key] ? roundOffDecimalVal(registeredData?.[key]) : '-';
    }

    const renderBodyContent = (heading, key, unit) => {
        return (
            <div className="my-composition-body">
                <span className="my-body-heading">{heading}</span>
                <div className="my-body-values-container"> 
                    <div className={`my-body-value ${handleDisableAndActiveTabs()}`}>{getValue(key)}</div>
                    <div className="my-body-unit">{unit}</div>
                </div>
            </div>
        )
    }

    const renderHeadingContent = () => {
        return (
            <>
                {isChallengeOngoing || afterWeightOutPeriod ?
                    <div className="nav-buttons-margin">
                        <ChallengeNavButton tabList={WEIGHT_IN_OUT_BUTTONS} onTabButtonChange={setData} />
                    </div> :
                    <div className="my-composition-heading">{CHALLENGE.teamDashboard.weightInBeforeChallenge}</div>
                }
            </>
        )
    }

    return (
        <div className="my-composition-card-container">
            {renderHeadingContent()}
            <div className="challenge-card-body">
                {renderBodyContent(CHALLENGE.myDashboard.height,
                    'height',
                    CHALLENGE.myDashboard.heightUnit)}
                <div className="dividing-line"></div>
                {renderBodyContent(CHALLENGE.myDashboard.weight,
                    'weight',
                    CHALLENGE.myDashboard.kilogram)}
                <div className="dividing-line"></div>
                {renderBodyContent(CHALLENGE.myDashboard.fatMass,
                    'fat',
                    CHALLENGE.myDashboard.kilogram)}
                <div className="dividing-line"></div>
                {renderBodyContent(CHALLENGE.myDashboard.muscleMass,
                    'muscle',
                    CHALLENGE.myDashboard.kilogram)}
                <div className="dividing-line"></div>
                {renderBodyContent(CHALLENGE.teamDashboard.bmi, 
                   'bmi',
                    '')}
            </div>
        </div>
    )
}

export default ChallengeMyCompositionCard;
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import queryString from 'query-string';
import ChallengePullUp from './ChallengePullUp';
import { fetchChallengeTeamDetail, fetchUserDetailsData, fetchViewedBadgeCount, updateViewBadgeCount } from '../../../actions';
import ChallengeMapLoading from './ChallengeMapLoading';
import ChallengeUnlocked from './ChallengeUnlocked';
import ChallengeMapUserPin from './ChallengeMapUserPin';
import teamPoints from '../../../common/challenge/mapHelper/TeamPoints';
import buddyPoints from '../../../common/challenge/mapHelper/BuddyPoints';
import { fadeOutMainPoints } from '../../../common/challenge/mapHelper';
import { getCalorieByTotalSteps, getDataFromSession, getParamsString } from '../../../utill.func';
import { commonConstant } from '../../../common';
import {
  allUsersMarkerTranslateMapping,
  BADGE_MAPPING as TEAM_BADGE_MAPPING,
  BUDDY_ME_BADGE_MAPPING,
  BADGES_MILESTONE as TEAM_BADGES_MILESTONE,
  BUDDY_ME_BADGES_MILESTONE,
  CHALLENGE,
  CHALLENGE_MAP,
} from '../../../common/challengeConstants';
import './ChallengeMap.scss';

const ChallengeMap = () => {
  const [scale, setScale] = useState(CHALLENGE_MAP.INITIAL_SCALE);
  const [isApiData, setIsApiData] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [displayBadge, setDisplayBadge] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialPositionX, setInitialPositionX] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [badgeNumber, setBadgeNumber] = useState(0);
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const params = queryString.parse(location.search);
  const mapRef = useRef(null);

  const challengeTeamDashboard = useSelector((state) => state.challengeTeamDashboard);
  const { teamData, userDetail = [], stepsBadgeViewedData } = challengeTeamDashboard;
  const { missionUserData, usersStepsList = [] } = teamData;
  const challengeId = params['challengeId'] || teamData?.eventId;
  const eventId = params['eventId'];
  const { partyId, amwayNumber } = getDataFromSession('customer');
  const [textBubbleList, setTextBubbleList] = useState(Array(4).fill(false));
  const ownRank = usersStepsList?.findIndex(user => user.partyId === partyId);
  const mapPoints = teamData.challengeType === 'Team' ? teamPoints : buddyPoints;

  const currentUserStepsData = missionUserData?.steps?.currentUserData;
  const currentUserCaloriesData = missionUserData?.calorie?.currentUserData;

  const fadeOutPoints = fadeOutMainPoints(currentUserStepsData?.positionOnMap);

  const CHALLENGE_MAPPING = {
    TEAM: {
      BADGE_MAPPING: TEAM_BADGE_MAPPING,
      BADGES_MILESTONE: TEAM_BADGES_MILESTONE,
    },
    BUDDY_ME: {
      BADGE_MAPPING: BUDDY_ME_BADGE_MAPPING,
      BADGES_MILESTONE: BUDDY_ME_BADGES_MILESTONE,
    }
  }

  const CHALLENGE_TYPE = teamData.challengeType === 'Team' ? CHALLENGE.type.TEAM : CHALLENGE.type.BUDDY_ME;

  useEffect(() => {
    let intervalId;
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    setLoading(true);
    if (!usersStepsList.length) {
      setIsApiData(true);
      getChallengeTeamDashboardData()
        .finally(() => {
          setLoading(false);
          setIsApiData(false);
        });
    }
    intervalId = setInterval(getChallengeTeamDashboardData, CHALLENGE.teamDashboard.refreshInterval);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getChallengeTeamDashboardData = async () => {
    try {
      await dispatch(fetchChallengeTeamDetail({ amwayNumber, partyId, challengeId }));
    } catch (error) {
      throw error;
    }
  }

  const calculateImagePositioning = () => {
    if (currentUserStepsData && divRef?.current) {
      const currentUserLocation = mapPoints[currentUserStepsData?.positionOnMap];
      const mapImageWidth = divRef?.current?.clientWidth;
      const user1XPercent = parseFloat(currentUserLocation.left);
      const user1XPixel = (user1XPercent / 100) * mapImageWidth; //876
      const halfViewportWidth = window.innerWidth / 2; //187.5
      let initialX = halfViewportWidth - user1XPixel;
      if ((mapImageWidth - user1XPixel) < halfViewportWidth) {
        initialX += (mapImageWidth - user1XPixel);
      }
      setInitialPositionX(initialX * scale);
    }
  }

  useEffect(() => {
    const viewedBadgeCount = stepsBadgeViewedData?.badgeViewedCount;
    const receivedBadgeCount = currentUserStepsData?.data?.badges_count;
    if (!isNaN(receivedBadgeCount) && !isNaN(viewedBadgeCount) && viewedBadgeCount < CHALLENGE_MAPPING[CHALLENGE_TYPE].BADGE_MAPPING.length && (receivedBadgeCount > viewedBadgeCount)) {
      setDisplayBadge(true);
      setBadgeNumber(viewedBadgeCount + 1);
    }
  }, [stepsBadgeViewedData?.badgeViewedCount, currentUserStepsData?.data?.badges_count]);

  useEffect(() => {
    divRef?.current?.clientWidth && calculateImagePositioning();
    stepsBadgeViewedData?.badgeViewedCount === undefined && dispatch(fetchViewedBadgeCount(eventId))
      .catch(() => setLoading(false));
  }, [divRef?.current?.clientWidth, challengeTeamDashboard]);

  useEffect(() => {
    if (!userDetail.length && teamData?.challengePartyIds) {
      dispatch(fetchUserDetailsData(teamData?.challengePartyIds));
    }
  }, [teamData?.challengePartyIds]);

  useEffect(() => {
    initialPositionX && mapRef.current && mapRef.current.setTransform(Math.min(initialPositionX, 0), 0, 1);
  }, [initialPositionX])

  const handleZoom = (event) => {
    setScale(event.state.scale < 1 ? 1 : event.state.scale);
  };

  const navigateToTeamDashbaord = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      partyId: partyId
    }

    history.push(`${teamData.challengeType === 'Me' ? commonConstant.pathChallengeMyDashboard
      : commonConstant.pathChallengeTeamDashboard}?${getParamsString(params)}`);
  }

  const handleLocationClick = (index) => {
    if (index && fadeOutPoints.includes(index)) {
      const params = `challengeId=${challengeId}&eventId=${eventId}&milestone=${mapPoints[index].label}`;
      history.push(`${commonConstant.pathChallengeMapDetail}?${params}`);
    }
  }

  const getUserProfileUrl = (userPartyId) => {
    if (teamData?.users?.length) {
      const userProfileInfo = userDetail.find(pictureUrlObj => pictureUrlObj.partyId === userPartyId);
      return userProfileInfo?.pictureUrl;
    }
  }

  const getTotalStepsOfUser = (userPartyId) => missionUserData?.steps?.userData?.find(user => user?.partyId === userPartyId);

  const handleUserPinClick = (index, userPartyId) => {
    const newTextBubbleList = [...textBubbleList];
    newTextBubbleList.forEach((item, i) => newTextBubbleList[i] = (i === index && currentUserStepsData?.data?.value !== getTotalStepsOfUser(userPartyId)?.data?.value));
    setTextBubbleList(newTextBubbleList);
  }

  const handleBadgePopupSubmit = () => {
    (currentUserStepsData?.data?.badges_count === badgeNumber || badgeNumber >= CHALLENGE_MAPPING[CHALLENGE_TYPE].BADGE_MAPPING.length) ?
      setDisplayBadge(false) : setBadgeNumber(badgeNumber + 1);
    setLoading(true);
    dispatch(updateViewBadgeCount(badgeNumber, eventId))
      .finally(() => setLoading(false));
  }

  const calculateUserPinMapping = useMemo(() => {
    const userPosMapping = usersStepsList.reduce((acc, user) => {
      const userPos = user?.positionOnMap;
      acc[userPos] = acc[userPos] ? acc[userPos] + 1 : 1;
      return acc;
    }, {});

    const sortedPositionMappings = Object.entries(userPosMapping).sort(([firstPosition], [secondPosition]) => secondPosition - firstPosition)
    const pinMappingArray = sortedPositionMappings.reduce((acc, [key, value]) => {
      const pinMapping = allUsersMarkerTranslateMapping[value - 1];
      acc.push(...pinMapping);
      return acc;
    }, []);
    return pinMappingArray;
  }, [usersStepsList]);

  const lastUserPosition = { pos: null, count: 0 };

  const UserPins = usersStepsList?.map((user, index) => {
    const userPos = user?.positionOnMap;
    const UserPinComponent = <div className={`lg-hotspot user-hotspot ${mapPoints[userPos].type === 'pin' ? 'pin' : ''}`}
      key={user?.partyId} style={{
        top: `${lastUserPosition.pos === userPos ? mapPoints[userPos].subPoint[lastUserPosition.count - 1].top : mapPoints[userPos].top}%`,
        left: `${lastUserPosition.pos === userPos ? mapPoints[userPos].subPoint[lastUserPosition.count - 1].left : mapPoints[userPos].left}%`,
        transform: `scale(${1 / scale})`,
        zIndex: mapPoints.length + index + 1,
      }}>
      <ChallengeMapUserPin
        userPinLocationInfo={`${mapPoints[userPos].type === "main" ? calculateUserPinMapping[index] : 'translate(-50%, -50%)'}`}
        onClick={handleUserPinClick}
        show={textBubbleList[index]}
        key={user?.partyId}
        partyId={user?.partyId}
        imgSrc={getUserProfileUrl(user?.partyId)}
        scale={scale} isLeader={user?.isLeader}
        index={index} ownRank={ownRank} />
    </div>;

    lastUserPosition.pos = userPos;
    lastUserPosition.count = lastUserPosition.pos === userPos ? lastUserPosition.count + 1 : 0;

    return UserPinComponent;
  });

  const unlockedBadges = (point, index) => {
    let showBadge;
    let targetBadgeIndex = 0;
    if (point.type === "main" && index) {
      showBadge = fadeOutPoints.includes(index)
      targetBadgeIndex = fadeOutPoints.findIndex((positionIndex) => positionIndex === index);
    }
    return showBadge && (
      <img src={`/images/challenge/badges/steps-${CHALLENGE_MAPPING[CHALLENGE_TYPE].BADGES_MILESTONE[`badge${targetBadgeIndex}`]}-badge@5x.png`} alt="Mission Badge"
        className="main-point-badge" style={{ top: point.badgeY * scale, left: point.badgeX * scale }}
      />
    )
  }

  return (
    <div className="challenge-map-wrapper">
      {loading && <ChallengeMapLoading onLoadFinish={() => !isApiData && setLoading(false)} />}
      <div className="map-details-header">
        <div className="challenge-date"><span>วันที่</span><span>{teamData.challengeDay}</span></div>
        <button className="btn btn-white-linear" onClick={navigateToTeamDashbaord}>
          <img src="/images/challenge/icons/exit.svg" alt="" /></button>
      </div>
      {initialPositionX !== null ? (
        <TransformWrapper
          pinch={{ step: 100 }}
          initialScale={CHALLENGE_MAP.INITIAL_SCALE}
          onZoom={handleZoom}
          initialPositionX={initialPositionX}
          disablePadding={true}
          minScale={1}
          maxScale={3}
          ref={mapRef}
        >
          {() => (
            <>
              <TransformComponent>
                <img id="lg-image" className="lg-image" src={`/images/challenge/${teamData.challengeType === 'Team' ? 'unlock-location' : 'buddy-location-map'}.png`} alt="Map" />
                {mapPoints.map((point, index) => (
                  <div key={index} className={`lg-hotspot ${point.type === "user" ? "user-hotspot" : "point-hotspot"}`}
                    style={{
                      top: `${point.top}%`, left: isMobile && point.type === "main" ? `${parseFloat(point.left)}%` : `${point.left}%`,
                      transform: `scale(${point.type === "main" || point.type === "user" ? 1 / scale : Math.min(1 / scale, 1)})`,
                      ...point.type === 'main' || index === 0 ? { zIndex: mapPoints.length - index } : {},
                    }}
                    onClick={() => handleLocationClick(index)}
                  >
                    {point.type === "main" && !fadeOutPoints.includes(index) && (
                      <img src="/images/challenge/cloudFadingNew.png" alt="Cloud Fading" style={{ height: point.height * scale }}
                        className={`cloud-fading-img ${point.label ? `cloud-fading-${point.label}` : ""} ${fadeOutPoints.includes(index) ? "fade-out" : ""}`}
                      />
                    )}
                    <img
                      src={point.type === "main" ? '/images/challenge/pin-gif.gif' : point.img || '/images/challenge/pin.png'}
                      className={point.type === "main" ? "main-point-img" : point.type === "user" ? "user-img" : "pin-img"}
                      alt={point.type === "main" ? "Main Point" : point.type === "user" ? "User" : "Pin"}
                    />
                    {unlockedBadges(point, index)}
                  </div>
                ))}
                {UserPins}
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) :
        <>
          <div><img
            id="lg-image"
            className="lg-image"
            src="/images/challenge/buddy-location-map.png"
            alt="Map"
            onLoad={calculateImagePositioning}
            ref={divRef}
            style={{ visibility: 'hidden' }}
          /></div>
        </>
      }
      {!loading && <ChallengePullUp badgesCount={currentUserStepsData?.data?.badges_count} title={teamData?.eventData?.eventName} stepsCompleted={currentUserStepsData?.data?.value}
        calories={getCalorieByTotalSteps(currentUserStepsData?.data?.value)}
        dailyData={{ steps: currentUserStepsData?.data?.daily_value, calories: getCalorieByTotalSteps(currentUserStepsData?.data?.daily_value) }} />}
      {<ChallengeUnlocked wrapperClass={!loading && displayBadge ? '' : '--hidden'} badgeNumber={badgeNumber} onClose={handleBadgePopupSubmit} />}
    </div>
  )
}

export default ChallengeMap
import React from "react";
import { TeamRectCard } from "../../../../common";
import { CHALLENGE } from "../../../../common/challengeConstants";
import variables from '../../../../common/commonConstant.scss';
import { roundOffDecimalVal, removeMinusFromString } from "../../../../utill.func";
import './ChallengeMyCompositionChangeCard.scss';

const ChallengeMyCompositionChangeCard = ({ userData, registerUserData, teamDashboardStatus, showWeightOutTitle }) => {

    const renderHeading = () => {
        return (
            <>
                <div className="composition-change-heading">{CHALLENGE.myDashboard.compositionHeading}</div>
                {showWeightOutTitle? (<div className="composition-change-message">{CHALLENGE.teamDashboard.teamCompositionFinalWeighing}</div>): (<div className="composition-change-message">
                {CHALLENGE.teamDashboard.teamCompositionMessage}<br/>{CHALLENGE.teamDashboard.teamCompositionSubMessage}
                </div>) }
            </>
        )
    }

    const renderChallengeCardBody = (
        heading,
        value,
        rectTitle,
        unit,
        teamDashboardStatus,
        badgeClassname,
        bmiBadgeIconColor,
        muscle,
        compositionChange
    ) => {
        return (
            <div className="challenge-card-body w-50">
                <TeamRectCard compCard
                    compositionHeading={heading}
                    headingText={value}
                    rectTitle={rectTitle}
                    unit={unit}
                    teamDashboardStatus={teamDashboardStatus}
                    badgeClassname={badgeClassname}
                    bmiBadgeIconColor={bmiBadgeIconColor}
                    muscle={muscle}
                    compositionChange={compositionChange}
                />
            </div>
        )
    }

    return (
        <div className="my-dashboard-composition-change-container">
            {renderHeading()}
            <div className="align-half-cards">
                {renderChallengeCardBody(CHALLENGE.teamDashboard.teamWeightHeading,
                    registerUserData?.weight ? roundOffDecimalVal(registerUserData?.weight) : '-',
                    userData?.userWeightChange && roundOffDecimalVal(removeMinusFromString((userData?.userWeightChange))),
                    CHALLENGE.teamDashboard.kilogram,
                    teamDashboardStatus,
                    userData?.userWeightChangeIcon,
                    null,
                    false,
                    userData?.userWeightChange
                )}
                {renderChallengeCardBody(CHALLENGE.teamDashboard.teamFatHeading,
                    registerUserData?.fat ? roundOffDecimalVal(registerUserData?.fat): '-',
                    userData?.userFatChange && roundOffDecimalVal(removeMinusFromString((userData?.userFatChange))),
                    CHALLENGE.teamDashboard.kilogram,
                    teamDashboardStatus,
                    userData?.userFatChangeIcon,
                    null,
                    false,
                    userData?.userFatChange
                )}
            </div>
            <div className="align-half-cards">
                {renderChallengeCardBody(CHALLENGE.teamDashboard.teamMuscleHeading,
                    registerUserData?.muscle ? roundOffDecimalVal(registerUserData?.muscle) : '-',
                    userData?.userMuscleChange && roundOffDecimalVal(removeMinusFromString((userData?.userMuscleChange))),
                    CHALLENGE.teamDashboard.kilogram,
                    teamDashboardStatus,
                    userData?.userMuscleChangeIcon,
                    null,
                    true,
                    userData?.userMuscleChange
                )}
                {renderChallengeCardBody(CHALLENGE.teamDashboard.bmi,
                    registerUserData?.bmi ? roundOffDecimalVal(registerUserData?.bmi) : '-',
                    userData?.bmiBadge?.bmiTitle,
                    ' ',
                    teamDashboardStatus,
                    userData?.bmiBadge,
                    userData?.bmiBadge?.bmiBadge?.icon || variables.bmiNormalText,
                    false,
                    null
                )}
            </div>
        </div>
    )
}

export default ChallengeMyCompositionChangeCard;
import React, { useEffect, useState } from 'react';
import './ChallengeNavButton.scss';

const ChallengeNavButton = ({ tabList, onTabButtonChange, isNavSticky, selectedIndex = 0, styleObj, tabListClass }) => {
  const [activeTab, setActiveTab] = useState(selectedIndex);
  const navSticky = isNavSticky ? 'nav-sticky' : '';
  const handleTabButtonClick = (index, value) => {
    setActiveTab(index);
    onTabButtonChange(index, value);
  };

  const getTabClass = (index) => {
    let tabClass = `challenge-tab-list__nav-link`;
    if (activeTab === index) {
      tabClass += ` challenge-tab-list__nav-link-active`;
    }
    return tabClass;
  }

  return (
    <div className={`challenge-tab-list ${navSticky} ${tabListClass ? tabListClass : ''}`}>
      {tabList.map((value, index) =>
        <button
          key={`challengeNavBtn${index + 1}`}
          className={getTabClass(index)}
          onClick={() => handleTabButtonClick(index, value)}
          style={styleObj}
        >
          {value}
        </button>

      )}
    </div>
  );
};
export default ChallengeNavButton;

import React from 'react';
import './ChallengeHeader.scss'
import RectBadge from '../rectBadge/RectBadge';

const ChallengeHeader = (props) => {

    const { leftIcon, badgeClassname, challengeDate, rightIcon, isHeaderSticky, isSticky,
        leftIconRedirect, rightIconRedirect, showRectBadge } = props;
    const quizSticky = isSticky ? 'quizSticky' : '';
    const dashboardSticky = isHeaderSticky ? 'dashboardSticky' : '';

    return (
        <div className={`challenge-header-container ${quizSticky} ${dashboardSticky}`}>
            <div className='challenge-header-left'>
                {leftIcon ? <div className='challenge-header-container-icon' onClick={() => leftIconRedirect()}>
                    <img src={leftIcon} />
                </div> : <div className='challenge-header-container-badge'>
                    <RectBadge badgeClassname={badgeClassname}>
                        <div className='rect-badge-title'>{badgeClassname?.title}</div>
                    </RectBadge>
                </div>}
                {showRectBadge && <div className="challenge-header-container-badge">
                    <RectBadge badgeClassname={badgeClassname}>
                        <div className='rect-badge-title'>{badgeClassname?.title}</div>
                    </RectBadge>
                </div>}
            </div>
            <div className='challenge-header-right'>
                {challengeDate && <div className='challenge-header-container-date'>
                    {challengeDate}
                </div>}
                {rightIcon && <div className='challenge-header-container-icon' onClick={() => rightIconRedirect()}>
                    <img src={rightIcon} />
                </div>}
            </div>
        </div>

    )
}

export default ChallengeHeader;
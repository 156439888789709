import React, { useEffect, useState } from 'react';
import './ChallengeUsers.scss';
import { CHALLENGE_USER_DEFAULT_IMG } from '../../../common/challengeConstants';
import { makeLeaderFirst } from '../../../utill.func';
import ChallengeTag from '../challengeTag/ChallengeTag';

const ChallengeUsers = ({ usersList, showUserTag, status, teamSize }) => {
  usersList = makeLeaderFirst(usersList);
  return (
    <div className="challenge-users-container">
      {usersList.map((user, index) =>
        <span
          key={user.id}
          className={`challenge-users-profile ${user.isLeader ? 'challenge-users-profile-image-leader' : 'challenge-users-profile-image-normal'}`}
        >
          <img
            src={user.profilePic || CHALLENGE_USER_DEFAULT_IMG}
            alt={`user ${index + 1}`}
            className='challenge-users-profile-image'
          />
          {user.isLeader && (
            <img
              src="/images/challenge/crown.svg"
              className="challenge-users-crown"
            />
          )}
        </span>
      )}
      {showUserTag && (
        <div className='challenge-users-tag'>
          <ChallengeTag status={status} teamMissing={teamSize - usersList?.length} />
        </div>
      )}
    </div>
  );
};

export default ChallengeUsers;

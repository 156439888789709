import React from "react";
import "./ChallengeSubmit.scss"
import { CHALLENGE } from "../../../../common/challengeConstants";

const ChallengeSubmit = (props) => {
    const { updateUserAnswers, enableSubmit, navigateToInitial, isQuizAnswered } = props
    return (
        <div className="btn-container">
            {
                isQuizAnswered === CHALLENGE.quizStatus.review &&
                <button disabled={!enableSubmit} onClick={navigateToInitial} className={`btn-green btn-back-white`}>
                    {CHALLENGE.challengeQuiz.reviewBackBtn}
                </button>
            }
            <button disabled={!enableSubmit} onClick={updateUserAnswers}
                className={`btn-green ${enableSubmit ? 'btn-green-linear' : 'btn-white-linear'} ${isQuizAnswered !== CHALLENGE.quizStatus.review ? 'w-100' : ''}`}>
                {CHALLENGE.challengeQuiz.submitButtonText}
            </button>
        </div>
    )
}

export default ChallengeSubmit;
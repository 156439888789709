import React, { useState, useEffect } from 'react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import './TeamBodyDataSlider.scss';
import { TeamDashboardSwiperMember, TeamDashboardSwiperDetail, ChallengeNavButton } from '../../../../common';
import { CHALLENGE, CHALLENGE_BODY_CARD } from '../../../../common/challengeConstants';

SwiperCore.use([Navigation, EffectCoverflow]);

const TeamBodyDataSlider = ({
    bodyUserData,
    teamSize,
    teamDashboardStatus,
    bodyWeightOutUserData,
    challengeUserData,
    afterWeightOutPeriod,
    hasTeamWeighOut,
    isChallengeOngoing
}) => {
    const [swiperData, setSwiperData] = useState(bodyUserData);
    const [weighOutData, setweighOutData] = useState(false);

    useEffect(() => {
        if(isChallengeOngoing || afterWeightOutPeriod) {
            setSwiperData(bodyUserData);
            setweighOutData(false);
        }
    },[teamDashboardStatus]);


    const setData = (index) => {
        const value = (index == 0) ? false: true;
        setweighOutData(value);
        const data = value ? bodyWeightOutUserData : bodyUserData
        setSwiperData(data);
    }

    const renderNavButtons = () => {
        return (
            <ChallengeNavButton
            tabList={CHALLENGE_BODY_CARD.HOME_TABS_LIST}
            onTabButtonChange={setData}
        />
        )
    }

    const _teamBodyDataTitle = () => {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {isChallengeOngoing || afterWeightOutPeriod ?
                            renderNavButtons()
                            : <h6 className="dashboard-slider-title">{CHALLENGE.teamDashboard.bodyDataSliderHeader}</h6>}

                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="dashboard-slider-outer">
                <div className="dashboard-slider-wrapper">
                    {_teamBodyDataTitle()}

                    <div class="dashboard-slider-container">
                        <TeamDashboardSwiperMember challengeUserData={challengeUserData} teamSize={teamSize} />
                        <TeamDashboardSwiperDetail swiperData={swiperData} bodyDataSlider={true} weighOutData={weighOutData}/>
                    </div>
                </div>

            </div>
        </>

    );
};

export default TeamBodyDataSlider
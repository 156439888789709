import { round } from '../services';
import variables from './commonConstant.scss';

export default {};

export const CHALLENGE = {
  teamDashboard: {
    teamInfoStepsHeading: 'ก้าวเดินวันนี้',
    totalStepsHeading: 'ก้าวเดินรวม',
    totalDistanceHeading: 'ระยะทางรวม',
    stepsUnit: 'ก้าว',
    minuteUnit: 'นาที',
    kilometerUnit: 'กม.',
    caloriesUnit: 'แคลอรี่',
    vdoHeading: 'ดูวิดีโอและ',
    vdoSubHeading: 'ทำแบบทดสอบ',
    average: 'เฉลี่ยวันละ',
    cumulativeSteps: 'ก้าวเดินสะสม',
    bmiHeading: 'BMI ดัชนีมวลกาย(ชั่งก่อน)',
    bmiweighOutHeading: 'BMI ดัชนีมวลกาย(ชั่งจบ)',
    bmiMessage: 'ร่างกายสมส่วน',
    teamName: 'ทีม: ',
    teamInfoHeading: 'สมาชิกทีม',
    medals: 'เหรียญรางวัล',
    teamCompositionFinalWeighing: '*คำนวณจากข้อมูลการชั่งจบการแข่งขัน',
    teamCompositionHeading: 'ความเปลี่ยนแปลงร่างกายรวมของทีม',
    teamCompositionMessage: '*คำนวณจากน้ำหนักที่ทำการบันทึกระหว่างแข่งขัน',
    teamCompositionSubMessage: '(จะไม่ถูกนำไปรวมกับการให้รางวัล)',
    totalStepsToBeTakenToday: '10000',
    maxBadges: '40',
    averageMuscleHeading: 'มวลกล้ามเนื้อ เฉลี่ย',
    avergeFatHeading: 'มวลไขมัน เฉลี่ย',
    averageWeightHeading: 'น้ำหนัก เฉลี่ย',
    kilogram: 'กก.',
    teamWeightHeading: 'น้ำหนัก',
    teamFatHeading: 'มวลไขมัน',
    teamMuscleHeading: 'มวลกล้ามเนื้อ',
    bmi: 'BMI',
    teamHeaderBadge: 'นธอ. ใหม่',
    teamStepsCardHeader: 'วันที่',
    bmiTag: 'เกณฑ์ปกติ',
    missingTeam: 'ขาดสมาชิก',
    membersTeam: 'คน',
    maxTeamMembers: 4,
    teamBodyHeading: 'ข้อมูลองค์ประกอบร่างกายรวมของทีม',
    teamBodySubHeading: 'ชั่งก่อนการแข่งขัน',
    weightInAtEndChallenge: 'ชั่งจบการแข่งขัน',
    weightInBeforeChallenge: 'ชั่งก่อนการแข่งขัน',
    teamNewAboTitle: 'นธอ. ใหม่',
    detailBtnTitle: 'ดูรายละเอียดการแข่งขัน',
    distanceConstant: 0.000762,
    missionSliderHeader: 'ภารกิจ 60 วัน สะสมเหรียญพิชิตรางวัล',
    bodyDataSliderHeader: 'ชั่งก่อนการแข่งขัน',
    teamProfileHeader: 'สมาชิกทีม',
    teamProfileFirstName: 'จิดา',
    teamProfileLastName: 'พรหมวงศ์วิจิตร',
    dashboardFooterLeftBtnTitle: 'เหรียญรางวัลของฉัน',
    dashboardFooterRightBtnTitle: 'เข้าสู่แผนที่',
    teamNewMemberTitle: 'สมาชิกใหม่',
    mapLocationTab: 'ปิรามิดแห่งกีซ่า, อียิปต์',
    mapUsersTab: 'อันดับการปลดล็อก',
    mapLocationTitle: 'ปลดล็อกเมื่อ',
    mapGoalTitle: 'เดินอีก',
    mapGoalTitleSteps: 'ก้าว',
    mapGoalSubtitle: 'เพื่อไปยังจุดหมายถัดไป',
    noUser: '-ว่าง-',
    businessOwner: 'businessowner',
    businessMember: 'member',
    refreshInterval: 150000, // 2 minutes 30 seconds 
  },
  me: {
    maxTeamMembers: 1
  },
  buddy: {
    maxTeamMembers: 2
  },
  team: {
    maxTeamMembers: 4
  },
  challengeQuiz: {
    submitButtonText: 'ยืนยันคำตอบ',
    quizVideoText: 'วิดีโอ',
    seeAllText: 'ดูทั้งหมด',
    quizContainerHeading: 'คำถาม',
    quizReviewHeading: 'กรุณายืนยันคำตอบ',
    reviewBackBtn: 'แก้ไข',
  },
  myDashboard: {
    maxUserSteps: '/10,000',
    stepsUnit: 'ก้าว',
    stepTodayHeading: 'ก้าวเดินวันนี้',
    accStepHeading: 'ก้าวเดินสะสม',
    skuText: 'รหัส: ',
    compositionHeading: 'ความเปลี่ยนแปลงร่างกาย',
    compositionMessage: '*คำนวณจากน้ำหนักที่ทำการบันทึกระหว่างแข่งขัน',
    compositionSubMessage: '(จะไม่ถูกนำไปรวมกับการให้รางวัล)',
    height: 'ส่วนสูง',
    weight: 'น้ำหนัก',
    fatMass: 'มวลไขมัน',
    muscleMass: 'มวลกล้ามเนื้อ',
    heightUnit: 'ซม.',
    kilogram: 'กก.',
    missionSectionHeading: 'ภารกิจ ',
    missionSectionSubHeading: ' วัน สะสมเหรียญพิชิตรางวัล',
    fitPerformer: 'Fit Performer',
    fitPerform: 'การออกกำลังกาย',
    maxStepsForTeam: '/600,000',
    bestDiscipliner: 'The Best Discipliner',
    goalKeeper: 'The Goal Keeper',
    bestLearner: 'The Best Learner',
    topLearner: 'Top Learner',
    maxRewardBadges: '/60',
    days: 'วัน',
    videos: 'วิดีโอ',
    points: 'คะแนน',
    badgePoints: 'พอยท์',
    dashboardFooterLeftBtnTitle: 'ภารกิจและรางวัล',
    dashboardFooterRightBtnTitle: 'เข้าสู่แผนที่',
    maxBadgeActiveValue: 50
  },
  mapDetail: {
    share: 'แชร์:',
  },
  badges: {
    smartLearnerHeading: 'Smart Learner',
    smartLearnerHeader: 'การเรียนรู้',
    dietPlanner: 'Diet Planner',
    diePlannerHeader: 'การจัดการแคลอรี',
    perfectWorkout: 'The Best Discipliner',
    achievedBadgeHeading: 'คุณได้รับเหรียญนี้จากก้าวเดินสะสมในการแข่งขัน',
    bodyKey10Heading: 'Bodykey Challenge ครั้งที่ 10',
    accumulating: 'เดินครบ',
    stepsUnit: 'ก้าว',
    disabledHeading: 'เพื่อพิชิตเหรียญนี้',
    disabledSubHeading: 'ตลอดระยะเวลาการแข่งขัน',
    complete: 'คุณต้องมีก้าวเดินสะสมอย่างน้อย',
    usersHeading: 'ประวัติการได้รับเหรียญ',
    FoodMissionBadgeHeading: 'ควบคุมแคลอรีต่อวันไม่ให้เกินเป้าหมายที่ตั้งไว้',
    CalorieMissionBadgeHeading: 'กรอกเมนูอาหารครบ 3 มื้อต่อวัน ตลอด 60 วัน',
    QuizHeading: 'ทำแบบทดสอบตอบถูก 1 ข้อ รับ 1 พอยท์',
    QuizSubHeading: 'สะสมพอยท์อย่างน้อย 50 พอยท์',
    VdoHeading: 'รับชมคลิปวิดีโอความรู้ 1 คลิป รับ 1 พอยท์',
    VdoSubHeading: 'สะสมอย่างน้อย 50 พอยท์',
    FoodNoteText: 'หมายเหตุ: ข้อมูล ณ วันปัจจุบันจะบันทึกคะแนนให้ในวันถัดไป',
    FoodCalorieSubHeading: 'รับ 1 พอยท์ สะสมอย่างน้อย 50 พอยท์',
    receivedWhen: 'ได้รับเมื่อ',
    receivedOn: 'เมื่อ',
    steps: 'steps',
    vdo: 'vdo',
    quiz: 'quiz',
    calorie: 'calorie',
    food: 'food',
    heading: 'เหรียญรางวัลกิจกรรม',
    others: 'others'
  },
  reward: {
    rewardValue: 'มูลค่า',
    rewardCurrency: '฿',
    activityRewards: 'รางวัลกิจกรรม',
    rewardPrize: 'รับรางวัลภายใน',
    rewardDays: 'วัน',
    rewardButton: 'ดูรางวัล',
    approved: 'รอรับรางวัล',
    pending: 'รอชำระเงิน',
    rejected: 'ไม่ผ่านเกณฑ์',
    order_placed: 'รับรางวัลแล้ว',
    waiting_to_received: 'APPROVED',
    not_qualified: 'REJECTED',
    waiting_for_payment: 'PENDING',
    received: 'ORDER_PLACED',
    noRewardTitle: 'ยังไม่มีรางวัลในขณะนี้',
    noRewardImage: '/images/challenge/no-rewards.svg'
  },
  type: {
    TEAM: 'TEAM',
    BUDDY_ME: 'BUDDY_ME',
    BUDDY: 'BUDDY',
    ME: 'ME'
  },
  quizAndVideoButtonText: {
    calorie: 'บันทึกมื้ออาหาร',
    food: 'ตั้งเป้าหมาย',
    vdo: 'ทำภารกิจดูวิดีโอ - ทำแบบทดสอบ',
    quiz: 'ทำภารกิจดูวิดีโอ - ทำแบบทดสอบ'
  },
  quizStatus: {
    initial: 'initial',
    review: 'review',
    submit: 'submit'
  }
};

export const CHALLENGE_LIST = {
  HOME_TABS_MAPPING: [
    { name: 'ongoing', label: 'การแข่งที่เริ่มแล้ว' },
    { name: 'upcoming', label: 'การแข่งที่กำลังมา' },
  ],
  TYPES: {
    upcoming: 'upcoming',
    ongoing: 'ongoing',
    completed: 'completed',
  },
  TYPE_MAP: {
    การแข่งที่เริ่มแล้ว: 'ongoing',
    การแข่งที่กำลังมา: 'upcoming',
  },
  TITLE_MAP: {
    ongoing: 'การแข่งขันที่เริ่มแล้ว',
    upcoming: 'การแข่งขันที่เปิดรับสมัคร',
  },
  CAROUSEL_RENDER_MAP: {
    home: 'home',
    listPage: 'list',
  },
};

export const CHALLENGE_STEPS_FOOD_KEY_MAPPING = {
  steps: {
    id: 'd5fc5080-643c-4e62-a50c-6cb80ed9e039',
    title: 'ก้าวสะสม',
    key_map: 'total_step',
    daily_key_map: 'round_details',
    daily_key_value: 'step',
    unit: '(ก้าว)',
  },
  food: {
    id: '08337165-12cf-4e05-8e41-56a96731d6c1',
    title: 'บันทึกการกิน',
    key_map: 'total_food_log',
    daily_key_map: 'food_log',
    daily_key_value: 'status',
    unit: '(วัน)',
  },
  calorie: {
    id: '8a23fc28-5fa8-4ebe-90bb-1a6f168af746',
    title: 'บันทึกแคลอรี',
    key_map: 'total_calories_log',
    daily_key_map: 'calories_log',
    daily_key_value: 'status',
    unit: '(วัน)',
  },
  vdo: {
    id: '7aef4d28-f767-44ce-a9a4-85eabcac70b3',
    title: 'ดูวิดีโอ',
    key_map: 'total_vdo_log',
    daily_key_map: 'vdo_log',
    daily_key_value: 'link',
    unit: '(วิดีโอ)',
  },
  quiz: {
    id: 'f52547b6-04ea-4418-9f35-64031353de3f',
    title: 'แบบทดสอบ',
    key_map: 'total_badge',
    daily_key_map: '',
    daily_key_value: '',
    unit: '(คะแนน)',
  },
};

export const CHALLENGE_BODY_KEY_MAPPING = {
  height: {
    title: 'ส่วนสูง',
    key: 'height',
    key_map: 'height',
    unit: '(ซม.)',
  },
  weight: {
    title: 'น้ำหนัก',
    key: 'weight',
    key_map: 'weight',
    unit: '(กก.)',
  },
  fat: {
    title: 'มวลไขมัน',
    key: 'fat',
    key_map: 'kg_cal',
    unit: '(กก.)',
  },
  muscle: {
    title: 'มวลกล้ามเนื้อ',
    key: 'muscle',
    key_map: 'kg',
    unit: '(กก.)',
  },
  bmi: {
    title: 'BMI',
    key: 'bmi',
    key_map: 'bmi',
    unit: '',
  },
};

export const WEIGHT_IN_DIFF_MAPPING = {
  weight: {
    key: 'daily',
    key_map: 'weight_change',
  },
  fat: {
    key: 'daily',
    key_map: 'fat_change',
  },
  muscle: {
    key: 'daily',
    key_map: 'muscle_change',
  },
};

export const WEIGHT_OUT_DIFF_MAPPING = {
  weight: {
    key: 'cal',
    key_map: 'weight_change',
  },
  fat: {
    key: 'cal',
    key_map: 'fat_mass_change',
  },
  muscle: {
    key: 'cal',
    key_map: 'muscle_mass_change',
  }
};

export const PERIOD_DATA_MAPPING = {
  registerPeroid: 'REGISTER',
  weightOutPeriod: 'FINAL',
};

export const rectBadgeClassname = {
  not_yet_started: {
    classname: 'no-member-badge',
    title: 'เพิ่มสมาชิกภายใน',
    text: 'ขาด',
    textAfter: 'คน',
  },
  ready_to_start: {
    classname: 'success-badge',
    title: 'พร้อมแข่งขัน',
    text: 'สมาชิกครบ',
    iconUrl: '/images/challenge/icons/challenge-tag-complete.svg',
  },
  ongoing: {
    classname: 'success-badge',
    tagClassName: 'ongoing-badge',
    title: 'กำลังแข่งขัน',
    text: 'แข่งวันที่',
  },
  weight_end: {
    classname: 'warning-badge',
    title: 'ชั่งจบการแข่งขัน',
    text: 'รอชั่งจบ',
    iconUrl: '/images/challenge/icons/challenge-tag-waiting.svg',
  },
  end_challenge: {
    classname: 'no-member-badge',
    tagClassName: 'success-badge',
    title: 'รับรางวัลภายใน',
    iconUrl: '/images/challenge/icons/challenge-tag-complete.svg',
    text: 'แข่งจบแล้ว',
  },
  remove_team: {
    classname: 'error-badge',
    title: 'ออกจากการแข่ง',
    text: 'ออกจากการแข่ง',
  },
  reward_period: {
    classname: 'no-member-badge',
    tagClassName: 'success-badge',
    title: 'รับรางวัลภายใน',
    text: 'แข่งจบแล้ว',
    iconUrl: '/images/challenge/icons/challenge-tag-complete.svg',
  },
  yet_to_watch: {
    classname: 'yet-to-watch-badge',
    title: 'ยังไม่ได้ดู',
    text: 'ยังไม่ได้ดู',
  },
  watch_not_fully: {
    classname: 'watch-unsuccessful-badge',
    title: 'ยังดูไม่สำเร็จ',
    text: 'ยังดูไม่สำเร็จ',
  },
  watch_successful: {
    classname: 'watch-success-badge',
    title: 'ดูสำเร็จแล้ว',
    text: 'ดูสำเร็จแล้ว',
  }
};

export const singleBadgeClassname = {
  not_yet_started: {
    classname: 'error-badge',
    title: 'ทีมจะถูกยกเลิกภายใน',
    icon: '/images/challenge/icons/team-dashboard-caution.svg',
    subtitle: 'วัน หากสร้างทีมไม่สำเร็จ',
  },
  weight_end: {
    classname: 'warning-badge',
    title: 'เหลือเวลาชั่งจบ',
    icon: '/images/challenge/icons/team-dashboard-caution-weight.svg',
    subtitle: 'วัน',
  },
};

export const teamInfoBadgeClass = {
  profile: {
    title: 'นธอ. ใหม่',
  },
};

export const teamMaxSize = 4;

export const challengeTypeMaxSize = {
  ME: { type: 'me', maxSize: 1 },
  BUDDY: { type: 'buddy', maxSize: 2 },
  TEAM: { type: 'team', maxSize: 4 }
};

export const CHALLENGE_QUIZ_LABELS = [
  { label: 'ก' },
  { label: 'ข' },
  { label: 'ค' },
  { label: 'ง' },
];

export const CHALLENGE_ANSWER_LABELS = {
  correctAns: '/images/challenge/correct.svg',
  incorrectAns: '/images/challenge/incorrect.svg',
};

export const QUIZ_HEADER = {
  LEFT_ICON: '/images/challenge/arrow-black.svg',
  RIGHT_ICON: '/images/challenge/team-dashboard.svg',
};

export const QUIZ_REVIEW_HEADER = {
  LEFT_ICON: '/images/challenge/arrow-black.svg',
};

export const VIDEO_DETAIL_HEADER = {
  LEFT_ICON: '/images/challenge/arrow-black.svg',
  RIGHT_ICON: '/images/challenge/team-dashboard.svg',
};

export const QUIZ_DETAILS = {
  quiz_pending: {
    score_text: 'ข้อ',
    date_text: 'ภายใน',
    time_img_url: '/images/challenge/time.svg',
    time_className: '',
  },
  quiz_completed: {
    score_text: 'พอยท์',
    date_text: 'ทำเสร็จเมื่อ',
    time_img_url: '/images/challenge/Solid-Circle-Check.svg',
    time_className: 'time-value-green',
  },
};

export const ChallengeTeamdashboardStatus = {
  not_yet_started: 'not_yet_started',
  ready_to_start: 'ready_to_start',
  weight_end: 'weight_end',
  remove_team: 'remove_team',
  end_challenge: 'end_challenge',
  ongoing: 'ongoing',
  reward_period: 'reward_period',
};

export const noChallengeText = {
  top: 'เตรียมพบกับการแข่งขัน',
  middle: 'หลากหลายรูปแบบจากแอมเวย์',
  bottom: 'เร็วๆ นี้!',
};

export const CHALLENGE_DETAIL = {
  maxContentHeight: 500,
  minContentHeight: 400,
  minContentHeightSm: 330,
  dragEvents: ['mousemove', 'touchmove'],
  dragEndEvents: ['mouseup, touchend'],
  dateFormat: 'dd MMM yyyy',
  buttonMap: {
    registered: 'แดชบอร์ดของฉัน',
    participate: 'เข้าร่วม',
    invite: 'ชวนเพื่อน',
  },
  buttonTypes: {
    registered: 'registered',
    participate: 'participate',
    invite: 'invite',
  },
};

export const CHALLENGE_MAP = {
  INITIAL_SCALE: 1,
  MAP_WIDTH: 534,
  MAP_HEIGHT: 300,
  BUBBLE_AHEAD_MESSAGE: 'รีบตามมานะ 😎',
  BUBBLE_BEHIND_MESSAGE: 'กำลังตามไปจ้า! 🤩',
};

export const MAP_TARGET_BADGE_IMG = [
  {
    target_img: 'egypt',
    thumbnailText: 'พีระมิดแห่งกีซา',
    badge_unlocked_img: '',
  },
  {
    target_img: 'peru',
    thumbnailText: 'มาชูปิกชู',
    badge_unlocked_img: 'egypt',
  },
  {
    target_img: 'usa',
    thumbnailText: 'เทพีเสรีภาพ',
    badge_unlocked_img: 'peru',
  },
  {
    target_img: 'france',
    thumbnailText: 'หอไอเฟล',
    badge_unlocked_img: 'usa',
  },
  {
    target_img: 'nepal',
    thumbnailText: 'เอเวอเรสต์',
    badge_unlocked_img: 'france',
  },
  {
    target_img: 'japan',
    thumbnailText: 'ภูเขาไฟฟูจิ',
    badge_unlocked_img: 'nepal',
  },
];
export const BUDDY_ME_MAP_TARGET_BADGE_IMG = [
  {
    target_img: 'khaoyai',
    thumbnailText: 'เขาใหญ่',
    badge_unlocked_img: '',
  },
  {
    target_img: 'inthanon',
    thumbnailText: 'ดอยอินทนนท์',
    badge_unlocked_img: 'khaoyai',
  },
  {
    target_img: 'myanmar',
    thumbnailText: 'พุกาม',
    badge_unlocked_img: 'inthanon',
  },
  {
    target_img: 'laos',
    thumbnailText: 'วังเวียง',
    badge_unlocked_img: 'myanmar',
  },
  {
    target_img: 'cambodia',
    thumbnailText: 'นครวัด',
    badge_unlocked_img: 'laos',
  },
  {
    target_img: 'vietnam',
    thumbnailText: 'บาน่าฮิลล์',
    badge_unlocked_img: 'cambodia',
  },
];

export const CHALLENGE_MAP_LOADING = [
  { value: 50, message: 'กำลังสร้างแผนที่ให้กับคุณ' },
  { value: 95, message: 'เตรียมตัวให้พร้อม!' },
  { value: 100, message: 'ยินดีต้อนรับสู่เกมแผนที่!' },
];

export const CHALLENGE_MAP_PULLUP = {
  TAB_TOTAL_STEPS: 'ก้าวเดินสะสม',
  TAB_DAILY_STEPS: 'ก้าวเดินวันนี้',
  GREETING_TEXT: 'ขอแสดงความยินดีด้วย',
  CHALLENGE_COMPLETE_MESSAGE: 'คุณเข้าเส้นชัย และพิชิตแผนที่ทั้งหมดแล้ว!',
  STEPS_UNIT: 'ก้าว',
  DISTANCE_UNIT: 'กม.',
  CALORIE_UNIT: 'แคลอรี',
  WALK_TITLE: 'เดินอีก',
  DESTINATION: 'เพื่อไปยังจุดหมายถัดไป',
  TOTAL_TARGET: 600000,
  STEPS_INTERVAL: 100000,
  DAILY_STEPS_TARGET: 10000,
  TOTAL_MILESTONES: 6,
};

export const ALL_STEPS_BADGES = [
  'egypt',
  'peru',
  'usa',
  'france',
  'nepal',
  'japan',
];

export const ALL_STEPS_BADGES_BUDDYME = [
  'khaoyai',
  'inthanon',
  'myanmar',
  'laos',
  'cambodia',
  'vietnam',
]

export const OTHERS_BADGES = ['quiz', 'vdo', 'food', 'calorie'];

export const allUsersMarkerTranslateMapping = [
  ['-20px -90px'],
  ['-45px -85px', '5px -85px'],
  [
    '-60px -65px',
    '-20px -90px',
    '26px -65px',
  ],
  [
    '-55px -80px',
    '15px -80px',
    '-55px -6px',
    '16px -6px',
  ],
];

export const CHALLENGE_UNLOCKED = {
  MODAL_TITLE: 'ยินดีด้วย',
  MODAL_GREETING_TEXT: 'คุณได้พิชิต',
  MODAL_SHARE_TEXT: 'แชร:์',
  MODAL_SUBMIT_BTN: 'ดูแผนที่',
  SOCIAL_MEDIA_LIST: ['facebook', 'instagram', 'line'],
  INSTA_MESSAGE: 'No support from Instagram yet',
  SHARE_SUCCESS_MESSAGE: 'The achievement was shared successfully',
  SHARE_FAILURE_MESSAGE: 'Failed to share achievement. Something went wrong',
  UNLOCK_MESSAGE_DATE_PREFIX: 'พิชิตเหรียญนี้สำเร็จเมื่อ',
};

export const BADGE_MAPPING = [
  {
    greetingMessage: 'Pyramid of Giza',
    imgPath: 'egypt',
    goalCard: '/images/challenge/step_peru.png',
  },
  {
    greetingMessage: 'Machu Picchu',
    imgPath: 'peru',
    goalCard: '/images/challenge/step_usa.png',
  },
  {
    greetingMessage: 'Statue of Liberty',
    imgPath: 'usa',
    goalCard: '/images/challenge/step_france.png',
  },
  {
    greetingMessage: 'Eiffel Tower',
    imgPath: 'france',
    goalCard: '/images/challenge/step_nepal.png',
  },
  {
    greetingMessage: 'Mount Everest',
    imgPath: 'nepal',
    goalCard: '/images/challenge/step_japan.png',
  },
  {
    greetingMessage: 'Mount Fuji',
    imgPath: 'japan',
  },
];

export const BUDDY_ME_BADGE_MAPPING = [
  {
    greetingMessage: 'เขาใหญ่, ไทย',
    imgPath: 'khaoyai',
    goalCard: '/images/challenge/step_inthanon.png',
  },
  {
    greetingMessage: 'ดอยอินทนนท์, ไทย',
    imgPath: 'inthanon',
    goalCard: '/images/challenge/step_myanmar.png',
  },
  {
    greetingMessage: 'วังเวียง, ลาว',
    imgPath: 'myanmar',
    goalCard: '/images/challenge/step_laos.png',
  },
  {
    greetingMessage: 'พุกาม, พม่า',
    imgPath: 'laos',
    goalCard: '/images/challenge/step_cambodia.png',
  },
  {
    greetingMessage: 'นครวัด, กัมพูชา',
    imgPath: 'cambodia',
    goalCard: '/images/challenge/step_vietnam.png',
  },
  {
    greetingMessage: 'บาน่าฮิลล์, เวียดนาม',
    imgPath: 'vietnam',
  },
];

export const CHALLENGE_MAP_DETAIL = {
  defaultPaddingTop: 500,
  maxPaddingTop: 868,
  minPaddingTop: 220,
  HOME_TABS_LIST: ['สถานที่', 'อันดับ'],
};
export const LINE_REGISTER_STATUS = 'complete';

export const PERSONAL_MEMBER_PICTURE = {
  PREVIEW_SUCCESS_BTN: 'ยืนยัน',
  PREVIEW_RETAKE_BTN: 'อัปโหลดภาพใหม่',
  BEFORE_THAI: 'ก่อนการแข่งขัน',
  AFTER_THAI: 'หลังการแข่งขัน',
  BEFORE_SUB_THAI: 'อัปโหลดได ้ตั้งแต่วันที่ 1-67 ของการแข่งขัน',
  AFTER_SUB_THAI: 'ระบบเปิดให้อัปโหลดได้ ตั้งแต่วันที่ 61-67 ของการแข่งเขัน',
  BEFORE_AND_AFTER_PICTURES_TITLE: 'ภาพก่อน - หลัง',
  BODY_PICTURE_BEFORE_AND_AFTER_TITLE: 'ภาพถ่าย',
  FRONT_TITLE: 'ด้านหน้า',
  LEFTSIDE_TITLE: 'ด้านซ้าย',
  RIGHTSIDE_TITLE: 'ด้านขวา',
  GREY_OUT_BACK: 'หลัง',
  GREY_OUT_OVERDUE: 'หลัง',
};

export const PERSONAL_MEMBER_PICTURE_MAP = {
  BEFORE_CENTER: 'ภาพด้านหน้า (ก่อน)',
  BEFORE_LEFT: 'ภาพด้านซ้าย (ก่อน)',
  BEFORE_RIGHT: 'ภาพด้านขวา (ก่อน)',
  AFTER_CENTER: 'ภาพด้านหน้า (หลัง)',
  AFTER_LEFT: 'ภาพด้านซ้าย (หลัง)',
  AFTER_RIGHT: 'ภาพด้านขวา (หลัง)',
};
export const BADGE_ACTIVE_ICON =
  '/images/challenge/icons/icon-rect-badge_active.svg';

export const BMI_ICON_MAPPING = {
  low: {
    title: 'ต่ำกว่าเกณฑ์',
    icon: variables.bmiLowText,
    classname: 'bmi-low',
  },
  normal: {
    title: 'เกณฑ์ปกติ',
    icon: variables.bmiNormalText,
    classname: 'bmi-normal',
  },
  firstLevelFat: {
    title: 'อ้วนระดับ 1',
    icon: variables.bmiFat1Text,
    classname: 'bmi-fat-1',
  },
  secondLevelFat: {
    title: 'อ้วนระดับ 2',
    icon: variables.bmiFat2Text,
    classname: 'bmi-fat-2',
  },
  thirdLevelFat: {
    title: 'อ้วนระดับ 3',
    icon: variables.bmiFat3Text,
    classname: 'bmi-fat-3',
  },
};

export const WEIGHT_IN_OUT_BUTTONS = ['ชั่งก่อนการแข่งขัน', 'ชั่งจบการแข่งขัน'];

export const WEIGHT_FAT_MUSCLE_MAPPING = {
  increase: {
    classname: 'weight-fat-inc',
  },
  decrease: {
    classname: 'weight-fat-dec',
  },
  muscleInc: {
    classname: 'muscle-inc',
  },
  //TODO: Change icon of weight decrease
  muscleDec: {
    classname: 'muscle-dec',
  },
};

export const ALL_BADGES_IMAGES = [
  {
    imageUrl: '/images/challenge/badges/steps-egypt_disable-badge@5x.png',
    title: 'Pyramid of Giza',
    id: 'egypt',
    maxValueOfBadge: 100000,
    minValueOfBadge: 0,
  },
  {
    imageUrl: '/images/challenge/badges/steps-peru_disable-badge@5x.png',
    title: 'Machu Picchu',
    id: 'peru',
    maxValueOfBadge: 200000,
    minValueOfBadge: 100000,
  },
  {
    imageUrl: '/images/challenge/badges/steps-usa_disable-badge@5x.png',
    title: 'Statue of Liberty',
    id: 'usa',
    maxValueOfBadge: 300000,
    minValueOfBadge: 200000,
  },
  {
    imageUrl: '/images/challenge/badges/steps-france_disable-badge@5x.png',
    title: 'Eiffel Tower',
    id: 'france',
    maxValueOfBadge: 400000,
    minValueOfBadge: 300000,
  },
  {
    imageUrl: '/images/challenge/badges/steps-nepal_disable-badge@5x.png',
    title: 'Mount Everest',
    id: 'nepal',
    maxValueOfBadge: 500000,
    minValueOfBadge: 400000,
  },
  {
    imageUrl: '/images/challenge/badges/steps-japan_disable-badge@5x.png',
    title: 'Mount Fuji',
    id: 'japan',
    maxValueOfBadge: 600000,
    minValueOfBadge: 500000,
  },
];

export const MISSION_SECTION_BADGES = [
  {
    imageUrl: '/images/challenge/badges/food_disable-badge@5x.png',
    title: 'The Best Discipliner',
    id: 'best-discipliner',
    type: 'food',
    maxValueOfBadge: 60,
  },
  {
    imageUrl: '/images/challenge/badges/vdo_disable-badge@5x.png',
    title: 'The Best Learner',
    id: 'best-learner',
    type: 'vdo',
    maxValueOfBadge: 60,
  },
  {
    imageUrl: '/images/challenge/badges/quiz_disable-badge@5x.png',
    title: 'Top Learner',
    id: 'top-learner',
    type: 'quiz',
    maxValueOfBadge: 60,
  },
  {
    imageUrl: '/images/challenge/badges/calorie_disable-badge@5x.png',
    title: 'The Goal Keeper',
    id: 'goal-keeper',
    type: 'calorie',
    maxValueOfBadge: 60,
  },
];

export const VIDEO_CARD_TEXT = {
  duration_text: 'นาที',
  video_title: 'Title',
  video_detail_heading: 'วิดีโอทั้งหมด',
  play_icon_url: '/images/challenge/play-sm.svg',
};

export const BADGES_MILESTONE = {
  badge1: 'egypt',
  badge2: 'peru',
  badge3: 'usa',
  badge4: 'france',
  badge5: 'nepal',
  badge6: 'japan',
};

export const BUDDY_ME_BADGES_MILESTONE = {
  badge1: 'khaoyai',
  badge2: 'inthanon',
  badge3: 'myanmar',
  badge4: 'laos',
  badge5: 'cambodia',
  badge6: 'vietnam',
};

export const minContent = '0';
export const maxTeamSteps = '20,000';
export const maxFoodVdoCalorieBadges = 60;
export const maxQuizBadges = 60;

export const USER_OR_TEAM_MEDALS = ['เหรียญของฉัน', 'เหรียญของทีม'];

export const CHALLENGE_USER_DEFAULT_IMG =
  '/images/challenge/icons/team-dashboard-no-user.svg';

export const CHALLENGE_MAP_DETAIL_MAPPING = {
  egypt: {
    badge_icon: '/images/challenge/badge-egypt.svg',
    bg_image: '/images/challenge/egypt-detail.svg',
    title: 'ปิรามิดแห่งกีซ่า, อียิปต์',
    mapDetailTitle: 'ปิรามิดแห่งกีซ่า, อียิปต์',
    initialY: -100,
    enTitle: 'Pyramid of Giza',
    thTitle: 'เขาใหญ่',
    maxValueOfBadge: 100000,
    minValueOfBadge: 0,
  },
  peru: {
    badge_icon: '/images/challenge/badge-peru.svg',
    bg_image: '/images/challenge/peru-detail.svg',
    title: 'มาชูปิกชู, เปรู',
    mapDetailTitle: 'มาชูปิกชู, เปรู',
    initialY: -100,
    enTitle: 'Machu Pichu',
    thTitle: 'ดอยอินทนนท์',
    maxValueOfBadge: 200000,
    minValueOfBadge: 100000,
  },
  usa: {
    badge_icon: '/images/challenge/badge-usa.svg',
    bg_image: '/images/challenge/usa-detail.svg',
    title: 'อนุสาวรีย์เทพีเสรีภาพ, สหรัฐอเมริกา',
    mapDetailTitle: 'อนุสาวรีย์เทพีเสรีภาพ, สหรัฐอเมริกา',
    initialY: 0,
    enTitle: 'Statue of Liberty',
    thTitle: 'เมียนมาร์',
    maxValueOfBadge: 300000,
    minValueOfBadge: 200000,
  },
  france: {
    badge_icon: '/images/challenge/badge-france.svg',
    bg_image: '/images/challenge/france-detail.svg',
    title: 'หอไอเฟล, ฝรั่งเศส',
    mapDetailTitle: 'หอไอเฟล, ฝรั่งเศส',
    initialY: -75,
    enTitle: 'Eiffel Tower',
    thTitle: 'ลาว',
    maxValueOfBadge: 400000,
    minValueOfBadge: 300000,
  },
  nepal: {
    badge_icon: '/images/challenge/badge-nepal.svg',
    bg_image: '/images/challenge/nepal-detail.svg',
    title: 'ยอดเขาเอเวอเรสต์, เนปาล',
    mapDetailTitle: 'ยอดเขาเอเวอเรสต์, เนปาล',
    initialY: -100,
    enTitle: 'Mount Everest',
    thTitle: 'กัมพูชา',
    maxValueOfBadge: 500000,
    minValueOfBadge: 400000,
  },
  japan: {
    badge_icon: '/images/challenge/badge-japan.svg',
    bg_image: '/images/challenge/japan-detail.svg',
    title: 'ภูเขาไฟฟูจิ, ญี่ปุ่น',
    mapDetailTitle: 'ภูเขาไฟฟูจิ, ญี่ปุ่น',
    initialY: -100,
    enTitle: 'Mount Fuji',
    thTitle: 'เวียดนาม',
    maxValueOfBadge: 600000,
    minValueOfBadge: 500000,
  },
  egypt_disable: {
    badge_icon: '/images/challenge/badge-egypt.svg',
    bg_image: '/images/challenge/egypt-detail.svg',
    title: 'ปิรามิดแห่งกีซ่า, อียิปต์',
    mapDetailTitle: 'ปิรามิดแห่งกีซ่า, อียิปต์',
    initialY: -100,
    enTitle: 'Pyramid of Giza',
    thTitle: 'เขาใหญ่',
    maxValueOfBadge: 100000,
    minValueOfBadge: 0,
  },
  peru_disable: {
    badge_icon: '/images/challenge/badge-peru.svg',
    bg_image: '/images/challenge/peru-detail.svg',
    title: 'มาชูปิกชู, เปรู',
    mapDetailTitle: 'มาชูปิกชู, เปรู',
    initialY: -100,
    enTitle: 'Machu Pichu',
    thTitle: 'ดอยอินทนนท์',
    maxValueOfBadge: 200000,
    minValueOfBadge: 100000,
  },
  usa_disable: {
    badge_icon: '/images/challenge/badge-usa.svg',
    bg_image: '/images/challenge/usa-detail.svg',
    title: 'อนุสาวรีย์เทพีเสรีภาพ, สหรัฐอเมริกา',
    mapDetailTitle: 'อนุสาวรีย์เทพีเสรีภาพ, สหรัฐอเมริกา',
    initialY: 0,
    enTitle: 'Statue of Liberty',
    thTitle: 'เมียนมาร์',
    maxValueOfBadge: 300000,
    minValueOfBadge: 200000,
  },
  france_disable: {
    badge_icon: '/images/challenge/badge-france.svg',
    bg_image: '/images/challenge/france-detail.svg',
    title: 'หอไอเฟล, ฝรั่งเศส',
    mapDetailTitle: 'หอไอเฟล, ฝรั่งเศส',
    initialY: -75,
    enTitle: 'Eiffel Tower',
    thTitle: 'ลาว',
    maxValueOfBadge: 400000,
    minValueOfBadge: 300000,
  },
  nepal_disable: {
    badge_icon: '/images/challenge/badge-nepal.svg',
    bg_image: '/images/challenge/nepal-detail.svg',
    title: 'ยอดเขาเอเวอเรสต์, เนปาล',
    mapDetailTitle: 'ยอดเขาเอเวอเรสต์, เนปาล',
    initialY: -100,
    enTitle: 'Mount Everest',
    thTitle: 'กัมพูชา',
    maxValueOfBadge: 500000,
    minValueOfBadge: 400000,
  },
  japan_disable: {
    badge_icon: '/images/challenge/badge-japan.svg',
    bg_image: '/images/challenge/japan-detail.svg',
    title: 'ภูเขาไฟฟูจิ, ญี่ปุ่น',
    mapDetailTitle: 'ภูเขาไฟฟูจิ, ญี่ปุ่น',
    initialY: -100,
    enTitle: 'Mount Fuji',
    thTitle: 'เวียดนาม',
    maxValueOfBadge: 600000,
    minValueOfBadge: 500000,
  },
};

export const BUDDY_ME_MAP_DETAIL_MAPPING = {
  khaoyai: {
    bg_image: '/images/challenge/khaoyai-detail.svg',
    title: 'อุทยานแห่งชาติเขาใหญ่',
    mapDetailTitle: 'เขาใหญ่, ไทย',
    initialY: -100,
    enTitle: 'Khao Yai National Park',
    thTitle: 'เขาใหญ่',
    maxValueOfBadge: 100000,
    minValueOfBadge: 0,
  },
  inthanon: {
    bg_image: '/images/challenge/inthanon-detail.svg',
    title: 'อุทยานแห่งชาติ\nดอยอินทนนท์',
    mapDetailTitle: 'ดอยอินทนนท์, ไทย',
    initialY: -100,
    enTitle: 'Doi Inthanon National Park',
    thTitle: 'ดอยอินทนนท์',
    maxValueOfBadge: 200000,
    minValueOfBadge: 100000,
  },
  myanmar: {
    bg_image: '/images/challenge/myanmar-detail.svg',
    title: 'พุกาม - เมียนมา',
    mapDetailTitle: 'พุกาม, เมียนมาร์',
    initialY: 0,
    enTitle: 'Bagan - Myannmar',
    thTitle: 'เมียนมาร์',
    maxValueOfBadge: 300000,
    minValueOfBadge: 200000,
  },
  laos: {
    bg_image: '/images/challenge/laos-detail.svg',
    title: 'วังเวียง - ลาว',
    mapDetailTitle: 'วังเวียง, ลาว',
    initialY: -75,
    enTitle: 'Vang Vieng - Laos',
    thTitle: 'ลาว',
    maxValueOfBadge: 400000,
    minValueOfBadge: 300000,
  },
  cambodia: {
    bg_image: '/images/challenge/cambodia-detail.svg',
    title: 'นครวัด - กัมพูชา',
    mapDetailTitle: 'นครวัด, กัมพูชา',
    initialY: -100,
    enTitle: 'Angkor Wat - Cambodia',
    thTitle: 'กัมพูชา',
    maxValueOfBadge: 500000,
    minValueOfBadge: 400000,
  },
  vietnam: {
    bg_image: '/images/challenge/vietnam-detail.svg',
    title: 'บาน่าฮิลล์ - เวียดนาม',
    mapDetailTitle: 'BodyKey Me & Buddy Challenge',
    initialY: -100,
    enTitle: 'Bana Hills - Vietnam',
    thTitle: 'เวียดนาม',
    maxValueOfBadge: 600000,
    minValueOfBadge: 500000,
  },
  khaoyai_disable: {
    badge_icon: '/images/challenge/badge-egypt.svg',
    bg_image: '/images/challenge/egypt-detail.svg',
    title: 'อุทยานแห่งชาติเขาใหญ่',
    mapDetailTitle: 'เขาใหญ่, ไทย',
    initialY: -100,
    enTitle: 'Khao Yai National Park',
    thTitle: 'เขาใหญ่',
    maxValueOfBadge: 100000,
    minValueOfBadge: 0,
  },
  inthanon_disable: {
    badge_icon: '/images/challenge/badge-peru.svg',
    bg_image: '/images/challenge/peru-detail.svg',
    title: 'อุทยานแห่งชาติ\nดอยอินทนนท์',
    mapDetailTitle: 'ดอยอินทนนท์, ไทย',
    initialY: -100,
    enTitle: 'Doi Inthanon National Park',
    thTitle: 'ดอยอินทนนท์',
    maxValueOfBadge: 200000,
    minValueOfBadge: 100000,
  },
  myanmar_disable: {
    badge_icon: '/images/challenge/badge-usa.svg',
    bg_image: '/images/challenge/usa-detail.svg',
    title: 'พุกาม - เมียนมา',
    mapDetailTitle: 'พุกาม, เมียนมาร์',
    initialY: 0,
    enTitle: 'Bagan - Myannmar',
    thTitle: 'เมียนมาร์',
    maxValueOfBadge: 300000,
    minValueOfBadge: 200000,
  },
  laos_disable: {
    badge_icon: '/images/challenge/badge-france.svg',
    bg_image: '/images/challenge/france-detail.svg',
    title: 'วังเวียง - ลาว',
    mapDetailTitle: 'วังเวียง, ลาว',
    initialY: -75,
    enTitle: 'Vang Vieng - Laos',
    thTitle: 'ลาว',
    maxValueOfBadge: 400000,
    minValueOfBadge: 300000,
  },
  cambodia_disable: {
    badge_icon: '/images/challenge/badge-nepal.svg',
    bg_image: '/images/challenge/nepal-detail.svg',
    title: 'นครวัด - กัมพูชา',
    mapDetailTitle: 'นครวัด, กัมพูชา',
    initialY: -100,
    enTitle: 'Angkor Wat - Cambodia',
    thTitle: 'กัมพูชา',
    maxValueOfBadge: 500000,
    minValueOfBadge: 400000,
  },
  vietnam_disable: {
    badge_icon: '/images/challenge/badge-japan.svg',
    bg_image: '/images/challenge/japan-detail.svg',
    title: 'บาน่าฮิลล์ - เวียดนาม',
    mapDetailTitle: 'BodyKey Me & Buddy Challenge',
    initialY: -100,
    enTitle: 'Bana Hills - Vietnam',
    thTitle: 'เวียดนาม',
    maxValueOfBadge: 600000,
    minValueOfBadge: 500000,
  },
};

export const CHALLENGE_MILESTONE = 'milestone';
export const CHALLENGE_MILESTONE_PERU = 'peru';
export const CHALLENGE_MAP_SLIDER = {
  sliderMinHeight: 10,
  sliderMaxHeight: 700,
  sliderDefaultHeight: 400,
  sliderAutoHeight: 'auto',
};

export const REWARD_HEADER = {
  LEFT_ICON: '/images/challenge/challenge-home.svg',
  RIGHT_ICON: '/images/challenge/team-dashboard.svg',
};

export const CHALLENGE_REWARD_DETAIL = {
  HOME_TABS_LIST: ['รางวัลของฉัน', 'รางวัลของทีม'],
};
export const CAROUSEL_CARD_DATES = {
  upcoming: {
    beforeReg: 'เปิดรับสมัคร',
    duringReg: 'สมัครภายใน',
  },
  ongoing: {
    notStarted: 'เพิ่มสมาชิกภายใน',
    readyToStart: 'เตรียมตัวแข่งขัน',
    endChallenge: 'แข่งจบแล้ว',
    weightEnd: 'ชั่งจบภายใน',
    removeTeam: 'การแข่งขันสิ้นสุด',
    days: 'เหลืออีก',
    left: 'วัน',
  },
  daysLeft: (days) => `เหลืออีก ${days} วัน`,
};

export const BEFORE_TYPE = 'before';
export const AFTER_TYPE = 'after';
export const PHOTOS_LIST = ['center', 'left', 'right'];
export const PHOTOS_LIST_PAGE = [
  {
    // อัปโหลดภาพด้านหน้า
    image: (disable = '') => `/images/challenge/myupload/photo_center${disable}_icon.svg`,
    thaiLabel: 'ด้านหน้า',
    uploadPictureThai: 'อัปโหลดภาพ',
    positionImageThai: 'ด้านหน้า',
    beforeAndAfter: [
      {
        position: 'center',
        type: 'before',
        typeThai: 'ก่อน',
      },
      {
        position: 'center',
        type: 'after',
        typeThai: 'หลัง',
      },
    ],
  },
  {
    image: (disable = '') => `/images/challenge/myupload/photo_left${disable}_icon.svg`,
    thaiLabel: 'ด้านซ้าย',
    uploadPictureThai: 'อัปโหลดภาพ',
    positionImageThai: 'ด้านซ้าย',
    beforeAndAfter: [
      {
        position: 'left',
        type: 'before',
        typeThai: 'ก่อน',
      },
      {
        position: 'left',
        type: 'after',
        typeThai: 'หลัง',
      },
    ],
  },
  {
    image: (disable = '') => `/images/challenge/myupload/photo_right${disable}_icon.svg`,
    thaiLabel: 'ด้านขวา',
    uploadPictureThai: 'อัปโหลดภาพ',
    positionImageThai: 'ด้านขวา',
    beforeAndAfter: [
      {
        position: 'right',
        type: 'before',
        typeThai: 'ก่อน',
      },
      {
        position: 'right',
        type: 'after',
        typeThai: 'หลัง',
      },
    ],
  },
];


export const PICTURE_BEFORE_AFTER_SCREEN_TITLE = 'ภาพก่อน - หลัง';
export const PICTURE_BEFORE_AFTER_TITLE = 'ภาพร่างกาย';
export const PICTURE_BEFORE_TITLE = 'ก่อน';
export const PICTURE_AFTER_TITLE = 'หลัง';

export const PICTURE_TYPE_LOCALSTORAGE = 'picture_type';
export const REDIRECTION_URI = 'redirect_to_source_page';
export const IMAGE_UPLOAD_PROPS = 'image_upload_props';
export const IMAGE_UPLOAD_ID = 'image_upload_id';

export const getPhotoName = (type, position) => `${type}_${position}`;
export const CHALLENGE_BODY_CARD = {
  HOME_TABS_LIST: ['ชั่งก่อนการแข่งขัน', 'ชั่งจบการแข่งขัน'],
};

export const DASHBOARD_HEADER = {
  LEFT_ICON: '/images/challenge/challenge-home.svg',
  RIGHT_ICON: '/images/challenge/team-dashboard.svg',
};

export const AS_BODY_KEY_VERIFICATION_STEP = "AS_BODY_KEY_VERIFICATION_STEP"
export const AS_OTP_VERIFICATION_STEP = "AS_OTP_VERIFICATION_STEP"

export const stepsBadgesRange = 100000;
export const CHUNK_SIZE = 50;
export const ARROW_WHITE_ICON = '/images/challenge/arrow-white.svg';

export const contentRegEx = /<\/?[a-z][\s\S]*>/i;

export const AUTO_SYNC_MODAL_THAI = {
  TITLE: 'ระบุเบอร์มือถือที่ใช้บน Bodykey App',
  OTP_TITLE: 'ยืนยันรหัส OTP',
  OTP_FAIL_TITLE: 'หมายเลข OTP ไม่ถูกต้อง',
  SUPPORT_CUSTOMER: [
    'เมื่อยืนยันแล้ว จะไม่สามารถแก้ไขได้',
    'กรุณาติดต่อ Amway Contact Center',
    'หากต้องการแก้ไข',
  ],
  NO_FOUND_SUPPORT_CUSTOMER: [
    'ขออภัย เราไม่พบหมายเลขนี้',
    'บน Bodykey App',
    'กรุณาตรวจสอบและลองใหม่อีกครั้ง'
  ],
  IN_USE_SUPPORT_CUSTOMER: [
    'ขออภัย มีการเชื่อมต่อก้าวเดินกับ',
    'หมายเลขนี้ บน Bodykey App แล้ว',
    'กรุณาตรวจสอบและลองใหม่อีกครั้ง'
  ],
  OTP_DETAIL: (bodyKeyId) => [
    'OTP จะถูกส่งไปยัง',
    bodyKeyId,
    'เบอร์โทรศัพท์ที่ทำการลงทะเบียนเอาไว้'
  ],
  OTP_FAIL_DETAIL: (bodyKeyId) => [
    'กรุณาลองใหม่อีกครั้ง',
    'OTP จะถูกส่งไปยัง',
    bodyKeyId,
    'เบอร์โทรศัพท์ที่ทำการลงทะเบียนเอาไว้'
  ],
  CANCEL_BTN: 'ยกเลิก',
  OTP_RESEND_BTN: 'ส่งใหม่อีกครั้ง',
  OTP_WAIT_MIN: 'นาที',
  OTP_WAIT_SEC: 'วินาที',
  SUCCESS_BTN: 'ยืนยัน',
};

export const REACT_APP_FACEBOOK_APP_ID = '1528088044747497';

export const FB_SDK_PARAMS = {
  appId: process.env.REACT_APP_FACEBOOK_APP_ID || REACT_APP_FACEBOOK_APP_ID,
  cookie: true,
  xfbml: true,
  version: 'v20.0',
};

export const BODY_KEY_UNIVERSAL_LINK = 'fb160142118013544://';
export const BODY_KEY_APPSTORE =
  'itms-apps://apps.apple.com/th/app/bodykey-app/id1017207468';
export const BODY_KEY_APP_LINK = 'bodykey://bodykey';
export const BODY_KEY_PLAYSTORE =
  'https://play.google.com/store/apps/details?id=amwaysea.inbody2014.inbody';

export const bmiValues = {
  minValue: 18.5,
  maxValue: 30,
};

export const CHALLENGE_DURATION = 60;

export const CHALLENGE_BMI_TEXT = {
  low: 'น้ำหนักต่ำกว่าเกณฑ์',
  normal: 'ร่างกายสมส่วน',
  firstLevelFat: 'อ้วนระดับ 1',
  secondLevelFat: 'อ้วนระดับ 2',
  thirdLevelFat: 'อ้วนระดับ 3',
};
export const OTHER_BADGES_MAPPING = {
  food: {
    enTitle: 'The Best Discipliner',
    id: 'best-discipliner',
    type: 'food',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'วัน'
  },
  vdo: {
    enTitle: 'The Best Learner',
    id: 'best-learner',
    type: 'vdo',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'วิดีโอ',
  },
  quiz: {
    enTitle: 'Top Learner',
    id: 'top-learner',
    type: 'quiz',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'คะแนน',
  },
  calorie: {
    enTitle: 'The Goal Keeper',
    id: 'goal-keeper',
    type: 'calorie',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'วัน',
  },
  food_disable: {
    enTitle: 'The Best Discipliner',
    id: 'best-discipliner',
    type: 'food',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'วัน',
  },
  vdo_disable: {
    enTitle: 'The Best Learner',
    id: 'best-learner',
    type: 'vdo',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'วิดีโอ',
  },
  quiz_disable: {
    enTitle: 'Top Learner',
    id: 'top-learner',
    type: 'quiz',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'คะแนน',
  },
  calorie_disable: {
    enTitle: 'The Goal Keeper',
    id: 'goal-keeper',
    type: 'calorie',
    maxValueOfBadge: 60,
    minValueOfBadge: 0,
    unit: 'วัน',
  },
};

export const IMAGE_UPLOAD_INDEX_ARR = [
  'before_center',
  'before_left',
  'before_right',
  'after_center',
  'after_left',
  'after_right',
];
export const USER_DEVICE_REGEX = /\(.*?\)/g;

export const CHALLENGE_END_STATUS = ["ongoing", "weight_end"]

export const IMAGE_UPLOAD_LOADING_STATE = {
  before_left: false,
  before_center: false,
  before_right: false,
  after_left: false,
  after_center: false,
  after_right: false,
}

export const BODY_KEY_LOADER_TITLE = 'กำลังเชื่อมต่อ'

export const BODY_KEY_VERIFY_ERROR = {
  NO_FOUND_SUPPORT_CUSTOMER: 'No data',
  IN_USE_SUPPORT_CUSTOMER: 'bodyKeyId is already associated with other account!',
  ABO_ID_NOT_FOUND: 'ABO ID not found, please try again'
}

export const AUTO_SYNC_STEPS_TRACK = (achieved, target) => [
  {
    title: 'เผาผลาญ',
    unit: 'แคลอรี',
    value: achieved?.stepKcal || 0,
    total: target?.targetCalories || 0,
    percent: achieved?.perKcal || 0
  },
  {
    title: 'ระยะทาง',
    unit: 'กม.',
    value: round(achieved?.stepDistance, 2) || 0,
    total: round(target?.targetDistance, 2) || 0,
    percent: achieved?.perDistance || 0
  },
  {
    title: 'เวลาออกกำลังกาย',
    unit: 'นาที',
    value: achieved?.stepTime || 0,
    total: target?.exerciseTime || 0,
    percent: achieved?.perTime || 0
  },
]

export const AUTO_SYNC_STEPS_THAI = {
  SCREEN_TITLE: 'ก้าวเดินของฉัน',
  GOAL_REDIRECTION: 'แก้ไขเป้าหมายการออกกำลังกาย',
  STEP_COUNT_UNIT: 'ก้าว',
}

export const AUTO_SYNC_OTP_LENTH_LIMIT = 4;
export const dividerWidth = '20px';

export const VDO_SORTED_IDS = [
  "03209c3b-0cd6-417a-a9f2-ea4acda6778d",
  "a76bb0fe-bd60-4c71-be9a-d784f0b7c5d6",
  "e97e18fb-3b77-4d06-af06-8496d5fa4df6",
  "f30901ab-25f6-41ad-b3d8-632b969e1490",
  "39b62e39-1483-4cce-885d-9de4bd0b67fc",
  "f4ff62a7-2952-408a-817e-938200a55960",
  "5079846b-7646-4cc3-bdaa-c7bf044c4601",
  "1cdcd626-77cc-4655-86a7-0a92a7c7ae48",
  "5fda3f21-3683-439f-a6d0-ba52b196d281",
  "e7f9c476-5916-4765-bd38-3253d2ee2bb4",
  "82905ad8-6933-4775-9cc1-5d7c9e4ff0e4",
  "eef0e8bb-6599-49d8-8fa1-b52a94f2a2de"
]

export const SMALL_SCREEN_WIDTH = 376;
export const MAX_WIDTH_FOR_DIVIDER = 140;

export const ChallengeVideoStatus = {
  yet_to_watch: 'yet_to_watch',
  watch_not_fully: 'watch_not_fully',
  watch_successful: 'watch_successful',
};

export const ALIGN_CLASS = {
  1: 'align-card-1',
  2: 'align-card-2',
  3: 'align-card-3',
  4: 'align-card-4'
}
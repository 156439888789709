import React, { useEffect, useState } from 'react';
import { HnwTabs } from '..';
import { CustomCalendar } from '../../../../../common';
import { CALENDAR_DATA_LABELS } from '../../../../../common/commonConstant';
import './MonthGraphContainer.scss';


export default function MonthGraphContainer({ showLegends = true, showTabs = true, typeList, onTabChange, ...props }) {
  const [activeTab, setActiveTab] = useState(CALENDAR_DATA_LABELS.food);

  useEffect(() => { showTabs && onTabChange(activeTab) }, [activeTab]);

  return (
    <div className="month-graph-container">
      <div className="month-graph-container-content">
        <CustomCalendar {...props} {...{ activeStartDate: props.min }} />
        {showLegends && <div className="legend-wrapper">
          {typeList.map((item) => <label className={`legend legend__${item}`}>{item.legendLabel ?? CALENDAR_DATA_LABELS[item].legendLabel}</label>)}
        </div>}
        {showTabs && <div className="month-graph-tabs">
          <HnwTabs onClick={setActiveTab} typeList={typeList.map(itemName => CALENDAR_DATA_LABELS[itemName])} activeTab={activeTab} />
        </div>}
      </div>
    </div>
  );
}

import queryString from 'query-string';
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchChallengeTeamDetail, fetchUserDetailsData } from "../../../actions";
import { ChallengeButton, ChallengeHeader, ChallengeProgressBar, ChallengeShare, commonConstant } from "../../../common";
import { ALL_STEPS_BADGES_BUDDYME, BUDDY_ME_MAP_DETAIL_MAPPING, CHALLENGE, CHALLENGE_MAP_DETAIL_MAPPING, CHALLENGE_STEPS_FOOD_KEY_MAPPING, CHALLENGE_UNLOCKED, OTHERS_BADGES, OTHER_BADGES_MAPPING } from "../../../common/challengeConstants";
import { checkAfterWeightPeriod, checkNullDate, formatNumberWithCommas, formatStringWithDecimal, getDataFromSession, getMissionBadgesRange, getOthersBadgesRange, getParamsString, getStepsBadgesRange, removeDisableForRedirection, updateMissionBadgesList, updateStepsBadgeList } from "../../../utill.func";
import './ChallengeBadgeDetail.scss';
import { BadgeDetailLoader, BadgeUserDetailLoader, CalorieBadgeDetail, FoodBadgeDetail, QuizBadgeDetail, StepsBadgeDetails, VdoBadgeDetail } from "./index";
import ChallengeCalendar from './challengeCalendar/ChallengeCalendar';
import { addMonths, startOfDay } from 'date-fns';

const ChallengeBadgeDetail = () => {

  const dispatch = useDispatch();
  const customer = getDataFromSession('customer');
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const partyId = params['partyId'] || '';
  const eventId = params['eventId'];
  const isTeamBadge = params['isTeam'] || '';
  const badgeType = params['type'] || '';
  const { teamData, userDetail } = useSelector((state) => state.challengeTeamDashboard);
  const challengeId = params['challengeId'] || teamData?.eventId;
  const challengeStatus = teamData?.teamDashboardStatus?.status;
  const afterWeightOutPeriod = checkAfterWeightPeriod(challengeStatus);

  const [loading, setLoading] = useState(false);
  const [badgeList, setBadgeList] = useState([]);
  const [currentBadge, setCurrentBadge] = useState();
  const [stepsBadgeCount, setStepsBadgeCount] = useState(0);
  const [selectedIndexOfList, setSelectedIndexOfList] = useState(0);
  const [calendarView, setCalendarView] = useState('month');
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [stepsValue, setStepsValue] = useState(0);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [missionBadgesList, setMissionBadgesList] = useState([]);
  const [isFlipped, setIsFlipped] = useState(false);
  const [otherBadgesUsersData, setOtherBadgesUsersData] = useState([]);
  const [currentUserOthersBadgeData, setCurrentUserOthersBadgeData] = useState();
  const [dailyLoggedData, setDailyLoggedData] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(params['badge'] || '');

  const allUsersData = teamData?.stepsBadgeData?.[selectedBadge]?.allUsersData;
  const currentUserStepsBadgeData = allUsersData?.find((data) => data?.partyId == partyId)
  const [currentBadgeData, setCurrentBadgeData] = useState();
  const [badgeReceived, setBadgeReceived] = useState(false);
  const [backRedirection, setBackRedirection] = useState('');
  const [detailScroll, setDetailScroll] = useState(false);
  const [missionBadgesData, setMissionBadgesData] = useState([]);
  const detailRef = useRef(null);
  const badgeTypeClass = badgeType == CHALLENGE.badges.others ? 'others-badge' : '';

  const minDate = startOfDay(new Date(teamData?.approvalTeamdate));
  const maxDate = addMonths(new Date(teamData?.approvalTeamdate), 3);

  const CHALLENGE_TYPE = teamData.challengeType === 'Team' ? CHALLENGE.type.TEAM : CHALLENGE.type.BUDDY_ME;
  const BADGE_DETAILS_MAPPING = {
    BUDDY_ME: {
      TEXT_DETAIL_MAPPING: BUDDY_ME_MAP_DETAIL_MAPPING,
    },
    TEAM: {
      TEXT_DETAIL_MAPPING: CHALLENGE_MAP_DETAIL_MAPPING,
    }
  };

  useEffect(() => {
    const didMount = () => {
      if (!teamData?.challengePartyIds && !teamData?.teamName) {
        getChallengeTeamDashboardData();
      }
    }
    didMount();
  }, []);

  const badgeDetailScrolling = () => {
    if (detailRef.current) {
      const detailHeight = detailRef.current.scrollHeight;
      const windowHeight = window.innerHeight;
      setDetailScroll(detailHeight > windowHeight);
    }
  };

  useEffect(() => {
    badgeDetailScrolling();
    window.addEventListener('resize', badgeDetailScrolling);
    return () => {
      window.removeEventListener('resize', badgeDetailScrolling);
    };
  }, []);

  const getChallengeTeamDashboardData = async () => {
    try {
      setLoading(true);
      await dispatch(fetchChallengeTeamDetail({ amwayNumber: customer?.amwayNumber, partyId: customer?.partyId, challengeId }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  useEffect(() => {
    const didMount = () => {
      if (teamData?.challengePartyIds && userDetail.length == 0) {
        getChallengeUserProfileData();
      }
    }
    didMount();
  }, [teamData?.challengePartyIds]);

  const getChallengeUserProfileData = async () => {
    if (teamData?.challengePartyIds) {
      await dispatch(fetchUserDetailsData(teamData?.challengePartyIds));
    }
  }

  const _getUserData = () => {
    const stepsFoodKeys = Object.keys(CHALLENGE_STEPS_FOOD_KEY_MAPPING);
    const missionData = stepsFoodKeys.reduce((acc, key) => {
      acc[key] = teamData?.missionUserData?.[key]?.userData?.find(user => user?.partyId === partyId);
      return acc;
    }, {});
    setMissionBadgesData(missionData);
    const stepsBadgeCount = missionData?.steps?.data?.badges_count;
    const otherBadgesData = stepsFoodKeys.reduce((acc, key) => {
      acc[key] = teamData?.otherBadgeData?.[key]?.allUsersData?.find(user => user?.partyId === partyId);
      return acc;
    }, {});
    setOtherBadgesUsersData(teamData?.otherBadgeData?.[selectedBadge]?.allUsersData);
    setBadgeReceived(otherBadgesData?.[selectedBadge]?.badgeReceived);
    setStepsBadgeCount(stepsBadgeCount);
    setStepsValue(missionData?.steps?.data?.value);
    missionData && setDailyLoggedData(missionData?.[selectedBadge]?.data?.loggedData);
    setBadgeList(updateStepsBadgeList(ALL_STEPS_BADGES_BUDDYME, stepsBadgeCount));
  };

  useEffect(() => {
    _getUserData();
  }, [teamData, partyId, selectedBadge]);

  useEffect(() => {
    setCurrentUserOthersBadgeData(otherBadgesUsersData?.find((user) => user?.partyId == partyId));
    setMissionBadgesList(updateMissionBadgesList(OTHERS_BADGES, badgeReceived, selectedBadge));
  }, [otherBadgesUsersData])

  const findBadgeType = (badgeList) => {
    return badgeList.find((badge) => removeDisableForRedirection(badge) == selectedBadge);
  }

  useEffect(() => {
    const badge = badgeType == CHALLENGE.badges.steps ? findBadgeType(badgeList) : findBadgeType(missionBadgesList);
    setCurrentBadge(badge);
    badgeType == CHALLENGE.badges.steps && badgeList ?
      setCurrentBadgeData(BADGE_DETAILS_MAPPING[CHALLENGE_TYPE].TEXT_DETAIL_MAPPING[currentBadge]) :
      setCurrentBadgeData(OTHER_BADGES_MAPPING[currentBadge])
    badgeType == CHALLENGE.badges.steps && setSelectedIndexOfList(badgeList.findIndex((badge) => removeDisableForRedirection(badge) == selectedBadge))
  }, [badgeList, missionBadgesList]);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollHeight > window.innerHeight) {
        const windowTop = window.scrollY;
        setIsHeaderSticky(windowTop > 10);
      } else {
        setIsHeaderSticky(false);
      }
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getDataFromSession('badgeDetail') == 'badge-screen' ?
      setBackRedirection(commonConstant.pathChallengeBadgeScreen) :
      setBackRedirection(commonConstant.pathChallengeMyDashboard);
  }, [getDataFromSession('badgeDetail')])

  const checkDisabled = currentBadge?.includes('_disable');

  const handleBadgeClick = () => {
    setIsFlipped(prevIsFlipped => !prevIsFlipped);
  };

  const getBadgesValue = (key) => {
    const key_map = (key == 'quiz') ? 'score' : 'value';
    return missionBadgesData[key]?.data?.[key_map];
  }

  // TODO: Handle redirection from session
  const handleMyDashboardRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      partyId: partyId,
    }
    return history.push(`${commonConstant.pathChallengeMyDashboard}?${getParamsString(params)}`);
  }

  const handleBadgeScreenRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      partyId: partyId,
      isTeam: isTeamBadge ? true : false
    }
    return history.push(`${backRedirection}?${getParamsString(params)}`);
  }

  const handleHomeRedirection = () => {
    return history.push(commonConstant.pathHealthnWellness);
  }

  const handleRedirection = () => {
    const currentUser = teamData?.users?.find(
      (user) => user?.partyId == customer?.partyId,
    );
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      userId: `${currentUser?.challengeUserId}`,
    };
    (selectedBadge === CHALLENGE.badges.vdo || selectedBadge === CHALLENGE.badges.quiz) && history.push(
      `${commonConstant.pathChallengeQuiz}?${getParamsString(params)}`,
    );
    selectedBadge === CHALLENGE.badges.food && history.push(commonConstant.pathHnwGoalsHome);
    selectedBadge === CHALLENGE.badges.calorie && history.push(commonConstant.pathFoodLogHome)
  };

  // TODO: Handle back navigation On conditions
  const renderHeader = () => {
    return (
      <div className="badges-header-wrapper">
        <ChallengeHeader leftIcon={'/images/challenge/arrow-black.svg'} leftIconRedirect={() => handleBadgeScreenRedirection()}
          rightIcon={'/images/challenge/challenge-home.svg'} rightIconRedirect={() => handleHomeRedirection()}
          isHeaderSticky={isHeaderSticky} />
      </div>
    )
  }

  const renderImageContainer = () => {
    const renderBackImage = badgeType == CHALLENGE.badges.steps ? '/images/challenge/badges/steps-badge-back.png' : '/images/challenge/badges/other-badges-back.png';
    return (
      <div className="card-wrapper" onClick={handleBadgeClick}>
        <div className="card-front">
          <img src={badgeType == CHALLENGE.badges.steps ?
            `/images/challenge/badges/steps-${currentBadge}-badge@5x.png` :
            `/images/challenge/badges/${currentBadge}-badge@5x.png`} alt="" className="badge-frontImg-lg" />
        </div>
        <div className="card-back">
          <div className="back-img-container">
            <img src={renderBackImage} alt="" className="badge-frontImg-lg" />
            <div className="back-img-content">
              <div className="back-img-title">{CHALLENGE.badges.receivedWhen}</div>
              <div className="back-img-value">
                <p>
                  {badgeType == CHALLENGE.badges.steps ?
                    checkNullDate(currentUserStepsBadgeData?.receivedAt) :
                    checkNullDate(currentUserOthersBadgeData?.receivedAt)
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderMissionDetailComponent = (key) => {
    switch (key) {
      case 'quiz':
        return <QuizBadgeDetail disabled={checkDisabled} />;
      case 'vdo':
        return <VdoBadgeDetail disabled={checkDisabled} />;
      case 'food':
        return <FoodBadgeDetail disabled={checkDisabled} />;
      case 'calorie':
        return <CalorieBadgeDetail disabled={checkDisabled} />;
      default:
        return null;
    }
  };

  const renderActiveStepsBadges = () => {
    return (
      <div className="badge-details-description">
        <pre className="badge-title">{currentBadgeData?.title}</pre>
        <StepsBadgeDetails disabled={checkDisabled} currentBadge={currentBadgeData} />
        {!isTeamBadge && !checkDisabled &&
          <p>
            {CHALLENGE.badges.receivedOn} {checkNullDate(currentUserStepsBadgeData?.receivedAt)}
          </p>
        }
      </div>
    )
  }

  const renderBadgeValues = () => {
    const values = getBadgesValue(selectedBadge);
    return (
      <div className="badge-values">
        <span className={`badge-current-values ${values >= CHALLENGE.myDashboard.maxBadgeActiveValue ? 'green-font' : ''}`}>{formatNumberWithCommas(values)}</span>
        <span className="badge-total-values">{CHALLENGE.myDashboard.maxRewardBadges}</span>
        <span className="badge-unit">{CHALLENGE.myDashboard.badgePoints}</span>
      </div>
    )
  }

  const renderActiveMissionBadgesDetail = () => {
    return (
      <div className="badge-details-description">
        <pre className="badge-title">{currentBadgeData?.enTitle}</pre>
        {renderMissionDetailComponent(currentBadge && removeDisableForRedirection(currentBadge))}
        {!isTeamBadge && !checkDisabled && <p>{CHALLENGE.badges.receivedOn} {checkNullDate(currentUserOthersBadgeData?.receivedAt)}</p>}
        {(selectedBadge == CHALLENGE.badges.vdo || selectedBadge == CHALLENGE.badges.quiz) && renderBadgeValues()}
      </div>
    )
  }

  const renderBadgeDetails = () => {
    return badgeType == CHALLENGE.badges.steps ? renderActiveStepsBadges() : renderActiveMissionBadgesDetail()
  }

  const renderChallengeShare = () => {
    return <ChallengeShare imgPath={`${process.env.REACT_APP_REDIRECT_URL}${`/images/challenge/badges/${badgeType == CHALLENGE.badges.steps ? 'steps-' : ''}${currentBadge}-badge@5x.png`}`}
      message={`${CHALLENGE_UNLOCKED.UNLOCK_MESSAGE_DATE_PREFIX} ${checkNullDate(badgeType == CHALLENGE.badges.steps ? currentUserStepsBadgeData?.receivedAt : currentUserOthersBadgeData?.receivedAt)}`} />
  }

  const getUserProfileUrl = (partyId) => {
    const userProfileInfo = userDetail.find(data => data?.partyId === partyId);
    return userProfileInfo?.pictureUrl;
  }

  const renderUsersData = (index, item) => {
    const badgeReceived = !item?.badgeReceived ? 'badge-received' : ''
    return (
      <div className='challenge-badge-detail-list-users' key={index}>
        <div className={`challenge-badge-detail-list-users-profile ${badgeReceived}`}>
          <img src={getUserProfileUrl(item?.partyId)} className='profile-circle-img' />
          <div className="dashboard-slider-leader-img">
            {item?.isLeader &&
              <>
                <img className='profile-img' src={'/images/challenge/icons/team-dashboard-circle.svg'} />
                <div className="btn-icon-crown">
                  <img src="/images/challenge/icons/icon-challenge-crown.svg" />
                </div>
              </>}
            {!item?.isLeader && badgeReceived && !!index && <img className='profile-img' src={'/images/challenge/white-stroke.svg'} />}
          </div></div>
        <div className={`challenge-badge-detail-list-users-name ${badgeReceived}`}>{item?.userName}</div>
        {item?.badgeReceived ? <div className='challenge-badge-detail-list-users-date'>{checkNullDate(item?.receivedAt)}</div> :
          badgeType == CHALLENGE.badges.steps ?
            <div className="challenge-badge-detail-list-users-date-progress">
              <ChallengeProgressBar percentage={getStepsBadgesRange(item?.total_steps, currentBadgeData?.minValueOfBadge)} />
              <div className="user-badge-progress-percent">{`${Math.floor(getStepsBadgesRange(item?.total_steps, currentBadgeData?.minValueOfBadge))}%`}</div>
            </div> :
            <div className="challenge-badge-detail-list-users-date-progress">
              <ChallengeProgressBar percentage={getMissionBadgesRange(item?.totalValue, currentBadgeData?.type)} />
              <div className="user-badge-progress-percent">{`${Math.floor(getMissionBadgesRange(item?.totalValue, currentBadgeData?.type))}%`}</div>
            </div>}
      </div>
    )
  }

  const handleUsersTab = () => {
    return (
      <div className={`challenge-badge-detail ${detailScroll ? 'badge-scroll' : ''}`}>
        <div className="challenge-badge-detail-heading">{CHALLENGE.badges.usersHeading}</div>
        {badgeType == CHALLENGE.badges.steps ? allUsersData?.map((item, index) => (
          renderUsersData(index, item)
        )) :
          otherBadgesUsersData?.map((item, index) => (
            renderUsersData(index, item)
          ))}
      </div>
    )

  }

  // --- Progres Bar removed below badge-----
  const renderStepsChallengeProgressBar = () => {
    return (
      <div className="badge-details-challenge-bar">
        <ChallengeProgressBar percentage={getStepsBadgesRange(stepsValue, currentBadgeData?.minValueOfBadge)} />
        <div className="steps-progress-container">
          <div className="steps-progress-values">
            <span className="bold-steps-font">{formatNumberWithCommas(stepsValue)}</span>
            <span className="normal-steps-font">{`/${formatNumberWithCommas(currentBadgeData?.maxValueOfBadge)}`}</span>
            <span className="steps-font">{CHALLENGE.badges.stepsUnit}</span>
          </div>
          <div className="steps-progress-percent bold-steps-font">
            {`${Math.floor(getStepsBadgesRange(stepsValue, currentBadgeData?.minValueOfBadge ? currentBadgeData?.minValueOfBadge : 0))}%`}
          </div>
        </div>
      </div>
    )
  }

  const renderOthersChallengeProgressBar = () => {
    return (
      <div className="badge-details-challenge-bar">
        <ChallengeProgressBar percentage={getOthersBadgesRange(currentUserOthersBadgeData?.totalValue, currentBadgeData?.maxValueOfBadge)} />
        <div className="steps-progress-container">
          <div className="steps-progress-values">
            <span className="bold-steps-font">{formatNumberWithCommas(currentUserOthersBadgeData?.totalValue)}</span>
            {selectedBadge != CHALLENGE.badges.quiz ?
              <span className="normal-steps-font">{`/${formatNumberWithCommas(currentBadgeData?.maxValueOfBadge)}`}</span> :
              ''
            }
            <span className="steps-font">{currentBadgeData?.unit}</span>
          </div>
          <div className="steps-progress-percent bold-steps-font">
            {`${Math.floor(getOthersBadgesRange(currentUserOthersBadgeData?.totalValue, currentBadgeData?.maxValueOfBadge))}%`}
          </div>
        </div>
      </div>
    )
  }

  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    if (view === 'decade') {
      // Prevent drill-up beyond year view
      setCalendarView('year');
    } else {
      setCalendarView(view);
      setActiveStartDate(activeStartDate < minDate ? minDate : activeStartDate);
    }
  };

  const handleViewChange = ({ view }) => {
    if (view !== 'decade') {
      setCalendarView(view);
    }
  };

  // --- Progres Bar removed below badge-----
  // TODO: WILL BE REMOVED IN NEXT BUILD AFTER TESTING
  console.log("window.location: ", window.location.href)
  return (
    <>
      <div className={`badge-details-container text-center ${detailScroll ? 'badge-scroll' : 'no-scroll'} ${badgeTypeClass}`} ref={detailRef}>
        {renderHeader()}
        <div className={`badge-details-body${isHeaderSticky ? ' dashboardSticky' : ''}`}>
          {loading ? <BadgeDetailLoader /> :
            <>
              <div className={`badge-details-inner badge-flipper ${isFlipped && !checkDisabled ? 'flip' : ''}`} >
                {renderImageContainer()}
                {renderBadgeDetails()}
              </div>
              {(selectedBadge == CHALLENGE.badges.food || selectedBadge == CHALLENGE.badges.calorie) &&
                <div className="badge-details-calendar-container">
                  <ChallengeCalendar
                    calendarType="iso8601"
                    min={minDate}
                    max={maxDate}
                    view={calendarView}
                    activeStartDate={activeStartDate}
                    onActiveStartDateChange={handleActiveStartDateChange}
                    onViewChange={handleViewChange}
                    data={dailyLoggedData} />
                  {renderBadgeValues()}
                </div>}
              <div className="social-media-section">
                {checkDisabled && !isTeamBadge &&
                  badgeType == CHALLENGE.badges.steps ?
                  renderStepsChallengeProgressBar() :
                  !checkDisabled && renderChallengeShare()}
              </div>
            </>}
          {loading ? <BadgeUserDetailLoader /> :
            <>
              {isTeamBadge &&
                <div className="badge-team-data">
                  {handleUsersTab()}
                </div>
              }
            </>}
        </div>
      </div>
      {(badgeType === CHALLENGE.badges.others || badgeType === "undefined") &&
        <div className='quiz-and-video-button'>
          <ChallengeButton btnTitle={CHALLENGE.quizAndVideoButtonText[selectedBadge]} successBtn={!afterWeightOutPeriod} secBtn={afterWeightOutPeriod} onBtnClick={afterWeightOutPeriod ? undefined : () => handleRedirection()} />
        </div>}
    </>
  )
}

export default ChallengeBadgeDetail;
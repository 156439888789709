import React from 'react';
import { AFTER_TYPE, getPhotoName } from '../../challengeConstants';

export default function ImageCard({
  type,
  photo,
  photos,
  handleImageUpload,
  allowImageUpload,
  isChallengePeriod,
  isUserValid,
  loadGreenOrGreyIdleImages,
  isAfterImageUpload
}) {
  return (
    <span
      key={getPhotoName(type, photo)}
      className="before-after-image-single before-after-image-uploaded pointer"
      onClick={() => {
        if (allowImageUpload(isUserValid, type === AFTER_TYPE ? isAfterImageUpload && isChallengePeriod : isChallengePeriod))
          handleImageUpload(getPhotoName(type, photo))
      }
      }
    >
      <img
        src={
          photos[getPhotoName(type, photo)]
            ? photos[getPhotoName(type, photo)]
            : `/images/challenge/myupload/photo_${loadGreenOrGreyIdleImages(
              type === AFTER_TYPE ? isAfterImageUpload && isChallengePeriod : isChallengePeriod,
              photo,
            )}.svg`
        }
        className="before-after-img-uploaded"
        alt={getPhotoName(type, photo)}
      />
    </span>
  );
}

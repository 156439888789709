import React from "react";
import { BadgeRectCard } from "../../../../common";
import { CHALLENGE } from "../../../../common/challengeConstants";
import { filterActiveInactiveImages } from "../../../../utill.func";
import './ChallengeBuddyMyBadgesCards.scss';

const ChallengeBuddyMyBadgesCards = ({ redirectToBadgeScreen, redirectToBadgeDetail, badgesData, otherBadgesData }) => {

    const getBadgesValue = (key) => {
        const key_map = (key == 'quiz') ? 'score' : 'value';
        return badgesData[key]?.data?.[key_map];
    }

    const getBadgesReceived = (key) => {
        return otherBadgesData[key]?.badgeReceived;
    }

    const renderBadgeCardBody = (title, badgeImage, values, maxBadgeValue, unit, badgeType, maxBadgeActiveValue) => {
        return (
            <div className="challenge-card-body w-50" onClick={(event) => redirectToBadgeDetail(badgeType, 'others')}>
                <BadgeRectCard cards
                    title={title}
                    badgeImage={badgeImage}
                    values={values}
                    maxBadgeValue={maxBadgeValue}
                    unit={unit}
                    redirectToBadgeDetail={redirectToBadgeDetail}
                    maxBadgeActiveValue={maxBadgeActiveValue}
                    btnBadge
                />
            </div>
        )
    }

    // TODO: WILL BE REMOVED IN NEXT BUILD AFTER TESTING
    console.log("window.location: ", window.location.href)
    return (
        <div className="my-badges-cards-container">
            <div className="align-half-cards-container">
                <h2 className="my-badges-card-title">{CHALLENGE.badges.smartLearnerHeading.toUpperCase()}</h2>
                <div className="align-half-cards">
                    {renderBadgeCardBody(
                        CHALLENGE.myDashboard.bestLearner,
                        filterActiveInactiveImages(getBadgesReceived('vdo'), 'vdo'),
                        getBadgesValue('vdo'),
                        CHALLENGE.myDashboard.maxRewardBadges,
                        CHALLENGE.myDashboard.badgePoints,
                        'vdo',
                        CHALLENGE.myDashboard.maxBadgeActiveValue
                    )}
                    {renderBadgeCardBody(
                        CHALLENGE.myDashboard.topLearner,
                        filterActiveInactiveImages(getBadgesReceived('quiz'), 'quiz'),
                        getBadgesValue('quiz'),
                        CHALLENGE.myDashboard.maxRewardBadges,
                        CHALLENGE.myDashboard.badgePoints,
                        'quiz',
                        CHALLENGE.myDashboard.maxBadgeActiveValue
                    )}
                </div>
            </div>
            <div className="align-half-cards-container">
                <h2 className="my-badges-card-title">{CHALLENGE.badges.dietPlanner.toUpperCase()}</h2>
                <div className="align-half-cards">
                    {renderBadgeCardBody(
                        CHALLENGE.myDashboard.bestDiscipliner,
                        filterActiveInactiveImages(getBadgesReceived('food'), 'food'),
                        getBadgesValue('food'),
                        CHALLENGE.myDashboard.maxRewardBadges,
                        CHALLENGE.myDashboard.badgePoints,
                        'food',
                        CHALLENGE.myDashboard.maxBadgeActiveValue
                    )}
                    {renderBadgeCardBody(
                        CHALLENGE.myDashboard.goalKeeper,
                        filterActiveInactiveImages(getBadgesReceived('calorie'), 'calorie'),
                        getBadgesValue('calorie'),
                        CHALLENGE.myDashboard.maxRewardBadges,
                        CHALLENGE.myDashboard.badgePoints,
                        'calorie',
                        CHALLENGE.myDashboard.maxBadgeActiveValue
                    )}
                </div>
            </div>
        </div>
    )

}

export default ChallengeBuddyMyBadgesCards;
import { FETCH_CHALLENGE_TEAM_SUCCESS, FETCH_CHALLENGE_TEAM_FAILURE, FETCH_USER_DETAIL_SUCCESS, FETCH_USER_DETAIL_FAILURE, FETCH_CHALLENGE_TEAM_DETAIL, SYNC_USER_BADGE_COUNT_FAILURE, SYNC_USER_BADGE_COUNT_SUCCESS } from './types';
import { fetchTeamDashboardDetail, fetchUserDetail, fetchViewedBadgeDetail, updateViewBadgeCountData } from '../services/challenge/teamDashboard.service';

export const fetchChallengeTeamDetail = (params) => (dispatch) => {
  dispatch({ type: FETCH_CHALLENGE_TEAM_DETAIL });

  return fetchTeamDashboardDetail(params)
    .then((result) => {
      dispatch({
        type: FETCH_CHALLENGE_TEAM_SUCCESS,
        payload: result,
        error: false
      });
      return result;
    })
    .catch(function (error) {
      dispatch({ type: FETCH_CHALLENGE_TEAM_FAILURE, payload: error.message });
    });
};

export const fetchUserDetailsData = (params) => (dispatch) =>
  fetchUserDetail(params).then((result) => {
    dispatch({
      type: FETCH_USER_DETAIL_SUCCESS,
      payload: result,
      hasError: false
    });
  }).catch(function (error) {
    dispatch({ type: FETCH_USER_DETAIL_FAILURE, payload: error.message });
  });

export const fetchViewedBadgeCount = (eventId) => dispatch =>
  fetchViewedBadgeDetail(eventId).then((result) => {
    dispatch({
      type: SYNC_USER_BADGE_COUNT_SUCCESS,
      payload: result,
      hasError: false
    });
  }).catch(function (error) {
    dispatch({ type: SYNC_USER_BADGE_COUNT_FAILURE, payload: error.message });
  });

export const updateViewBadgeCount = (viewedBadgeNumber, eventId) => dispatch =>
  updateViewBadgeCountData(viewedBadgeNumber, eventId).then((result) => {
    dispatch({
      type: SYNC_USER_BADGE_COUNT_SUCCESS,
      payload: result,
      hasError: false
    });
  }).catch(function (error) {
    dispatch({ type: SYNC_USER_BADGE_COUNT_FAILURE, payload: error.message });
  });
import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";
import { getPhoneFormat } from "../../../../services/promotion/utils.service";
import './ChallengeMyProfile.scss';

const ChallengeMyProfile = ({ userData, challengeUserData, eventType }) => {

    const renderCardDescription = () => {
        return (
            <div className="card-ongoing-description">
                <div className="card-ongoing-name">
                    {userData?.userName}
                </div>
                <div className="card-ongoing-number">
                    <span>{CHALLENGE.myDashboard.skuText}</span>
                    <span>{userData?.aboId}</span>
                </div>
            </div>
        )
    }

    const renderCardContactDetails = () => {
        return (
            <div className="card-ongoing-contact-details">
                <div className="card-ongoing-contactleft">
                    <img src="/images/challenge/icon-challenge-phone.svg" alt="" />
                    <div className="card-ongoing-contact-name">
                        {userData?.phoneNumber && getPhoneFormat(userData?.phoneNumber)}</div>
                </div>
                <div className="card-ongoing-contactright">
                    <img src="/images/challenge/icon-challenge-watch.svg" alt="" />
                    <div className="card-ongoing-contact-name">{userData?.userDevice}</div>
                </div>
            </div>
        )
    }

    const renderImageContainer = () => {
        const userProfileInfo = challengeUserData.find(data => data?.partyId === userData?.partyId);
        const userImg = userProfileInfo?.pictureUrl;

        return (
            <>
                <img src="/images/challenge/profile-img-white-bg.svg" className="profile--top-img" alt="" />
                <img src={userImg || '/images/challenge/default_challenge_profile.svg'} className="profile-img" alt="" />
                {(eventType && userData?.isLeader) &&
                    <div className="btn-icon-crown">
                        <img src="/images/challenge/icons/icon-challenge-crown.svg" className="crown-img" alt="" />
                    </div>}
            </>
        )
    }

    return (
        <div className="my-dashboard-profile-wrapper">
            <div className="profile-card-container-wrapper">
                <div className={`profile-card-container ${(eventType && userData?.isLeader) ? 'profile-card-container-active' : ''}`}>
                    {renderImageContainer()}
                    <div className="profile-description-container">
                        {renderCardDescription()}
                        {renderCardContactDetails()}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChallengeMyProfile;
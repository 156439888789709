import React, { useEffect, useMemo, useState } from 'react';
import './hnwHome.scss';
import Header from '../../../common/header/header';
import {
  commonConstant,
  HnwHeightWeightInput,
  HnwInputModal,
  NoDataCard,
} from '../../../common';
import Assessment from './assessment/assessment';
import {
  calculateLastSevenMonthsRange,
  capitalizeFirstLetter,
  getDatetimeStamp,
  isValidObject,
  timeMoment,
} from '../../../utill.func';
import {
  BGIMG,
  FOOD_PARAMETERS_MAPPING,
  H_W_VALIDATION,
  HOME_CARDS_ROUTES,
  GENDER,
  homePageSlideUpGap,
  LABEL_CALORIE,
  LABEL_EN_MANUAL_LOG_TAB,
  LABEL_ML,
  policyTitle,
  routePathKey,
  WEIGHT_GOAL_MAPPING,
  WEIGHT_GRAPH,
  WEIGHT_GRAPH_TYPE,
  API_DATE_FORMAT,
} from '../../../common/commonConstant';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPolicies,
  fetchPolicyStatus,
  updateProfileImage,
} from '../../../thunk/hnwHome.thunk';
import { fetchExerciseCalendarData } from '../../../actions/hnwCalendarActions';
import { format } from 'date-fns';
import { calculateGroupedFoodWaterLogData } from '../hnwFoodLog/components/utility';
import { getHnwAllHealhGoalList } from '../../../actions/hnwGoalSettingActions';
import TargetWeightGraph from './targetWeightGraph/TargetWeightGraph';
import ChallengeHome from '../../challenge/challengeHome/ChallengeHome';
import {
  fetchBmiData,
  updateBmiData,
  fetchExerciseTimeLogs,
  fetchUserActivityGoal,
  fetchListMonthlyBmiData,
} from '../../../actions';
import { getHealthLogs } from '../../../thunk/foodLog.thunk';
import { setChallengeType } from '../../../actions/hnwFooterAction';
import { SET_FOOTER_STATE_HOME } from '../../../actions/types';
import HnwFooter from '../../../common/hnwFooter/HnwFooter';
import ProfileCard from './profileCard/ProfileCard';
import moment from 'moment';
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import ProfileCardLoader from '../hnwHome/hnwHomeLoader/ProfileCardLoader';
import {
  ChallengeMyBmiData,
  MyDashboardStepsLoader,
} from '../../challenge/challengeMyDashboard';
import {
  HOME_FOOD_LOG,
  HOME_NO_BMI,
  HOME_PAGE,
  HOME_WEIGHT_GOAL,
} from '../../../common/homePageConstants';
import BodyDataChangeCard from './bodyData/BodyDataChangeCard';
import HomeStepsCard from './homeStepsCard/HomeStepsCard';
import FoodLogCard from './foodLogCard/FoodLogCard';
import { useHistory, useLocation } from 'react-router-dom';
import HomeNutritionBar from './homeNutritionBar/HomeNutritionBar';
import BodyDataLoader from './bodyDataLoader/BodyDataLoader';
import HomeNutritionLoader from './homeNutritionLoader/HomeNutritionLoader';
import WaterLog from './waterLog/WaterLog';
import { MyGoalExerciseMenu } from '../../../model/ExerciseLog.model';
import HomeExerciseLog from './homeExerciseLog/HomeExerciseLog';
import { fetchBodyDifference } from '../../../actions/bodyDataDifferenceAction';
import { getWeightGraphValues } from './HnwHomeHelper';
import ChallengeAutoSyncFloatingWidget from '../../../common/challenge/asFloatingWidget/ChallengeAutoSyncFloatingWidget';
import {
  getCustomerAge,
  getCustomerGender,
} from '../../../services/healthWellness/healthWellness.service';
import { getDefaultValues } from '../../../services/healthWellness/hnwGoals.service';
import { getFacadeToken, round, setFacadeToken } from '../../../services';
import { getFacadeAccessToken } from '../../../services/promotion/auth.service';
import { UserErrorModal } from '../assessment/components';

const Home = ({ routeTo }) => {
  const DefaultTotalValues = {
    TotalCarbs: 0,
    TotalProtein: 0,
    TotalFat: 0,
    TotalWater: 0,
    TotalCalories: 0,
    TotalExercise: 0,
  };
  const DefaultGoalValues = {
    foodGoal: null,
    waterGoal: null,
    activityGoal: null,
    weightGoal: null,
  };
  const todayDateValue = {
    startDate: getDatetimeStamp(moment().format('yyyy-MM-DD')),
    endDate: getDatetimeStamp(moment().format('yyyy-MM-DD')),
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const bmiData = useSelector((state) => state.bmi);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const preRedirectTo = urlParams.get('preRedirectTo');
  const uid = urlParams.get('uid');
  const liff = window.liff;

  const {
    exerciseTimeLogList,
    userActivities,
    hnwUserGoalLoader,
    hnwExerciseLogLoader,
  } = useSelector((state) => state?.exerciseLog);
  const { policyStatus, policies } = useSelector((state) => state.home);
  const customerData = useSelector((state) => state.customer);
  const profileDetails = useSelector((state) => state.profile);
  const { hnwRecordsLoader } = useSelector(
    (state) => state.challengeBodyKey,
  );
  const [bgImg, setBgImg] = useState(BGIMG.default);
  const [logData, setLogData] = useState(null);
  const [targetWeightGraphValue, setTargetWeightGraphValue] = useState({
    series: [{ name: WEIGHT_GRAPH, data: [] }],
    graphType: WEIGHT_GRAPH_TYPE.WEEKLY,
  });
  const foodWaterData = useSelector((state) => state?.logList?.foodChartData);
  const [myGoalList, setMyGoalList] = useState([]);
  const [showAutoSync, setShowAutoSync] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showNumpad, setShowNumpad] = useState(false);
  const [bmiUpdated, setBmiUpdated] = useState(false);
  const [healthGoalUpdated, setHealthGoalUpdated] = useState(false);
  const [currentInputType, setCurrentInputType] = useState(null);
  const [userDetails, setUserDetails] = useState({
    weight: 0,
    height: 0,
  });
  const [isAccessTokenFetched, setIsAccessTokenFetched] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const exerciseData = useSelector((state) => state.hnwCalendar?.exercise);
  const healthGoal =
    useSelector((state) => state.goalSetting.healthGoalList) ??
    DefaultGoalValues;
  const healthGoalCount = Object.entries(healthGoal).filter(
    ([key, value]) => value !== null,
  );
  const healthGoalLength = healthGoalCount.length;
  const foodExerciseData = [...foodWaterData, ...exerciseData];
  const {
    TotalCarbs,
    TotalProtein,
    TotalFat,
    TotalWater,
    TotalCalories,
    TotalExercise,
  } = foodExerciseData.reduce(
    calculateGroupedFoodWaterLogData,
    DefaultTotalValues,
  );

  const { bodyDifferenceData, bodyDataDiffLoading } = useSelector(
    (state) => state.bodyDataDifference,
  );
  const healthObj = {
    TotalCarbs,
    TotalProtein,
    TotalFat,
    TotalWater,
    TotalCalories,
    TotalExercise,
  };

  const customerGender = Object.keys(GENDER).find(
    (item) => GENDER[item] === getCustomerGender(),
  );
  const customerAge = getCustomerAge();

  const defaultValues = getDefaultValues(bmiData, customerAge, customerGender);

  const defaultValue = {
    calories: round(defaultValues.defaultCalories, 0) || 0,
    protein: round(defaultValues.defaultProtein, 0) || 0,
    carbs: round(defaultValues.defaultCarbs, 0) || 0,
    fat: round(defaultValues.defaultFat, 0) || 0,
  };

  const { isAuthTokenSaved } = useSelector(
    (state) => state.tokenStatus,
  );

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const facadeToken = await getFacadeToken();
        if (!facadeToken) {
          const accessTokenObj = await getFacadeAccessToken();
          if (accessTokenObj?.access_token) {
            setFacadeToken(accessTokenObj);
          }
        }
      } catch (error) {
        console.error("Error fetching tokens:", error);
      } finally {
        setIsAccessTokenFetched(true);
      }
    };
    fetchTokens();
  }, []);

  useEffect(() => {
    if (customerData?.amwayNumber && isAuthTokenSaved && isAccessTokenFetched) {
      const CURRENT_DATE = new Date();
      if (
        customerData._id &&
        (!healthGoal.waterGoal ||
          !healthGoal.foodGoal ||
          !healthGoal.activityGoal)
      ) {
        dispatch(getHnwAllHealhGoalList(customerData._id))
          .then(() => {
            setHealthGoalUpdated(true);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        setHealthGoalUpdated(true);
      }

      dispatch(
        getHealthLogs({
          startDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
          endDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
        }),
      );

      dispatch(
        fetchExerciseCalendarData({
          startDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
          endDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
        }),
      );
      checkForTnC();
    }
  }, [customerData, customerData.amwayNumber, isAuthTokenSaved, isAccessTokenFetched]);

  useEffect(() => {
    dispatch(setChallengeType(SET_FOOTER_STATE_HOME));
  }, []);

  useEffect(() => {
    if (customerData?._id && isAuthTokenSaved && isAccessTokenFetched) {
      Promise.all([
        dispatch(fetchExerciseTimeLogs(todayDateValue)),
        dispatch(fetchUserActivityGoal(customerData?._id)),
      ]);
      if (preRedirectTo) {
        routeTo(preRedirectTo);
      }
      if (uid) {
        const customerUid = customerData?.uid;
        if (customerUid && uid && customerUid !== uid) {
          setErrorModal(true);
        }
      }
    }
  }, [customerData, preRedirectTo, uid, isAuthTokenSaved, isAccessTokenFetched]);
  useEffect(() => {
    if (
      isAuthTokenSaved &&
      isAccessTokenFetched &&
      customerData?.pictureUrl &&
      profileDetails?.pictureUrl &&
      customerData.pictureUrl !== profileDetails.pictureUrl
    ) {
      dispatch(
        updateProfileImage({
          pictureUrl: profileDetails?.pictureUrl,
        }),
      );
    }
  }, [customerData, profileDetails, isAuthTokenSaved, isAccessTokenFetched]);

  useEffect(() => {
    if (bmiUpdated && healthGoalUpdated && isAuthTokenSaved && isAccessTokenFetched) {
      const { weight, height } = bmiData;

      if (weight || height) {
        setUserDetails((prevData) => ({
          ...prevData,
          ...(weight && { weight }),
          ...(height && { height }),
        }));
      }
      setCurrentInputType(weight && !height ? 'height' : 'weight');
      if (
        (!weight || !height) &&
        (!isValidObject(healthGoal) ||
          !isValidObject(healthGoal.foodGoal) ||
          !isValidObject(healthGoal.waterGoal))
      ) {
        setShowNumpad(true);
        setShowModal(true);
      }
    }
  }, [bmiUpdated, healthGoalUpdated, isAuthTokenSaved, isAccessTokenFetched]);

  const closeLiffWindow = async () => {
    await liff.closeWindow();
  }

  const checkForTnC = async () => {
    try {
      setBgImage();
      if (!customerData.isTncAck) {
        routeTo('pathTermsConditions', '?path=pathHealthnWellness');
      } else {
        fetchData();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setBgImage = () => {
    if (customerData && customerData.gender) {
      if (customerData.gender.toLowerCase() === commonConstant.MALE) {
        setBgImg(BGIMG.male);
      } else if (customerData.gender.toLowerCase() === commonConstant.FEMALE) {
        setBgImg(BGIMG.female);
      }
    }
  };

  const fetchBMIDataOnCondition = (status = false) => {
    if (status) {
      if (!bmiData?.bmi) {

        dispatch(fetchBmiData())
          .then(() => setBmiUpdated(true))
          .catch((err) => {
            throw err;
          });

      } else {
        setBmiUpdated(true);

      }
    }
  };

  const getBodyDataDifference = async () => {
    try {
      dispatch(fetchBodyDifference());
      const { startDate, endDate } = calculateLastSevenMonthsRange();
      dispatch(fetchListMonthlyBmiData(startDate.toISOString(), endDate.toISOString()));
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const didMount = () => {
      if (isAuthTokenSaved && isAccessTokenFetched) {
        getBodyDataDifference();
      }
    };
    didMount();
  }, [isAuthTokenSaved, isAccessTokenFetched]);

  useEffect(() => {
    // Calculate graphType based on conditions
    const bmiResponse = bmiData.bmiMonthlyList;
    if (bmiResponse.length) {
      const onlyWeightRecords = bmiResponse.map((arr) => arr?.filter(bmiRecord => bmiRecord?.weight));
      const nonEmptyCount = onlyWeightRecords.filter((arr) => arr.length > 0).length;

      const graphType =
        [
          { condition: nonEmptyCount >= 5, value: WEIGHT_GRAPH_TYPE.ANNUALLY },
          {
            condition:
              onlyWeightRecords.slice(0, 4).flat().length === 0 &&
              onlyWeightRecords[5]?.length > 0,
            value: WEIGHT_GRAPH_TYPE.MONTHLY,
          },
          {
            condition:
              onlyWeightRecords.slice(0, 5).flat().length === 0 &&
              onlyWeightRecords[6]?.length > 0,
            value: WEIGHT_GRAPH_TYPE.WEEKLY,
          },
        ].find(({ condition }) => condition)?.value || WEIGHT_GRAPH_TYPE.WEEKLY;
      const { graphData, minValue } = getWeightGraphValues(graphType, onlyWeightRecords);
      setTargetWeightGraphValue({
        series: [{ name: WEIGHT_GRAPH, data: graphData }],
        graphType,
        minValue,
      });
    }
  }, [bmiData.bmiMonthlyList]);

  const handleCardClick = (type) => {
    switch (type) {
      case HOME_CARDS_ROUTES.FOOD:
        history.push(
          `${healthGoal?.foodGoal
            ? commonConstant.pathFoodLogHome
            : commonConstant.pathHnwGoalsHome
          }`,
        );
        break;
      case HOME_CARDS_ROUTES.EXERCISE:
        history.push(
          `${healthGoal?.activityGoal
            ? commonConstant.pathHnwExerciseLogHome
            : commonConstant.pathHnwGoalsHome
          }`,
        );
        break;
      case HOME_CARDS_ROUTES.WEIGHT:
        history.push(commonConstant.pathWeight);
        break;
      case HOME_CARDS_ROUTES.BMI:
        history.push(commonConstant.pathBMI);
        break;
      case HOME_CARDS_ROUTES.WATER:
        history.push(
          `${healthGoal?.waterGoal
            ? commonConstant.pathFoodLogHome
            : commonConstant.pathHnwGoalsHome
          }?scrollTo=waterLog`,
        );
        break;
    }
  };

  const GraphComp = useMemo(
    () => (
      <div className="container-fluid">
        <TargetWeightGraph
          series={targetWeightGraphValue.series}
          minValue={targetWeightGraphValue.minValue}
          graphType={targetWeightGraphValue.graphType}
          goalType={WEIGHT_GOAL_MAPPING[healthGoal?.weightGoal?.weightGoalType]}
          onClick={() => handleCardClick(HOME_CARDS_ROUTES.WEIGHT)}
        />
      </div>
    ),
    [targetWeightGraphValue, healthGoal?.weightGoal?.weightGoalType],
  );

  useEffect(() => {
    if (!policyStatus && policies.length && isAuthTokenSaved && isAccessTokenFetched) {
      dispatch(
        fetchPolicyStatus(
          policies.find(
            (el) => el.title.toLowerCase() === policyTitle.home_policy,
          ),
        ) ?? null,
      );

    }
  }, [policies.length, isAuthTokenSaved, isAccessTokenFetched]);

  useEffect(() => {
    if (isAuthTokenSaved && isAccessTokenFetched) {
      fetchBMIDataOnCondition(policyStatus);
    }
  }, [policyStatus, isAuthTokenSaved, isAccessTokenFetched]);

  const fetchData = async () => {
    try {
      if (!policyStatus) {
        if (!customerData.hasOwnProperty('isPolicyAccepted')) {
          dispatch(fetchPolicies());
        } else {
          fetchBMIDataOnCondition(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkRoute = async (pathKey, params = '') => {
    if (policyStatus && commonConstant[pathKey]) {
      routeTo(pathKey, params);
    } else {
      routeTo(routePathKey.policy);
    }
  };

  const healthParamsValues = FOOD_PARAMETERS_MAPPING.reduce(
    (acc, item) => ({
      ...acc,
      [item.name]: {
        ...item,
        consumed: healthObj[`Total${capitalizeFirstLetter(item.name)}`],
        total:
          healthGoal.foodGoal &&
          healthGoal.foodGoal[`target${capitalizeFirstLetter(item.name)}`],
      },
    }),
    {},
  );

  useEffect(() => {
    const logData = {
      foodCard: {
        isEmpty: !healthGoal.foodGoal,
        onClick: () =>
          checkRoute(
            healthGoal.foodGoal ? 'pathFoodLogHome' : 'pathHnwGoalsHome',
          ),
        healthParamsValues,
        calorie: {
          total: healthGoal?.foodGoal?.targetCalories,
          consumed: TotalCalories,
          unit: LABEL_CALORIE,
        },
      },
      waterCard: {
        isEmpty: !healthGoal.waterGoal,
        onClick: () =>
          checkRoute(
            healthGoal.waterGoal ? 'pathFoodLogHome' : 'pathHnwGoalsHome',
            '?scrollTo=waterLog',
          ),
        waterLog: {
          total: healthGoal?.waterGoal?.targetWater,
          consumed: TotalWater,
          unit: LABEL_ML,
        },
      },
      excerciseCard: {
        isEmpty: !healthGoal.activityGoal,
        onClick: () =>
          checkRoute(
            healthGoal.activityGoal
              ? 'pathHnwExerciseLogHome'
              : 'pathHnwGoalsHome',
          ),
        exerciseLog: {
          total: healthGoal?.activityGoal?.targetCalories,
          consumed: TotalExercise,
          unit: LABEL_CALORIE,
        },
      },
    };
    setLogData(logData);
  }, [
    policyStatus,
    healthGoal.foodGoal,
    healthGoal.waterGoal,
    healthGoal.activityGoal,
    TotalCarbs,
    TotalProtein,
    TotalFat,
    TotalWater,
    TotalCalories,
    TotalExercise,
  ]);

  useEffect(() => {
    if (userActivities?.exerciseCategory?.length) {
      const goalList = userActivities.exerciseCategory
        .flatMap((exercise) => exercise?.exerciseMenu)
        .map((exerciseMenu) => {
          const exerciseLoggedArr = exerciseTimeLogList?.filter(
            (timelog) => timelog?.excerciseCategory?.id === exerciseMenu?.id,
          );
          const exerciseLogged =
            exerciseLoggedArr?.reduce(
              (acc, item, i) => ({
                ...acc,
                ...item,
                caloriesBurn:
                  Number(item.caloriesBurn) + (acc?.caloriesBurn || 0),
                differenceInMinutes:
                  (item.type === LABEL_EN_MANUAL_LOG_TAB
                    ? timeMoment(item?.endTime).diff(
                      timeMoment(item?.startTime),
                      'minutes',
                    )
                    : Number(item?.excerciseTime)) +
                  (acc?.differenceInMinutes || 0),
              }),
              {},
            ) || [];
          return new MyGoalExerciseMenu({
            ...exerciseLogged,
            ...exerciseMenu,
          });
        });
      setMyGoalList(goalList);
    }
  }, [userActivities, exerciseTimeLogList]);

  const handleAutoSyncToggle = (toggleFlag) => {
    setShowAutoSync(toggleFlag);
  };
  const renderNoWeightCard = () => {
    return (
      <div
        className="home-page-no-weight-container"
        onClick={() => handleCardClick(HOME_CARDS_ROUTES.WEIGHT)}
      >
        <NoDataCard>
          <img src="/images/healthAndWellness/home-weight-goal.svg" />
          <div className="home-weight-no-data">{HOME_WEIGHT_GOAL.title}</div>
        </NoDataCard>
      </div>
    );
  };

  const renderNoBmiCard = () => {
    return (
      <div
        className="home-page-no-bmi-container"
        onClick={() => handleCardClick(HOME_CARDS_ROUTES.BMI)}
      >
        <NoDataCard>
          <div className="home-no-bmi-title">
            <div className="home-no-bmi-title-bmi">{HOME_NO_BMI.bmi}</div>
            <div className="home-bmi-bottom-section">
              <div className="home-bmi-value">{HOME_NO_BMI.value}</div>
              <div className="home-bmi-des">{HOME_NO_BMI.des}</div>
            </div>
          </div>
        </NoDataCard>
      </div>
    );
  };

  const renderNoFoodLogCard = () => {
    return (
      <div
        className="home-page-no-food-log-container"
        onClick={() => handleCardClick(HOME_CARDS_ROUTES.FOOD)}
      >
        <NoDataCard>
          <img src="/images/healthAndWellness/home-food-goal.svg" />
          <div className="home-food-no-data">{HOME_FOOD_LOG.noFoodGoal}</div>
        </NoDataCard>
      </div>
    );
  };

  const handleNumpadSubmit = () => {
    const { weight, height } = userDetails;
    if (weight && height) {
      const payload = Object.assign({}, userDetails, {
        height: Number(userDetails.height),
        weight: Number(userDetails.weight),
      });

      setShowModal(false);
      dispatch(updateBmiData(payload))
        .then(() => {
          const { startDate, endDate } = calculateLastSevenMonthsRange();
          dispatch(fetchListMonthlyBmiData(startDate.toISOString(), endDate.toISOString()));
        });
    }
    setShowNumpad(false);
    setCurrentInputType(null);
  };

  const handleNumpadValueChange = (val, type) => {
    const regex =
      currentInputType === 'weight'
        ? H_W_VALIDATION.weight.pattern
        : H_W_VALIDATION.height.pattern;
    const newValue =
      type !== 'delete' ? (userDetails[currentInputType] || '') + val : val;
    if (regex.test(newValue)) {
      setUserDetails((prevData) => ({
        ...prevData,
        [currentInputType]: newValue,
      }));
    }
  };

  const handleNumpadDelete = () => {
    const newValue = userDetails[currentInputType].toString().slice(0, -1);
    handleNumpadValueChange(newValue, 'delete');
  };

  const handleInputBoxClick = (name) => {
    setCurrentInputType(name);
    if (!showNumpad) {
      setShowNumpad(true);
    }
  };

  const renderNoExerciseLogCard = () => {
    return (
      <div
        className="home-page-no-exercise-log-container"
        onClick={() => handleCardClick(HOME_CARDS_ROUTES.EXERCISE)}
      >
        <NoDataCard>
          <img
            src="/images/healthAndWellness/home-exercise-no-data.svg"
            className="home-exercise-no-img"
          />
          <div className="home-exercise-no-data">
            {HOME_PAGE.EXERCISE_NO_DATA}
          </div>
          <div className="home-exercise-no-data-subtitle">
            {HOME_PAGE.EXERCISE_NO_DATA_SUBTITLE}
          </div>
        </NoDataCard>
      </div>
    );
  };

  const renderNoWaterTargetCard = () => {
    return (
      <div
        className="home-page-no-water-target-log-container"
        onClick={() => handleCardClick(HOME_CARDS_ROUTES.WATER)}
      >
        <NoDataCard>
          <img
            src="/images/healthAndWellness/water-target-no-data.svg"
            className="water-target-no-img"
          />
          <div className="home-water-no-data">
            {HOME_PAGE.WATER_LOG_NO_DATA}
          </div>
          <div className="home-water-no-data-subtitle">
            {HOME_PAGE.WATER_LOG_NO_DATA_SUBTITLE}
          </div>
        </NoDataCard>
      </div>
    );
  };

  const exerciseLogs = myGoalList.slice(0, 2);
  return (
    <div className="hnw-home-header-container">
      <div className="container-fluid">
        {/* //home header section */}
        <div className="header-bottom">
          <Header routeTo={checkRoute} />
        </div>
        {/* //home profile section */}
        {bmiData?.hnwCustomerLoader ? (
          <ProfileCardLoader />
        ) : (<ProfileCard
          customer={customerData}
        />)}
      </div>
      {/* //home steps section */}
      <div className="home-page-steps-container">
        {(hnwRecordsLoader && customerData?.bodyKeyId) || hnwExerciseLogLoader || hnwUserGoalLoader ? (
          <MyDashboardStepsLoader />
        ) : (<HomeStepsCard
          goalCount={healthGoalLength}
          exerciseLogs={exerciseLogs}
          bodyKeyId={customerData?.bodyKeyId}
          handleAutoSyncToggle={handleAutoSyncToggle}
        />)}
      </div>
      {/* //home weight section */}
      {bmiData?.hnwBMILoader ? (
        <MyDashboardStepsLoader />
      ) : bmiData?.weight == '' ? (
        renderNoWeightCard()
      ) : (
        GraphComp
      )}

      {/* //weight weight section */}
      {/* //home bmi section */}
      {!bmiData?.bmi ? (
        bmiData?.hnwBMILoader ? (
          <MyDashboardStepsLoader />
        ) : (
          renderNoBmiCard()
        )
      ) : bmiData?.hnwBMILoader ? (
        <MyDashboardStepsLoader />
      ) : (
        <ChallengeMyBmiData
          bmiHeading={HOME_PAGE.BMI_HEADING}
          bmiThaiHeading={HOME_PAGE.BMI_HEADING_THAI}
          bmiValue={bmiData?.bmi}
          routeTo={checkRoute}
        />
      )}
      {/* //home body data section */}
      {bodyDataDiffLoading || bmiData?.hnwBMILoader ? (
        <BodyDataLoader />
      ) : (
        bmiData &&
        bodyDifferenceData && (
          <BodyDataChangeCard
            bodyDiffData={bodyDifferenceData}
            userData={bmiData}
          />
        )
      )}

      {/* //home food log section */}

      {bmiData?.hnwBMILoader ? (
        <MyDashboardStepsLoader />
      ) : !healthGoal?.foodGoal && !defaultValue.calories ? (
        renderNoFoodLogCard()
      ) : (
        <div
          className="home-page-food-log-container"
          onClick={() => handleCardClick(HOME_CARDS_ROUTES.FOOD)}
        >
          {logData?.foodCard && (
            <FoodLogCard
              logData={
                healthGoal?.foodGoal?.targetCalories || defaultValue.calories
              }
              consumed={TotalCalories}
            />
          )}
        </div>
      )}

      {/* //TODO hide for now */}
      {/* //home badge section */}
      {/* <HomeBadgeCard/> */}
      {bmiData?.hnwBMILoader ? (
        <HomeNutritionLoader />
      ) : (
        <div className="container-fluid">
          <div onClick={() => handleCardClick(HOME_CARDS_ROUTES.FOOD)}>
            {logData?.foodCard && (
              <HomeNutritionBar
                defaultNutrition={defaultValue}
                nutritionData={logData?.foodCard}
              />
            )}
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="drink-excercise-container">
          {!healthGoal?.waterGoal
            ? renderNoWaterTargetCard()
            : logData?.waterCard && (
              <div onClick={() => handleCardClick(HOME_CARDS_ROUTES.WATER)}>
                <WaterLog waterData={logData?.waterCard} />
              </div>
            )}
          {!healthGoal?.activityGoal
            ? renderNoExerciseLogCard()
            : logData?.excerciseCard && (
              <div
                onClick={() => handleCardClick(HOME_CARDS_ROUTES.EXERCISE)}
              >
                <HomeExerciseLog
                  exerciseData={logData?.excerciseCard?.exerciseLog}
                  exerciseTimeLogList={exerciseTimeLogList}
                />
              </div>
            )}
        </div>
      </div>
      <ChallengeAutoSyncFloatingWidget
        showAutoSync={showAutoSync}
        handleAutoSyncToggle={handleAutoSyncToggle}
      />
      <Assessment routeTo={checkRoute} isAccessTokenFetched={isAccessTokenFetched} />
      <ChallengeHome isAccessTokenFetched={isAccessTokenFetched} />
      <HnwFooter />
      <HnWSlideup scrollHide={true} bottomGap={homePageSlideUpGap} />
      <HnwInputModal
        isOpen={showModal}
        onBackgroundClick={handleNumpadSubmit}
        onNumpadValueChange={handleNumpadValueChange}
        onNumpadDelete={handleNumpadDelete}
        showNumpad={showNumpad}
        onNumpadSubmit={handleNumpadSubmit}
      >
        <HnwHeightWeightInput
          userHeight={userDetails.height}
          userWeight={userDetails.weight}
          handleInputBoxClick={handleInputBoxClick}
        />
      </HnwInputModal>
      {errorModal &&
        <UserErrorModal
          isOpen={errorModal}
          onClose={() => closeLiffWindow()}
        >
        </UserErrorModal>
      }
    </div>
  );
};

export default Home;

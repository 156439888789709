import React from 'react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import './TeamMissionSlider.scss';
import {TeamDashboardSwiperMember, TeamDashboardSwiperDetail} from '../../../../common';
import { CHALLENGE } from '../../../../common/challengeConstants';

SwiperCore.use([Navigation, EffectCoverflow]);

const TeamMissionSlider = ({
    missionUserData,
    teamSize,
    challengeUserData
}) => {

    const _teamMissionTitle = () => {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h6 className="dashboard-slider-title">ภารกิจ <span className='mission-days'>60</span> วัน สะสมเหรียญพิชิตรางวัล</h6>
                    </div>
                </div>
            </div>
        )
    }

    return (
    <>
        <div className="dashboard-slider-outer">
            <div className="dashboard-slider-wrapper">
                {_teamMissionTitle()}

                <div class="dashboard-slider-container">
                    <TeamDashboardSwiperMember challengeUserData={challengeUserData} teamSize={teamSize}/>
                    <TeamDashboardSwiperDetail swiperData={missionUserData}/>
                </div>
            </div>

        </div>
    </>

    );
};

export default TeamMissionSlider
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MyImagePreview from '../myImagePreview/MyImagePreview';
import HnWNumpadModal from '../../hnWNumpadModal/HnWNumpadModal';
import AndroidUploadPicker from '../androidUploadPicker/AndroidUploadPicker';
import {
  checkIsIOS,
  getCurrentDateTimeStamp,
  getSplitText,
  thaiDate,
} from '../../../utill.func';
import { Upload } from 'antd';
import commonConstant, {
  ANDROID_CAMERA_PAGE_CANCEL_BTN,
  BUTTON_TAGNAME,
} from '../../commonConstant';
import {
  IMAGE_UPLOAD_ID,
  IMAGE_UPLOAD_INDEX_ARR,
  IMAGE_UPLOAD_LOADING_STATE,
  IMAGE_UPLOAD_PROPS,
  PERSONAL_MEMBER_PICTURE_MAP,
  PICTURE_TYPE_LOCALSTORAGE,
  REDIRECTION_URI,
} from '../../challengeConstants';
import {
  getLocalStorage,
  setLocalStorage,
} from '../../../services/promotion/utils.service';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { Camera } from './camera/Camera';
import {
  getChallengeImagesAction,
  getPreSignedUrlAndUploadAction,
} from '../../../actions/challenge/challengeImageUploadActions';
import moment from 'moment';
import './CommonAndroidWrapper.scss';

const convertBlobToBinaryString = (blobFile, callbackBinaryString) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(blobFile);
  reader.onloadend = () => callbackBinaryString(reader.result);
};

const image64ToImage = (base64) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => resolve(img);
    img.onerror = () => reject(img);
  });
};

const cropImage = (image, x, y, newWidth, newHeight) => {
  const canvas = document.createElement('canvas');
  canvas.width = newWidth;
  canvas.height = newHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(image, x, y, newWidth, newHeight, 0, 0, newWidth, newHeight);
  return canvas.toDataURL('image/jpeg', 0.5);
};

const cropImage64 = async (base64, x, y, newWidth, newHeight) => {
  const img = await image64ToImage(base64);
  return cropImage(img, x, y, newWidth, newHeight);
};

const DEFAULT_CROP = { x: 0, y: 0 }
const DEFAULT_ZOOM = 1
const DEFAULT_IMAGE_PROPS = {
  width: 0,
  height: 0,
  aspect: 0.75,
  containerHeight: 500,
}
export const CommonAndroidWrapper = forwardRef(
  (
    {
      imageRef,
      componentInstance,
      photos,
      setPhotos,
      eventId,
      partyId,
      aboId,
      userId,
      redirectionEventIdQuery,
      setPhotosLoading,
      setDatetimeThaiObj,
    },
    ref,
  ) => {
    const camera = useRef(null);

    const dispatch = useDispatch();
    const { images: s3ImagesStore } = useSelector(
      (state) => state.challengeImageUpload,
    );

    const [uploadId, setUploadId] = useState('');
    const [previewBlob, setPreviewBlob] = useState(null);
    const [activePosition, setActivePosition] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPicker, setShowPicker] = useState(null);
    const [flash, setFlash] = useState(false);
    const [crop, setCrop] = useState(DEFAULT_CROP);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [coord, setCoord] = useState(null);
    const [imageProperties, setImageProperties] = useState(DEFAULT_IMAGE_PROPS);

    useEffect(() => {
      if (eventId && userId) {
        getChallengeUserImages(eventId, userId);
      }
    }, [eventId, userId]);

    useEffect(() => {
      if (s3ImagesStore.length > 0) {
        onMountHandler(s3ImagesStore);
      }
    }, [s3ImagesStore]);

    const getChallengeUserImages = async (eventId, userId) => {
      try {
        setLoading(true);
        await dispatch(
          getChallengeImagesAction({
            eventId,
            userId,
            scoreId: null,
          }),
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const handleImageUpload = (photoPosition) => {
      setUploadIdHandler(photoPosition);
      setActivePosition(photoPosition);
      if (checkIsIOS()) {
        const onClickCallback = imageRef?.current?.upload?.uploader?.onClick;
        onClickCallback({
          target: {
            tagName: BUTTON_TAGNAME,
            blur: () => { },
          },
        });
      } else {
        setShowPicker(photoPosition);
      }
    };

    useImperativeHandle(ref, () => ({
      handleImageUpload,
      onMountHandler,
    }));

    const onMountHandler = async (images) => {
      let photosObj = photos;
      let datetimeObj;
      const userUploads = images.flatMap((image) => image?.userUploads);
      userUploads.forEach((userUpload) => {
        if (activePosition) {
          photosObj = {
            ...photosObj,
            [IMAGE_UPLOAD_INDEX_ARR[userUpload?.upload_index - 1]]:
              IMAGE_UPLOAD_INDEX_ARR[userUpload?.upload_index - 1] ===
                activePosition
                ? userUpload.url
                : userUpload.thumbnail_url,
          };
        } else {
          photosObj = {
            ...photosObj,
            [IMAGE_UPLOAD_INDEX_ARR[userUpload?.upload_index - 1]]:
              userUpload.thumbnail_url || null,
          };
        }
        datetimeObj = {
          ...datetimeObj,
          [IMAGE_UPLOAD_INDEX_ARR[userUpload?.upload_index - 1]]:
            userUpload.updated_at
              ? thaiDate(userUpload.updated_at, 'dd MMM yy')
              : null,
        };
      });
      setPhotos(photosObj);
      if (setDatetimeThaiObj) {
        setDatetimeThaiObj(datetimeObj);
      }
    };

    const retakeCallback = async () => {
      if (componentInstance === 'CAMERA') {
        const photoPosition = await getLocalStorage(PICTURE_TYPE_LOCALSTORAGE);
        handleImageUpload(photoPosition);
      } else {
        handleImageUpload(activePosition);
      }
    };

    const previewHandler = (type, event) => {
      switch (type) {
        case 'onChange':
          setPreviewBlob(URL.createObjectURL(event.file.originFileObj));
          break;
        case 'onClose':
        default:
          setPreviewBlob(null);
          break;
      }
    };

    const beforeUpload = (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', event => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height, naturalHeight, naturalWidth } = image;
          if (height > width) {
            const cropperAspect = parseFloat((width / height).toFixed(2))
            const imageProps = {
              width,
              height,
              cropperAspect,
              containerHeight: cropperAspect <= 0.6 ? 650 : 500
            }
            setImageProperties(imageProps)
          }
        });
      });
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      return isJpgOrPng;
    }

    const handleAndroidPicker = (type) => {
      switch (type) {
        case 'takePhoto':
          if (
            ['PICTURE_PAGE', 'PICTURE_COMPONENT'].includes(componentInstance)
          ) {
            setLocalStorage(PICTURE_TYPE_LOCALSTORAGE, activePosition);
            setLocalStorage(
              REDIRECTION_URI,
              commonConstant.pathChallengeMyDashboard,
            );
            setLocalStorage(
              IMAGE_UPLOAD_PROPS,
              JSON.stringify({
                eventId,
                partyId,
                aboId,
                userId,
                redirectionEventIdQuery,
              }),
            );
            setLocalStorage(
              IMAGE_UPLOAD_ID,
              setUploadIdHandler(activePosition),
            );
          }
          setCrop(DEFAULT_CROP);
          setZoom(DEFAULT_ZOOM);
          setCoord(null);
          setShowPicker(null);
          setPreviewBlob(null);
          window.location.href = process.env.REACT_APP_CHALLENGE_CAMERA_LIFF_URL;
          break;
        case 'uploadPhoto':
        default:
          const onClickCallback = imageRef?.current?.upload?.uploader?.onClick;
          onClickCallback({
            target: {
              tagName: BUTTON_TAGNAME,
              blur: () => { },
            },
          });
          setCrop(DEFAULT_CROP);
          setZoom(DEFAULT_ZOOM);
          setCoord(null);
          setShowPicker(null);
          break;
      }
    };

    const setUploadIdHandler = (photoPosition) => {
      const uploadIndex = parseInt(
        IMAGE_UPLOAD_INDEX_ARR.indexOf(photoPosition) + 1,
      );
      const userUpload = s3ImagesStore
        .flatMap((image) => image?.userUploads)
        .find((upload) => upload?.upload_index === uploadIndex);
      setUploadId(userUpload.id);
      return userUpload.id;
    };

    const uploadCallback = async () => {
      setLoading(true);

      const croppedImage = await cropImage64(
        previewBlob,
        coord.x,
        coord.y,
        coord.width,
        coord.height,
      );
      const response = await fetch(croppedImage);
      const blobImage = await response.blob();
      try {
        let uId = uploadId;
        let isBeforeAfter;
        let postCompetition;
        if (componentInstance === 'CAMERA') {
          const imageUploadId = await getLocalStorage(IMAGE_UPLOAD_ID);
          const pictureType = await getLocalStorage(PICTURE_TYPE_LOCALSTORAGE);
          isBeforeAfter = getSplitText(pictureType, 0, '_');
          postCompetition = getSplitText(pictureType, 1, '_');
          uId = imageUploadId;
        } else {
          setPhotosLoading({
            ...IMAGE_UPLOAD_LOADING_STATE,
            [activePosition]: true,
          });
          uId = uploadId;
          isBeforeAfter = getSplitText(activePosition, 0, '_');
          postCompetition = getSplitText(activePosition, 1, '_');
          setShowPicker(null);
          setPreviewBlob(null);
        }
        if (blobImage) {
          const fileExtention = getSplitText(blobImage.type, 1, '/') || 'png';
          const fileName = `beforeafter/${partyId}/${isBeforeAfter}/${aboId}_${partyId}_${postCompetition}_${getCurrentDateTimeStamp()}.${fileExtention}`;
          convertBlobToBinaryString(blobImage, async (binaryString) => {
            const payload = {
              // 1st
              eventId: eventId,
              partyId: partyId,
              fileName,
              contentType: blobImage.type,
              // 2nd
              file: binaryString,
              // 3rd
              uploadId: uId,
              approveStatus: 'APPROVED',
              fileDesc: fileName,
              uploadDate: moment().format('yyyy-MM-DD'),
              createdAt: moment().format('yyyy-MM-DD'),
            };
            await dispatch(getPreSignedUrlAndUploadAction(payload));
            if (componentInstance === 'CAMERA') {
              const redirectToPath = await getLocalStorage(REDIRECTION_URI);
              window.location.href = `https://${window.location.hostname}${redirectToPath}?eventId=${redirectionEventIdQuery}&partyId=${partyId}&challengeId=${eventId}&scrollTo=imageCardSection`;
            } else {
              await getChallengeUserImages(eventId, userId);
            }
            setLoading(false);
            if (componentInstance !== 'CAMERA') {
              setPhotosLoading(IMAGE_UPLOAD_LOADING_STATE);
            }
          });
        } else {
          setLoading(false);
          if (componentInstance !== 'CAMERA') {
            setPhotosLoading(IMAGE_UPLOAD_LOADING_STATE);
          }
        }
      } catch (error) {
        setLoading(false);
        if (componentInstance !== 'CAMERA') {
          setPhotosLoading(IMAGE_UPLOAD_LOADING_STATE);
        }
      }
    };
    const handleCancelBtn = async () => {
      const redirectToPath = await getLocalStorage(REDIRECTION_URI);
      window.location.href = `https://${window.location.hostname}${redirectToPath}?eventId=${redirectionEventIdQuery}&partyId=${partyId}&challengeId=${eventId}`;
    };
    return (
      <>
        {loading && componentInstance === 'CAMERA' ? (
          <LoadingOverlay text="Loading" active={loading} spinner={true} />
        ) : null}
        {componentInstance === 'CAMERA' ? (
          <>
            <Camera
              ref={camera}
              setImage={(bFile) => setPreviewBlob(URL.createObjectURL(bFile))}
              aspectRatio={3 / 5.5}
              facingMode="environment"
            />
            <div className="custom-android-camera-container-flash">
              <img
                src={`/images/challenge/myupload/${flash ? 'flashon' : 'flashoff'
                  }.svg`}
                alt="flash.svg"
                onClick={() => {
                  const flashState = camera.current.toggleTorch();
                  setFlash(flashState);
                }}
              />
            </div>
            <div className="custom-android-camera-container-actions">
              <span
                className="custom-android-camera-container-actions-cancel"
                onClick={handleCancelBtn}
              >
                {ANDROID_CAMERA_PAGE_CANCEL_BTN}
              </span>
              <img
                src="/images/challenge/myupload/camera-click.svg"
                alt="camera-click.svg"
                onClick={() => camera.current.takePhoto('toBlob')}
              />
              <img
                src="/images/challenge/myupload/change-camera.svg"
                alt="change-camera.svg"
                onClick={() => camera.current.switchCamera()}
              />
            </div>
          </>
        ) : null}
        <div className="common-android-preview-height">
          {previewBlob ? (
            <HnWNumpadModal
              openNumpad={previewBlob}
              onBackgroundClick={() => previewHandler('onClose')}
            >
              <MyImagePreview
                crop={crop}
                setCrop={setCrop}
                zoom={zoom}
                setZoom={setZoom}
                setCoord={setCoord}
                activePosition={
                  PERSONAL_MEMBER_PICTURE_MAP[activePosition.toUpperCase()]
                }
                previewBlob={previewBlob}
                uploadCallback={uploadCallback}
                retakeCallback={retakeCallback}
                imageProperties={imageProperties}
                closePreview={() => previewHandler('onClose')}
              />
            </HnWNumpadModal>
          ) : null}
        </div>
        <div className="common-android-picker-height">
          {showPicker ? (
            <HnWNumpadModal
              openNumpad={showPicker}
              onBackgroundClick={() => setShowPicker(null)}
            >
              <AndroidUploadPicker
                onCloseHandler={() => setShowPicker(null)}
                handleAndroidPicker={handleAndroidPicker}
              />
            </HnWNumpadModal>
          ) : null}
        </div>
        <Upload
          onChange={(event) => previewHandler('onChange', event)}
          className="display-none"
          name="myPhotoUpload"
          id="myPhotoUpload"
          ref={imageRef}
          accept={'image/png,image/jpeg,image/jpg'}
          openFileDialogOnClick={checkIsIOS()}
          beforeUpload={beforeUpload}
        />
      </>
    );
  },
);

import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";

const CalorieBadgeDetail = () => <div>
  <p>
    {CHALLENGE.badges.CalorieMissionBadgeHeading}<br />
    {CHALLENGE.badges.FoodCalorieSubHeading}
  </p>
  <p className="badge-note">{CHALLENGE.badges.FoodNoteText}
  </p>
</div>

export default CalorieBadgeDetail;
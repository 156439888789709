import config from '../config';

export class ChallengeList {
  constructor(data = null) {
    if (data) {
      this.id = data._id || data.id || '';
      this.name = data.name || '';
      this.description = data.description || '';
      this.eventId = data.eventId || '';
      this.startDate = data.startDate || null;
      this.endDate = data.endDate || null;
      this.completeDate = data.completeDate || null;
      this.challengeCardImg = data.challengeCardImg
        ? `${config.S3_URL}${data.challengeCardImg}`
        : '';
      this.challengeDetailImg = data.challengeDetailImg
        ? `${config.S3_URL}${data.challengeDetailImg}`
        : '';
      this.content = data.content || null;
      this.challengeLink = data.challengeLink || null;
      this.challengeStatus = data.challengeStatus || null;
      this.status = data.status || null;
      this.teamDashboardImage = data.teamDashboardImg
      ? `${config.S3_URL}${data.teamDashboardImg}`
      : '';
      this.missionContent = data.missionContent || null;
    }
  }
}

import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { commonConstant } from '../../../common';
import { getEntriesFromCart } from '../../../services/promotion/bundle.service';
import { getCustomerFOA } from '../../../services/promotion/foa.service';
import {
  addPremiumItemToCart,
  addProductsToCart,
  deleteCartItems,
  deleteCartItemsPublic,
  deleteCouponToCart,
  getCart,
  getCartInstallment,
  getCartPublic,
  getPriceFormat,
  getPromotionList,
  INSTALLMENT_TYPE,
  isCartABOMemberType,
  isCartABOType,
  isCartMemberType,
  message,
  PAYMENT_OPTION,
  RULE_BASED,
  SOP_BASIC,
  SOP_BEYOND,
  updateCart,
  updateCartPublic,
} from '../../../services/promotion/utils.service';
import Liff from '../../modal/Liff';
import LiffPromotionList from '../../modal/LiffPromotionList';
import '../../promotion/CartPromotion.scss';
import { SOPProfileList } from '../../sop/SOPProfileList/index';
import CouponPanel from '../CouponPanel';
import InputCoupon from '../InputCoupon';
import BundleProductItem from '../PromotionTemplate/BundleProductItem';
import Product, { ProductItemType } from '../PromotionTemplate/Product';
import PromotionItem from '../PromotionTemplate/PromotionItem';
import {
  ABO_MEMBER,
  FOA_MEMBER,
  UpdateState,
} from '../SelectProduct/PromotionProduct';
import { liffGetProfile } from '../../../services/promotion/auth.service';
import { getCartData } from '../../../actions/cartAction';
import { connect } from 'react-redux';

export const history = createBrowserHistory();

const { liff } = window;

export class CartPromotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      pifCart: props.location.state?.pifCart?props.location.state?.pifCart:null,
      zippCart: null,
      loading: true,
      isLiffopen: false,
      isLiffPromotionOpen: false,
      productEntries: [],
      pifProductEntries: [],
      zippProductEntries: [],
      isInstallment: false,
      phone: '',
      customerData: null,
      haveAddress: false,
      selectedPaymentTab: PAYMENT_OPTION.PIF,
      lastSelectedPaymentTab: null,
      promotionList: [],
      isSopLiffOpen: false,
      customerFOA: false,
      isInitialLoad: true,
    };

    this.onOpenLiff = this.onOpenLiff.bind(this);
    this.onCloseLiff = this.onCloseLiff.bind(this);
    this.onCloseSopLiff = this.onCloseSopLiff.bind(this);
    this.onUpdateCart = this.onUpdateCart.bind(this);
    this.goToRemovePage = this.goToRemovePage.bind(this);
    this.goToAddressList = this.goToAddressList.bind(this);
    this.handleIntitCart = this.handleIntitCart.bind(this);
    this.setProductSelected = this.setProductSelected.bind(this);
    this.handlePremiumItemSet = this.handlePremiumItemSet.bind(this);
    this.onOpenPromotionLiff = this.onOpenPromotionLiff.bind(this);
    this.onClosePromotionLiff = this.onClosePromotionLiff.bind(this);
    this.fetchCartData = this.fetchCartData.bind(this);
    this.fetchPIFCart = this.fetchPIFCart.bind(this);
    this.fetchZIPPCart = this.fetchZIPPCart.bind(this);
    this.fetchPromotionList = this.fetchPromotionList.bind(this);
    this.renderPromotionList = this.renderPromotionList.bind(this);
    this.navigatePromotion = this.navigatePromotion.bind(this);
    this.onPromoClose = this.onPromoClose.bind(this);
    this.isProductPromotion = this.isProductPromotion.bind(this);
  }

  async componentDidMount() {
    if (this.props?.data && this.props?.data.length === 0) {
      await this.props.getCartData('FULL');
    }
    this.setState({pifCart : this.props?.data})
    this.handleIntitCart();
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const hasCustomerFOA =
      customer.type === 'public' || customer.type === 'foa';
    this.setState({ customerFOA: hasCustomerFOA });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && !this.state.isInitialLoad) {
      this.setState({pifCart : this.props?.data})
      await this.fetchCartData();
      await this.handlePremiumItemSet();
    }
  } 

  async handleIntitCart() {
    await this.fetchCartData();
    await this.fetchPromotionList();
    await this.handlePremiumItemSet();

    if (this.state.cart) {
      for (const entry of this.state.cart[0].entries) {
        if (entry.kitEntryCode) {
          this.setProductSelected(
            entry.product.id,
            entry.quantity,
            UpdateState.INIT,
            entry.kitEntryCode,
          );
        }
      }
    }
  }

  async fetchPromotionList() {
    try {
      this.setState({ loading: true });
      const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
      const guid = sessionStorage.getItem('guid');
      const { userId } = await liffGetProfile();
      const id = customerFOA ? guid : userId;
      const promotionList = await getPromotionList(id);
      this.setState({
        promotionList,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      alert(message.error.somethingWrong);
    }
  }

  async fetchCartData() {
    try {
      await this.fetchZIPPCart();
      await this.fetchPIFCart();
      this.updateSelectedPaymentTab(
        this.state.lastSelectedPaymentTab
          ? this.state.lastSelectedPaymentTab
          : this.state.isInstallment
          ? PAYMENT_OPTION.ZIPP
          : PAYMENT_OPTION.PIF,
      );
    } catch (error) {
      console.log(error);
      alert(message.error.somethingWrong);
    }
  }

  async fetchPIFCart() {
    try {
      this.setState({ loading: true });
      const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
      if (!localStorage.getItem('deleteIds')) {
        localStorage.setItem('deleteIds', '[]');
      }
      const guid = sessionStorage.getItem('guid');
      let pifCart =this.state.pifCart;
      if (!pifCart) { 
      pifCart = !customerFOA
        ? await this.props.getCartData("FULL")
        : await getCartPublic(guid);
      }
      const localAddress =
        sessionStorage.getItem('address') !== null
          ? JSON.parse(sessionStorage.getItem('address'))
          : null;
      if (customerFOA) {
        this.setState({
          customerData: {
            ...customerFOA,
            address: localAddress,
          },
          phone: localAddress?.phone ? localAddress.phone : '',
        });
      }
      const pifCartData = pifCart && pifCart.length ? pifCart[0] : {};
      const pifCartId = pifCartData && pifCartData.id ? pifCartData.id : null;
      let pifProductEntries = getEntriesFromCart(pifCartData);
      pifProductEntries = this.setQuantityToProduct(pifProductEntries);
      this.setState({
        pifCart,
        loading: false,
        pifProductEntries,
      });
      if (!localStorage.getItem('pifCartId')) {
        localStorage.setItem('pifCartId', JSON.stringify(pifCartId));
      }
      // check cartId in localStorage
      const cartLocal = JSON.parse(localStorage.getItem('pifCartId'));
      if (cartLocal !== pifCartId) {
        localStorage.setItem('pifCartId', JSON.stringify(pifCartId));
        localStorage.setItem('deleteIds', '[]');
      }
      // check address member-abo
      if (!this.state.customerData) {
        if (
          pifCartData &&
          pifCartData?.deliveryMethod.address?.fullAddress !== ''
        ) {
          this.setState({ haveAddress: true, pifCart: pifCart });
        } else {
          this.setState({ haveAddress: false });
        }
      } else {
        //check address public
        const address = sessionStorage.getItem('address');
        if (address) {
          this.setState({ haveAddress: true, pifCart: pifCart });
        } else {
          this.setState({ haveAddress: false });
        }
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      alert(message.error.somethingWrong);
    }
  }

  async fetchZIPPCart() {
    try {
      this.setState({ loading: true });
      if (!localStorage.getItem('deleteIds')) {
        localStorage.setItem('deleteIds', '[]');
      }
      const urlParams = queryString.parse(this.props.location.search);

      if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
        const cartId = urlParams.cartId;
        this.setState({ isInstallment: true });
        if (!cartId) {
          alert('ไม่พบข้อมูลตะกร้าสินค้า');
        }
        const zippCart = await getCartInstallment(cartId);
        let zippProductEntries = zippCart.entries;
        zippProductEntries = this.setQuantityToProduct(zippProductEntries);
        this.setState({
          zippCart: [zippCart],
          loading: false,
          zippProductEntries,
        });

        if (zippCart && zippCart?.deliveryMethod.address?.fullAddress !== '') {
          this.setState({ haveAddress: true });
        } else {
          const address = sessionStorage.getItem('address');
          if (address) {
            this.setState({ haveAddress: true });
          } else {
            this.setState({ haveAddress: false });
          }
        }
        console.log('ZIPP cart : ', zippCart);
        this.setState({ loading: false });
        return;
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      alert(message.error.somethingWrong);
    }
  }

  async handlePremiumItemSet() {
    if (this.state.cart) {
      if (
        this.state.cart[0].premiumItemSet.length !== 0 ||
        this.state.cart[0].premiumItemSet !== []
      ) {
        this.setState({ loading: true });

        const productIds = [];
        const deleteLocal = JSON.parse(localStorage.getItem('deleteIds'));
        for (const entry of this.state.cart[0].entries) {
          productIds.push(entry.product.id);
        }
        for (const premiumItem of this.state.cart[0].premiumItemSet) {
          const premiumItemSet = premiumItem;
          const firstItem = premiumItemSet.premiumItems[0];
          let isHavePremiumSetTocart = false;
          for (const item of premiumItemSet.premiumItems) {
            if (productIds.includes(item.id)) {
              const productIndex = productIds.findIndex((v) => v === item.id);
              const quantity =
                this.state.cart[0].entries[productIndex].quantity;
              isHavePremiumSetTocart = true;
              if (item.targetPrice === 0) {
                await updateCart(
                  this.state.cart[0].id,
                  productIndex,
                  quantity + premiumItemSet.elligibleTargetQty,
                );
                const newDeleteLocal = deleteLocal.filter(
                  (v) => v.product.id !== item.id,
                );
                localStorage.setItem(
                  'deleteIds',
                  JSON.stringify(newDeleteLocal),
                );
                await this.fetchCartData();
              }
            }
          }
          if (!isHavePremiumSetTocart) {
            const hasDelete = deleteLocal.find(
              (v) => v.product.id === firstItem.id,
            );
            if (!hasDelete) {
              await addPremiumItemToCart(
                this.state.cart[0].id,
                firstItem,
                premiumItemSet.elligibleTargetQty,
              );
              const newDeleteLocal = deleteLocal.filter(
                (v) => v.product.id !== firstItem,
              );
              localStorage.setItem('deleteIds', JSON.stringify(newDeleteLocal));
              await this.fetchCartData();
            }
          }
        }
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: true });
      localStorage.setItem('deleteIds', '[]');
      this.setState({ loading: false });
    }
  }

  async onUpdateCart(cartId, itemId, quantity) {
    try {
      this.setState({ loading: true });
      const guid = sessionStorage.getItem('guid');
      let cart;
      !this.state.customerData
        ? cart= await updateCart(cartId, itemId, quantity)
        : cart= await updateCartPublic(cartId, itemId, quantity, guid);
        this.setState({pifCart: [cart] });
      await this.fetchCartData();
      await this.handlePremiumItemSet();
    } catch (error) {
      console.log(error);
      alert(message.error.somethingWrong);
    }
  }

  onOpenLiff() {
    this.setState({ isLiffopen: true });
  }

  onCloseLiff() {
    this.setState({ isLiffopen: false });
    this.fetchCartData();
  }

  onOpenPromotionLiff() {
    this.setState({ isLiffPromotionOpen: true });
  }

  onClosePromotionLiff() {
    this.setState({ isLiffPromotionOpen: false });
  }

  navigatePromotion(promotion) {
    if (promotion && promotion.id) {
      const redirectUrl = `${commonConstant.pathProductPromotion}?id=${promotion.id}&productId=`;
      window.location.href = redirectUrl;
    }
  }

  onSopBtnClick() {
    this.setState({ isSopLiffOpen: true });
  }

  onCloseSopLiff() {
    this.setState({ isSopLiffOpen: false });
  }

  onPromotionBtnClick() {
    this.onOpenPromotionLiff();
  }

  setQuantityToProduct(entries) {
    const newRelatedProducts = entries.map((entry) => {
      entry.product.quantity = entry.quantity;
      if (entry.product.kitEntries.length > 0) {
        entry.product.kitEntries.map((kitEntry) => {
          if (kitEntry.product.baseOptions.length > 0) {
            const baseOptions = kitEntry.product.baseOptions[0];
            baseOptions.options.map((option, index) => {
              option.quantity = 0;
              option.lastQuantity = 0;
            });
          }
        });
      }
      return entry;
    });
    return newRelatedProducts;
  }

  async setProductSelected(productId, quantity, updateState, kitEntryCode) {
    const productEntries = this.state.productEntries.map((entry) => {
      if (entry.product.id === productId) {
        entry.product.quantity = quantity;
      }
      if (entry.product.kitEntries.length > 0) {
        entry.product.kitEntries.map((kitEntry) => {
          if (kitEntryCode.indexOf(entry.alias) >= 0) {
            kitEntry.kitEntryCodeAlias = kitEntryCode;
            if (kitEntry.product.baseOptions.length > 0) {
              const baseOptions = kitEntry.product.baseOptions[0];
              baseOptions.options.map((option) => {
                if (updateState === UpdateState.FINISH) {
                  option.lastQuantity = option.quantity;
                } else if (option.id === productId) {
                  if (updateState === UpdateState.CHANGE) {
                    option.quantity = quantity;
                  }
                  if (updateState === UpdateState.INIT) {
                    option.quantity = quantity;
                    option.lastQuantity = quantity;
                  }
                  if (updateState === UpdateState.CLEAR) {
                    option.quantity = option.lastQuantity;
                  }
                }
              });
            }
          }
        });
      }
      return entry;
    });
    this.setState({ productEntries });

    if (updateState === UpdateState.FINISH) {
      const productEntry = productEntries.find(
        (value) => value.alias === kitEntryCode,
      );

      const cart = this.state.cart ? this.state.cart[0] : null;
      try {
        this.setState({ loading: true });
        const urlParams = queryString.parse(this.props.location.search);

        const addProductResponse = await addProductsToCart(cart.id, [
          productEntry.product,
        ]);

        if (addProductResponse.error) {
          alert(addProductResponse.error[0].th_message);
          this.setState({ loading: false });
          return;
        }

        const isInstallment = urlParams.isInstallment === INSTALLMENT_TYPE.TRUE;

        const guid = sessionStorage.getItem('guid');

        !this.state.customerData
          ? await deleteCartItems(cart.id, [productEntry.id], isInstallment)
          : await deleteCartItemsPublic(
              cart.id,
              [productEntry.id],
              isInstallment,
              guid,
            );

        this.setState({
          productEntries: [],
        });
        await this.handleIntitCart();

        return;
      } catch (error) {
        console.log(error);
        alert(message.error.somethingWrong);
        window.location.reload();
        return;
      }
    }
  }

  renderProduct() {
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return <></>;
    }

    return this.state.productEntries.map((entry, index) => {
      if (entry.kitEntryCode || entry.isGiveAway) {
        return <></>;
      }

      const {
        totalPrice,
        quantity,
        amwayValue,
        kitProductTotalPriceInclTax,
        qualifiedSubscriptions,
      } = entry;
      const {
        id,
        sku,
        stock,
        prices,
        kitEntries,
        identifier,
        galleryImages,
        type,
      } = entry.product;

      const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
      const isBundletItem = kitEntries.length > 0;
      let customerFOA = sessionStorage.getItem('customerFOAData');
      let aboPrice = prices.find((value) => value.type === ABO_MEMBER);
      let foaPrice = prices.find((value) => value.type === FOA_MEMBER);
      aboPrice = parseInt(aboPrice.price);
      foaPrice = parseInt(foaPrice.price);
      const isSOPProduct = entry.hasSopEntries;
      const isRuleBased =
        isSOPProduct && type && type.toLowerCase() === RULE_BASED.toLowerCase();
      const parentQualification =
        qualifiedSubscriptions && qualifiedSubscriptions.length
          ? qualifiedSubscriptions[0]
          : null;
      let isSOPBeyondEntry = false;
      let isSOPBasicEntry = false;
      let sopTagMsg = '';
      let tagClr = '';
      let sopChildEntries = [];
      let totalSopChildQty = 0;

      if (
        isRuleBased &&
        kitProductTotalPriceInclTax &&
        kitProductTotalPriceInclTax.price &&
        parseInt(kitProductTotalPriceInclTax.price) > 0
      ) {
        aboPrice = kitProductTotalPriceInclTax.price;
        foaPrice = kitProductTotalPriceInclTax.price;
      }

      if (isRuleBased && identifier) {
        isSOPBeyondEntry = identifier
          .toLowerCase()
          .includes(SOP_BEYOND.toLowerCase());
        isSOPBasicEntry = identifier
          .toLowerCase()
          .includes(SOP_BASIC.toLowerCase());

        sopTagMsg = isSOPBeyondEntry
          ? 'SOP บียอนด์'
          : isSOPBasicEntry
          ? 'SOP เบสิค'
          : '';
        tagClr = isSOPBeyondEntry
          ? 'beyond-clr'
          : isSOPBasicEntry
          ? 'basic-clr'
          : '';
      } else if (entry.hasSopEntries) {
        sopTagMsg = 'SOP Flex';
        tagClr = 'flex-clr';
      }

      if (
        isRuleBased &&
        (isSOPBeyondEntry || isSOPBasicEntry) &&
        parentQualification
      ) {
        sopChildEntries = this.getSopChildEntries(parentQualification);
        totalSopChildQty = sopChildEntries.reduce((sum, entry) => {
          return sum + entry.quantity;
        }, 0);
      }

      const { accountType = '' } = this.state.cart[0]?.account;
      const isABOType = isCartABOType(accountType);
      const isABOMemberType = isCartABOMemberType(accountType);

      if (isBundletItem) {
        const props = {
          id: id,
          img: img,
          key: index,
          code: sku,
          alias: entry.alias,
          amwayValue: isABOType ? amwayValue : null,
          stock: stock,
          total: quantity,
          title: identifier,
          hasSopEntries: isSOPProduct,
          kitEntries: kitEntries,
          quantity: entry.quantity,
          price: isABOMemberType ? aboPrice : foaPrice,
          totalPrice: parseInt(totalPrice.price),
          productItemType: ProductItemType.CART,
          setProductSelected: this.setProductSelected,
        };
        return <BundleProductItem key={entry.alias} {...props} />;
      }

      const props = {
        img: img,
        code: sku,
        amwayValue: isABOType ? amwayValue : null,
        id: entry.id,
        stock: stock,
        price: isABOMemberType ? aboPrice : foaPrice,
        cartId: cart.id,
        title: identifier,
        hasSopEntries: isSOPProduct,
        total: entry.quantity,
        callback: this.onUpdateCart,
        productItemType: ProductItemType.CART,
        totalPrice: parseInt(totalPrice.price),
        sopTagMsg: sopTagMsg,
        tagClr: tagClr,
        sopChildEntries: sopChildEntries,
        totalSopChildQty: totalSopChildQty,
      };
      return (
        <div className="card-product-container mb-10" key={index}>
          <Product key={img} {...props} />
        </div>
      );
    });
  }

  getSopChildEntries(qualifiedSubscriptions) {
    const childEntries = [];
    const entries = [];
    const { cycleSequence, elementSequence, subscriptionIdentifier } =
      qualifiedSubscriptions;
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return entries;
    }
    cart.entries.forEach((entry, index) => {
      if (
        entry.hasSopEntries &&
        entry.kitEntryCode &&
        entry.qualifiedSubscriptions &&
        entry.qualifiedSubscriptions.length
      ) {
        const subscription = entry.qualifiedSubscriptions[0];
        const childCycleSequence = subscription.cycleSequence;
        const childElementSequence = subscription.elementSequence;
        const childSubscriptionIdentifier = subscription.subscriptionIdentifier;

        if (
          cycleSequence === childCycleSequence &&
          elementSequence === childElementSequence &&
          subscriptionIdentifier === childSubscriptionIdentifier
        ) {
          childEntries.push(entry);
        }
      }
    });

    if (childEntries && childEntries.length) {
      childEntries.forEach((entry, index) => {
        const { galleryImages, identifier } = entry.product;
        const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
        entries.push({ img, identifier, quantity: entry.quantity });
      });
    }

    return entries;
  }

  async onPromoClose() {
    localStorage.setItem('deleteIds', '[]');
    await this.fetchCartData();
  }

  isProductPromotion = (value, cart) => {
    const hasPremiumItemSet = cart.premiumItemSet.length > 0;
    if (!hasPremiumItemSet) {
      return false;
    }
    for (const premiumItem of cart.premiumItemSet) {
      const premiumItemSet = premiumItem;
      for (const item of premiumItemSet.premiumItems) {
        const checkPremium = value.product.id === item.id;
        if (checkPremium) {
          return true;
        }
      }
    }
    return false;
  };

  // cart and item level - promotion (pwp,gwp)

  renderGiftProduct() {
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return <></>;
    }

    const giftEntries = cart.entries.filter((value) => {
      if (
        value.isGiveAway &&
        parseInt(value.totalPrice.price) === 0 &&
        !value.kitEntryCode
      ) {
        return true;
      } else if (
        !value.isGiveAway &&
        parseInt(value.totalPrice.price) === 0 &&
        !value.kitEntryCode &&
        !this.isProductPromotion(value, cart)
      ) {
        return true;
      }
    });

    if (giftEntries.length === 0) {
      return <></>;
    } else {
      return giftEntries.map((entry, index) => {
        const { product, quantity } = entry;
        const { identifier, galleryImages } = product;
        const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';

        return (
          <div className="mb-10" key={index}>
            <div className="card-product-body-cart">
              <div className="gift-product-container">
                <div
                  className="gift-label-div card-product-title-text-cart font-black"
                  style={{ flex: '1 1 0', wordWrap: 'break-word' }}
                >
                  สินค้าแถมฟรี
                </div>
              </div>
              <div className="gift-info-container">
                <img className="gift-img" src={img} alt="gift image" />
                <div className="info-div">
                  <div className="gift-title-container">
                    <div
                      className="cart-product-info-cart font-black"
                      style={{ alignSelf: 'stretch', wordWrap: 'break-word' }}
                    >
                      {identifier}
                    </div>
                  </div>
                  <div
                    className="cart-product-info-cart font-black"
                    style={{ textAlign: 'right', wordWrap: 'break-word' }}
                  >{`x${quantity}`}</div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  renderPromotionProduct() {
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return <></>;
    }

    const quantityUpdateCallback = async (
      product,
      option,
      quantity,
      totalPrice,
      targetPrice,
    ) => {
      const tempEntries = this.state.cart[0].entries;
      if (!product) {
        this.fetchCartData();
      } else {
        this.fetchCartData();
        const targetIndex = tempEntries.findIndex((v) => v.id === product.id);
        tempEntries[targetIndex].quantity = quantity;
      }
      const tempCart = this.state.cart;
      tempCart[0].entries = tempEntries;
      tempCart[0].grandTotalPrice.price = totalPrice;
      this.setState({ cart: tempCart });
    };

    const promotionEntries = cart.entries.filter((value) => {
      return (
        !value.isGiveAway &&
        parseInt(value.totalPrice.price) > 0 &&
        !value.kitEntryCode &&
        this.isProductPromotion(value, cart)
      );
    });
    const promotionItems = [];
    promotionEntries.map((entry, index) => {
      let premiumItemsOption = null;
      let lastPremiumItemsId = null;
      let elligibleTargetQty = null;
      let hasElligibleTargetQty = null;
      if (this.state.cart[0].premiumItemSet.length !== 0) {
        for (const premiumItem of this.state.cart[0].premiumItemSet) {
          const premiumItems = premiumItem.premiumItems;
          const selectedPremiumItem = premiumItems.find(
            (value) => value.id === entry.product.id,
          );
          if (selectedPremiumItem) {
            premiumItemsOption = premiumItems;
            elligibleTargetQty = entry.latestQuantity;
            lastPremiumItemsId = selectedPremiumItem.id;
            hasElligibleTargetQty = premiumItem.elligibleTargetQty;
            const { identifier, galleryImages, id, prices } = entry.product;
            const { grandTotalPrice } = cart;
            const img =
              galleryImages.length > 0 ? galleryImages[0].image_url : '';
            const promotionItem = (
              <PromotionItem
                index={premiumItem}
                img={img}
                cart={cart}
                key={index}
                identifier={identifier}
                premiumItemsOption={premiumItemsOption}
                lastPremiumItemsId={lastPremiumItemsId}
                elligibleTargetQty={elligibleTargetQty}
                hasElligibleTargetQty={hasElligibleTargetQty}
                quantity={entry.quantity}
                totalPrice={parseInt(grandTotalPrice.price)}
                callback={quantityUpdateCallback}
                fetchCart={this.onPromoClose}
              />
            );
            promotionItems.push(promotionItem);
          }
        }
      }
    });
    // render deleted premiumItem from localStorage
    const deleteLocal = JSON.parse(localStorage.getItem('deleteIds'));
    if (deleteLocal.length !== 0) {
      deleteLocal.map((item, index) => {
        let premiumItemsOption = null;
        let lastPremiumItemsId = null;
        let elligibleTargetQty = null;
        let hasElligibleTargetQty = null;
        if (this.state.cart[0].premiumItemSet.length !== 0) {
          for (const premiumItem of this.state.cart[0].premiumItemSet) {
            const premiumItems = premiumItem.premiumItems;
            const selectedPremiumItem = premiumItems.find(
              (value) => value.id === item.product.id,
            );
            if (selectedPremiumItem) {
              premiumItemsOption = premiumItems;
              lastPremiumItemsId = selectedPremiumItem.id;
              hasElligibleTargetQty = premiumItem.elligibleTargetQty;
              const { identifier, galleryImages, id, prices } = item.product;
              const { grandTotalPrice } = cart;
              const img =
                galleryImages.length > 0 ? galleryImages[0].image_url : '';
              const deletePromotionItem = (
                <PromotionItem
                  index={premiumItem}
                  img={img}
                  cart={cart}
                  key={index}
                  identifier={identifier}
                  premiumItemsOption={premiumItemsOption}
                  lastPremiumItemsId={lastPremiumItemsId}
                  elligibleTargetQty={0}
                  hasElligibleTargetQty={hasElligibleTargetQty}
                  quantity={0}
                  totalPrice={parseInt(grandTotalPrice.price)}
                  prices={prices}
                  callback={quantityUpdateCallback}
                  fetchCart={this.onPromoClose}
                />
              );
              const hasPromottionItems = promotionItems.find(
                (v) => v.props.lastPremiumItemsId === lastPremiumItemsId,
              );
              if (!hasPromottionItems) {
                promotionItems.push(deletePromotionItem);
              }
            }
          }
        }
      });
    }

    const giftPromotionEntries = cart.entries.filter(
      (value) =>
        !value.isGiveAway &&
        parseInt(value.totalPrice.price) === 0 &&
        !value.kitEntryCode &&
        this.isProductPromotion(value, cart),
    );

    const giftPromotionItems = [];
    giftPromotionEntries.map((entry, index) => {
      let premiumItemsOption = null;
      let lastPremiumItemsId = null;
      let elligibleTargetQty = null;
      let hasElligibleTargetQty = null;
      if (this.state.cart[0].premiumItemSet.length !== 0) {
        for (const premiumItem of this.state.cart[0].premiumItemSet) {
          const premiumItems = premiumItem.premiumItems;
          const selectedPremiumItem = premiumItems.find(
            (value) => value.id === entry.product.id,
          );
          if (selectedPremiumItem) {
            premiumItemsOption = premiumItems;
            elligibleTargetQty = entry.latestQuantity;
            lastPremiumItemsId = selectedPremiumItem.id;
            hasElligibleTargetQty = premiumItem.elligibleTargetQty;
            const { identifier, galleryImages, id, prices } = entry.product;
            const { grandTotalPrice } = cart;
            const img =
              galleryImages.length > 0 ? galleryImages[0].image_url : '';
            const giftPromotionItem = (
              <PromotionItem
                index={premiumItem}
                img={img}
                cart={cart}
                key={index}
                identifier={identifier}
                premiumItemsOption={premiumItemsOption}
                lastPremiumItemsId={lastPremiumItemsId}
                elligibleTargetQty={elligibleTargetQty}
                hasElligibleTargetQty={hasElligibleTargetQty}
                quantity={entry.quantity}
                totalPrice={parseInt(grandTotalPrice.price)}
                callback={quantityUpdateCallback}
                fetchCart={this.onPromoClose}
              />
            );
            giftPromotionItems.push(giftPromotionItem);
          }
        }
      }
    });

    if (promotionItems.length === 0 && giftPromotionItems.length === 0) {
      return;
    }
    return (
      <>
        {promotionItems}
        {giftPromotionItems}
      </>
    );
  }

  displayFooterCoupon() {
    const cart = this.state.cart ? this.state.cart[0] : null;
    if (!this.state.cart) {
      return <></>;
    }

    return cart.appliedCoupon.map((coupon, index) => {
      return (
        <div key={index} className="applied-coupon-container font-small">
          {!coupon.redemptionCoupon ? (
            <img
              src="/images/promotion/applied-coupon-blue.svg"
              className="w-100"
            />
          ) : (
            <img
              src="/images/promotion/applied-coupon-red.svg"
              className="w-100"
            />
          )}
          {!coupon.redemptionCoupon ? (
            <div className="dark-blue applied-coupon-left">
              {coupon.name ? coupon.name : coupon.code}
            </div>
          ) : (
            <div className="dark-red applied-coupon-left">
              {coupon.name ? coupon.name : coupon.code}
            </div>
          )}
          {!coupon.redemptionCoupon ? (
            <div className="dark-blue applied-coupon-right-new">
              <div className="coupon-value-new">{`- ฿ ${coupon.value}`}</div>
              <div className="coupon-cross">
                <img
                  src="/images/promotion/icon-uncheck-blue.svg"
                  onClick={() => this.onCancelCoupon(coupon.code)}
                />
              </div>
            </div>
          ) : (
            <div className="dark-red applied-coupon-right-new">
              <div className="coupon-value-new">{`- ฿ ${coupon.value}`}</div>
              <div className="coupon-cross">
                <img
                  src="/images/promotion/icon-uncheck-red.svg"
                  onClick={() => this.onCancelCoupon(coupon.code)}
                />
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  async onCancelCoupon(code) {
    try {
      this.setState({ loading: true });
      const cart = this.state.cart ? this.state.cart[0] : null;
      await deleteCouponToCart(code, cart.id);
      await this.props.getCartData("FULL");
      await this.fetchCartData();
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  isCartEmpty() {
    return (
      !this.state.loading &&
      (!this.state.cart || this.state.cart[0].entries.length === 0)
    );
  }

  goToRemovePage() {
    this.setState({ loading: true });
    const urlParams = queryString.parse(this.props.location.search);
    if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
      if (!urlParams.cartId) {
        alert(message.error.cart);
      }
      let path= `${commonConstant.pathRemovePromotion}?isInstallment=TRUE&cartId=${urlParams.cartId}`;
      return this.props.history.push(path,{cart:this.state.cart});
    }
    this.props.history.push(commonConstant.pathRemovePromotion,{cart:this.state.cart});
  }

  goToAddressList = async () => {
    this.setState({ loading: true });
    const urlParams = queryString.parse(this.props.location.search);
    if (urlParams.isInstallment === INSTALLMENT_TYPE.TRUE) {
      if (!urlParams.cartId) {
        alert(message.error.cart);
      }
      let path=`${commonConstant.pathPromotionAddressList}?isInstallment=TRUE&cartId=${urlParams.cartId}`;
      return this.props.history.push(path);
    }
    const liffProfile = await liffGetProfile();
    const getCustomerProfile = await getCustomerFOA(liffProfile.userId);
    let redirectPath =
      getCustomerProfile.type === 'public'
        ? commonConstant.pathFOARegister
        : commonConstant.pathPromotionAddressList;
        
    this.props.history.push(redirectPath,{cart : this.state.cart});
  };

  getTotalPrice() {
    if (!this.state.cart) {
      return <></>;
    }
    const cart = this.state.cart[0];
    const totalPrice = cart.subTotal.filter(
      (value) => value.label === 'รวมค่าสินค้า',
    )[0];

    const price = getPriceFormat(totalPrice.value.price);
    return `฿ ${price}`;
  }

  getTotalDeliveryCost() {
    if (!this.state.cart) {
      return <></>;
    }
    const cart = this.state.cart[0];
    const totalDeliveryCost = cart.subTotal.filter(
      (value) => value.label === 'ค่าจัดส่ง',
    )[0];

    const price = getPriceFormat(totalDeliveryCost.value.price);
    return `฿ ${price}`;
  }

  getTotalDiscounts() {
    if (!this.state.cart) {
      return <></>;
    }
    const cart = this.state.cart[0];
    const totalOrderDiscounts = cart.subTotal.filter(
      (value) => value.label === 'ส่วนลดทั้งหมด',
    )[0];

    const price = getPriceFormat(totalOrderDiscounts.value.price);
    if (price == 0) {
      return `- ฿ ${price}`;
    } else {
      return `- ฿ ${price.substring(1)}`;
    }
  }

  getTotalAmwayPointForCartSummary(amwayValue) {
    if (!amwayValue) {
      return <></>;
    }

    const { businessVolume, pointValue } = amwayValue;
    if (businessVolume && pointValue) {
      return (
        <span>{`+ ${pointValue.toLocaleString()} / ${businessVolume.toLocaleString()}`}</span>
      );
    } else {
      return <></>;
    }
  }

  getTotalEntriesCount() {
    let totalQty = 0;
    if (
      this.state.cart &&
      this.state.productEntries &&
      this.state.productEntries.length
    ) {
      this.state.productEntries.map((entry, index) => {
        totalQty += entry.quantity;
      });
    }

    return totalQty ? totalQty.toString() : '';
  }

  getTotalAmplusPointForCartSummary(memberPoints = '') {
    if (!memberPoints) {
      return <></>;
    }
    return <span> {memberPoints.toLocaleString()}</span>;
  }

  async gotoShopping() {
    let msgText = 'โปรโมชั่น';
    const liffProfile = await liffGetProfile();
    const getCustomerProfile = await getCustomerFOA(liffProfile.userId);
    if (
      getCustomerProfile.type === 'foa' ||
      getCustomerProfile.type === 'public'
    ) {
      msgText = 'สินค้าแนะนำ';
    }

    liff
      .sendMessages([
        {
          type: 'text',
          text: msgText,
        },
      ])
      .then(() => {
        console.log('message sent', msgText);
        liff.closeWindow();
      })
      .catch((err) => {
        console.log('error', err);
        liff.closeWindow();
      });
  }

  renderEmptyCart() {
    return (
      <>
        {this.renderCartSelectionTabBar()}
        <div className="empty-cart-div">
          <div className="empty-cart-container">
            <img
              className="empty-cart-img"
              src="/images/promotion/cart_not_found.png"
            />
            <div className="empty-cart-msg-div">
              <div className="empty-cart-label">ตะกร้าว่าง</div>
              <div className="empty-cart-productmsg">
                คุณยังไม่มีสินค้าในตะกร้า
              </div>
            </div>
            <button className="btn-shop-now" onClick={this.gotoShopping}>
              ไปช็อปเลย
            </button>
          </div>
        </div>
      </>
    );
  }

  renderUserTypeIncentiveRow() {
    const cart = this.state.cart[0];
    const { accountType = '' } = cart.account;

    if (isCartABOMemberType(accountType)) {
      if (isCartABOType(accountType)) {
        return (
          <div className="flex-between">
            <div className="cart-summary-label">PV / BV</div>
            <div className="cart-summary-value">
              {this.getTotalAmwayPointForCartSummary(cart.amwayValue)}
            </div>
          </div>
        );
      } else if (isCartMemberType(accountType)) {
        return (
          <div className="flex-between">
            <div className="cart-summary-label">A POINT</div>
            <div className="cart-summary-value">
              {this.getTotalAmplusPointForCartSummary(cart.memberPoints)}
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  renderCartSummary() {
    if (!this.state.cart) {
      return <></>;
    }

    return (
      <div className="cart-summary flex-between">
        <div className="flex-between">
          <div className="cart-summary-label">
            ยอดรวมสินค้า ({this.getTotalEntriesCount()})
          </div>
          <div className="cart-summary-price">{this.getTotalPrice()}</div>
        </div>
        <div className="flex-between">
          <div className="cart-summary-label">ค่าจัดส่ง</div>
          <div className="cart-summary-value">
            {this.getTotalDeliveryCost()}
          </div>
        </div>
        <div className="flex-between">
          <div className="cart-summary-label">ส่วนลด</div>
          <div className="cart-summary-value">{this.getTotalDiscounts()}</div>
        </div>
        {this.renderUserTypeIncentiveRow()}
      </div>
    );
  }

  renderCartFooter() {
    let cartTotalPrice = '฿ 0';
    let cartTotalAPoint = '0';
    let cartTotalPointValue = 'PV 0';
    let cartTotalBusinessValue = 'BV 0';
    let isABOAccount = false;
    let isMemberAccount = false;

    const cart = this.state.cart ? this.state.cart[0] : null;

    if (cart) {
      if (cart.totalPrice && cart.totalPrice.price) {
        cartTotalPrice = `฿ ${getPriceFormat(cart.totalPrice.price)}`;
      }
      cartTotalAPoint = cart.memberPoints
        ? `${cart.memberPoints.toLocaleString()}`
        : cartTotalAPoint;
      if (
        cart.amwayValue &&
        cart.amwayValue.businessVolume &&
        cart.amwayValue.pointValue
      ) {
        const { businessVolume, pointValue } = cart.amwayValue;
        cartTotalPointValue = `PV ${pointValue.toLocaleString()}`;
        cartTotalBusinessValue = `BV ${businessVolume.toLocaleString()}`;
      }
      if (cart.account) {
        const { accountType = '' } = cart.account;
        isABOAccount = isCartABOType(accountType);
        isMemberAccount = isCartMemberType(accountType);
      }
    }

    return (
      <footer>
        <div
          className="cart-panel-footer"
          style={{ height: 'calc((100vh - 52px) * 0.10)' }}
        >
          <div className="select-product-footer font-larger">
            <div className="price-pvbv-container">
              <div className="product-panel-footer-price">
                <span>{cartTotalPrice}</span>
              </div>
              {isABOAccount && (
                <div className="pvbv-total">
                  <span>{`${cartTotalPointValue} / ${cartTotalBusinessValue}`}</span>
                </div>
              )}
              {isMemberAccount && (
                <div className="pvbv-total">
                  <span>{`A POINT ${cartTotalAPoint}`}</span>
                </div>
              )}
            </div>
            {cartTotalPrice === '฿ 0' ? (
              <span className="add-to-cart-disabled" disabled>
                <span className="add-to-cart-text-disabled">สั่งซื้อ</span>
              </span>
            ) : (
              <span
                className="add-to-cart-active"
                onClick={this.goToAddressList}
              >
                <span className="add-to-cart-text-enabled">สั่งซื้อ</span>
              </span>
            )}
          </div>
        </div>
      </footer>
    );
  }

  renderSopPromotionSelectionSection() {
    const { accountType = '' } =
      this.state.cart && this.state.cart.length && this.state.cart[0].account
        ? this.state.cart[0].account
        : {};
    const isABOMemberType = isCartABOMemberType(accountType);

    if (
      isABOMemberType &&
      this.state.selectedPaymentTab &&
      this.state.selectedPaymentTab === PAYMENT_OPTION.PIF
    ) {
      return (
        <div>
          <div className="sop-btn" onClick={() => this.onSopBtnClick()}>
            + เพิ่มสินค้า SOP
          </div>
          <div className="prm-btn" onClick={() => this.onPromotionBtnClick()}>
            + เพิ่มสินค้าโปรโมชัน
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  renderSelectedPaymentCart(paymentType) {
    console.log('Selected Payment Tab : ' + paymentType);
    if (paymentType) {
      this.setState({ lastSelectedPaymentTab: paymentType });
      this.updateSelectedPaymentTab(paymentType);
    }
  }

  updateSelectedPaymentTab(paymentType) {
    if (paymentType === PAYMENT_OPTION.ZIPP) {
      this.setState({
        cart: this.state.zippCart,
        isInstallment: true,
        selectedPaymentTab: PAYMENT_OPTION.ZIPP,
        productEntries: this.state.zippProductEntries,
      });
    } else if (paymentType === PAYMENT_OPTION.PIF) {
      this.setState({
        cart: this.state.pifCart,
        isInstallment: false,
        selectedPaymentTab: PAYMENT_OPTION.PIF,
        productEntries: this.state.pifProductEntries,
      });
    }
  }

  renderCartSelectionTabBar() {
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    let accountType = '';
    let isABOMemberType = false;
    let pifCartEntriesLength = 0;
    let zippCartEntriesLength = 0;

    if (this.state.cart && this.state.cart.length) {
      accountType =
        this.state.cart[0].account && this.state.cart[0].account.accountType
          ? this.state.cart[0].account.accountType
          : accountType;
      isABOMemberType = isCartABOMemberType(accountType);
      pifCartEntriesLength = this.state.pifProductEntries.length
        ? this.state.pifProductEntries.length
        : 0;
      zippCartEntriesLength = this.state.zippProductEntries.length
        ? this.state.zippProductEntries.length
        : 0;
    }

    if (isABOMemberType && (pifCartEntriesLength || zippCartEntriesLength)) {
      return (
        <div className="cartTabBar">
          <div
            className="cartPaymentSelectionTab"
            onClick={() => this.renderSelectedPaymentCart(PAYMENT_OPTION.PIF)}
          >
            <div
              className="cartPaymentSelectionText"
              style={
                this.state.selectedPaymentTab === PAYMENT_OPTION.PIF
                  ? { color: '#38539A' }
                  : { color: '#2C2C2C' }
              }
            >
              ชำระเต็มจำนวน ({pifCartEntriesLength})
            </div>
            <div
              className="selectedCartTabBar"
              style={
                this.state.selectedPaymentTab === PAYMENT_OPTION.ZIPP
                  ? { visibility: 'hidden' }
                  : {}
              }
            ></div>
          </div>
          <div
            className="cartPaymentSelectionTab"
            onClick={() => this.renderSelectedPaymentCart(PAYMENT_OPTION.ZIPP)}
          >
            <div
              className="cartPaymentSelectionText"
              style={
                this.state.selectedPaymentTab === PAYMENT_OPTION.ZIPP
                  ? { color: '#38539A' }
                  : { color: '#2C2C2C' }
              }
            >
              ผ่อนชำระ ({zippCartEntriesLength})
            </div>
            <div
              className="selectedCartTabBar"
              style={
                this.state.selectedPaymentTab === PAYMENT_OPTION.PIF
                  ? { visibility: 'hidden' }
                  : {}
              }
            ></div>
          </div>
        </div>
      );
    } else if (!pifCartEntriesLength || !zippCartEntriesLength) {
      return <></>;
    } else {
      return (
        <div className="customer-pay-select-div">
          <div className="pay-div">
            <div className="pif-text">ตะกร้า ({pifCartEntriesLength})</div>
          </div>
        </div>
      );
    }
  }

  renderPromotionList() {
    if (
      this.state.cart &&
      this.state.promotionList &&
      this.state.promotionList.length
    ) {
      return this.state.promotionList.map((promotion, index) => {
        const imgUrl = promotion.cardImageUrl ? promotion.cardImageUrl : '';
        const prmName = promotion.name ? promotion.name : '';
        return (
          <div className="card-prm-body" key={index}>
            <div className="flex">
              <div className="product-image-container-cart">
                <div className="card-product-img-promotion mb-10">
                  <img src={imgUrl} alt="product image" />
                </div>
              </div>
              <div>
                <div className="card-product-title mb-10">
                  <div className="font-black card-product-title-text">
                    {prmName}
                  </div>
                </div>
              </div>
            </div>
            <div className="price-selection-panel flex">
              <div>
                <div className="font-black footer-price-bold"></div>
                <div>
                  {this.props.amwayValue && (
                    <div className="amway-value-dark"></div>
                  )}
                </div>
              </div>

              <div className="select-prmlist-btn font-larger">
                <div className="text-center">
                  <button
                    className={`away-button active`}
                    onClick={() => this.navigatePromotion(promotion)}
                  >
                    เลือก
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <></>;
    }
  }

  renderCart() {
    let chkCustomer = JSON.parse(sessionStorage.getItem('customer'));
    if(chkCustomer){
      if(chkCustomer.notCommerce == '1'){
        localStorage.setItem('deleteIds', '[]');
        liff.closeWindow();
        return <section>{this.renderEmptyCart()}</section>;
      }
    }

    if (this.isCartEmpty()) {
      localStorage.setItem('deleteIds', '[]');
      return <section>{this.renderEmptyCart()}</section>;
    }

    return (
      <div>
        {this.renderCartSelectionTabBar()}
        <div
          className="cart-wrapper scroll-auto cart-panel-container"
          style={{ height: 'calc((100vh - 52px) * 0.90)' }}
        >
          <div className="pd-15">
            <div className="flex-between font-small pb-10">
              <span className="product-list-label">รายการสินค้า</span>
              <span
                className="link-text underline font-stock"
                onClick={this.goToRemovePage}
              >
                แก้ไข
              </span>
            </div>
            {this.renderProduct()}
            {this.renderPromotionProduct()}
            {this.renderGiftProduct()}
          </div>
          {!this.state.customerFOA && (
            <div className="cart-coupon-container">
              <div className="pd-16">
                <div className="flex-between mb-16">
                  <div className="coupon-label">
                    <img
                      style={{ width: 15, marginRight: 15 }}
                      src="/images/promotion/icon_Discount.png"
                    />
                    สิทธิ์ส่วนลด และโปรโมชันโค้ด
                  </div>
                  <div onClick={this.onOpenLiff} className="flex">
                    <div className="coupon-link">{`เลือกคูปอง`}</div>
                    <span className="link-text-icon">{'>'}</span>
                  </div>
                </div>
                <InputCoupon
                  callback={this.fetchCartData}
                  cartId={this.state.cart ? this.state.cart[0].id : null}
                />
                {this.displayFooterCoupon()}
              </div>
            </div>
          )}
          <Liff
            title={'เลือกคูปอง'}
            onClose={this.onCloseLiff}
            isOpen={this.state.isLiffopen}
          >
            <CouponPanel
              cartId={this.state.cart ? this.state.cart[0].id : null}
              cartData={this.state.cart ? this.state.cart[0] : {}}
              isOpen={this.state.isLiffopen}
              onClose={this.onCloseLiff}
            />
          </Liff>
          <LiffPromotionList
            title={'เพิ่มสินค้าโปรโมชัน'}
            onClose={this.onClosePromotionLiff}
            isOpen={this.state.isLiffPromotionOpen}
            bgColor={'#f4f4f4'}
          >
            <div
              className="content scroll-auto"
              style={{ height: 'calc(85vh - 50px)' }}
            >
              {this.renderPromotionList()}
            </div>
          </LiffPromotionList>
          {this.renderCartSummary()}
          {this.renderSopPromotionSelectionSection()}
        </div>
        {this.renderCartFooter()}
      </div>
    );
  }

  render() {
    return (
      <section>
        <LoadingOverlay
          text="Loading"
          spinner
          active={this.state.loading}
          styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />
        {this.renderCart()}
        {this.state.isSopLiffOpen && (
          <Liff
            title={'เพิ่มสินค้า SOP'}
            onClose={() => this.onCloseSopLiff()}
            isOpen={this.state.isSopLiffOpen}
          >
            <div className="cart-profile-list">
              <SOPProfileList notFromCartPage={false} />
            </div>
          </Liff>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.cartApi.data,
    loading: state.cartApi.loading,
    error: state.cartApi.error,
  };
};

const mapDispatchToProps = (dispatch)=> {
  return {
    getCartData: (fields) => dispatch(getCartData(fields)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPromotion);

import { CHALLENGE_MAP, CHALLENGE_MAP_PULLUP } from "../../challengeConstants";
import teamPoints from "./TeamPoints";
import buddyPoints from './BuddyPoints';

let points = false ? teamPoints : buddyPoints;

export const calculateUserPosition = (userSteps) => {

  const stepsToAdd = parseInt(userSteps);
  if (isNaN(stepsToAdd)) return 0;

  let targetPosition = 0;
  let minStepDifference = Infinity;

  // Iterate over points to find the nearest valid target position
  points.forEach((point, index) => {
    if (userSteps >= point.totalStep) {
      targetPosition = index;
    } else {
      return;
    }
  });
  return targetPosition;
};


export const fadeOutMainPoints = (targetPosition = 0) => {
  let fadeOutList = [];

  [...Array(targetPosition + 1)].forEach((_, i) => {
    if (points[i].type === "main") {
      fadeOutList.push(i);
    }
  });

  return fadeOutList;
};

export const getStepsPercentage = (steps, totalSteps) => Math.round(steps * 100 / totalSteps);
export const getStepsActiveIndex = (steps) => Math.min(CHALLENGE_MAP_PULLUP.TOTAL_MILESTONES, Math.floor(steps * 7 / CHALLENGE_MAP_PULLUP.TOTAL_TARGET));

import React, { useState, useRef, useEffect } from 'react';
import './ChallengeDetailSlider.scss';
import { CHALLENGE_MAP_SLIDER } from '../challengeConstants';

const ChallengeDetailSlider = ({ minHeight = CHALLENGE_MAP_SLIDER.sliderMinHeight,
    maxHeight = CHALLENGE_MAP_SLIDER.sliderMaxHeight,
    defaultHeight = CHALLENGE_MAP_SLIDER.sliderDefaultHeight,
    showGrab = true,
    styleObj,
    children,
    autoHeight = false,
    isAutoMaxHeight = false,
    onDrag }) => {

    const sliderRef = useRef(null);
    const [height, setHeight] = useState(defaultHeight)

    const handleWindowToggle = () => {
        const sliderContainerHeight = height === minHeight ? (autoHeight ? CHALLENGE_MAP_SLIDER.sliderAutoHeight : maxHeight) : minHeight;
        setHeight(sliderContainerHeight);
        onDrag && onDrag(sliderContainerHeight, sliderRef.current);
    }

    const containerStyle = {
        ...styleObj,
        height: `${height}${height === CHALLENGE_MAP_SLIDER.sliderAutoHeight ? "" : "px"}`,
    };
    return (
        <div
            className="challenge-slider-container"
            style={containerStyle}
            ref={sliderRef}
        >
            {showGrab && (
                <div
                    className="challenge-slider-container-grab"
                    onClick={handleWindowToggle}
                >
                    <img src="/images/challenge/challenge-detail-grabber.svg" />
                </div>
            )}
            <div className='challenge-slider-container-content'>
                {children}
            </div>
        </div>
    )
}

export default ChallengeDetailSlider;
import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";
import { formatNumberWithCommas } from "../../../../utill.func";

const StepsBadgeDetails = ({ disabled, currentBadge }) => {

    const renderDisabledStepsBadgeDetails = () => {
        return (
            <p>
                {CHALLENGE.badges.disabledHeading}<br />
                {CHALLENGE.badges.complete} {formatNumberWithCommas(currentBadge?.maxValueOfBadge)} {CHALLENGE.badges.stepsUnit}<br />
                {CHALLENGE.badges.disabledSubHeading}
            </p>
        )
    }

    const renderActiveStepsBadges = () => {
        return (
            <p>
                {CHALLENGE.badges.achievedBadgeHeading}<br />
                {CHALLENGE.badges.accumulating} {formatNumberWithCommas(currentBadge?.maxValueOfBadge)} {CHALLENGE.badges.stepsUnit}
            </p>
        )
    }

    return (
        disabled ? renderDisabledStepsBadgeDetails() : renderActiveStepsBadges()
    )
}

export default StepsBadgeDetails;
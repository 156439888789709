import { EMPTY_PRODUCTS_REQUEST, GET_FOOD_DETAIL, GET_CATEGORIES, GET_FOOD_CATEGORIES, GET_FOOD_PRODUCTS, GET_HISTORY_KEYWORDS, GET_SEARCH_PRODUCTS, GET_RECOMMENDED_PRODUCTS, RESET_FOOD_PRODUCTS } from '../actions/types';
const INITIAL_STATE = {
  foodCategories: [],
  food: { data: [], recordsTotal: 0, categoryId: 'all' },
  supplement: { data: [], recordsTotal: 0, categoryId: '' },
  searchedProducts: { data: [], recordsTotal: 0 },
  categories: [],
  historyKeywords: [],
  foodCalorieList: [],
  recommendedProducts: { data: [], recordsTotal: 0 },
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_FOOD_PRODUCTS:
      return { ...state, food: { data: [], recordsTotal: 0, categoryId: 'all' }, supplement: { data: [], recordsTotal: 0, categoryId: '' } };
    case GET_FOOD_CATEGORIES:
      return { ...state, foodCategories: action.payload };
    case GET_FOOD_PRODUCTS:
      return {
        ...state, [action.productType]: {
          data: [
            ...action.categoryId === state.categoryId ? state[action.productType].data : [],
            ...action.payload.data,
          ],
          recordsTotal: action.payload.recordsTotal,
        },
        categoryId: action.categoryId,
      };
    case EMPTY_PRODUCTS_REQUEST:
      return { ...state, searchedProducts: { data: [], recordsTotal: 0 } };
    case GET_SEARCH_PRODUCTS:
      return { ...state, searchedProducts: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_HISTORY_KEYWORDS:
      return { ...state, historyKeywords: action.payload };
    case GET_FOOD_DETAIL:
      return { ...state, foodCalorieList: action.payload };
    case GET_RECOMMENDED_PRODUCTS:
      return {
        ...state, recommendedProducts: {
          data: [...state.recommendedProducts.data, ...action.payload.data],
          recordsTotal: action.payload.recordsTotal,
        },
      };
    default:
      return state;
  }
}

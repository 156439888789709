import React from 'react';
import { getPhotoName } from '../../challengeConstants';
import MyPictureCardDetail from '../myPictureCardDetail/MyPictureCardDetail';
import MyPictureCardIdle from '../myPictureCardIdle/MyPictureCardIdle';
import MyDashboardStepsLoader from '../../../components/challenge/challengeMyDashboard/myDashboardLoader/MyDashboardStepsLoader';

export default function MyPictureCard({
  photos,
  photosLoading,
  photoPositionObj,
  handleImageUpload,
  datetimeThai,
  isAfterImageUpload
}) {
  return (
    <div className="picture-after-before-row">
      {photoPositionObj?.beforeAndAfter.map((ba, id) => (
        <React.Fragment key={`${id}_${getPhotoName(ba.type, ba.position)}`}>
          {photos[getPhotoName(ba.type, ba.position)] ? (
            photosLoading[getPhotoName(ba.type, ba.position)] ? (
              <MyDashboardStepsLoader className="picture-col-details picture-col-img" />
            ) : (
              <MyPictureCardDetail
                photos={photos}
                photoPositionObj={photoPositionObj}
                beforeOrAfter={ba}
                handleImageUpload={handleImageUpload}
                datetimeThai={datetimeThai}
                photosLoading={photosLoading}
                isAfterImageUpload={isAfterImageUpload}
              />
            )
          ) : (
            <MyPictureCardIdle
              photoPositionObj={photoPositionObj}
              beforeOrAfter={ba}
              handleImageUpload={handleImageUpload}
              isAfterImageUpload={isAfterImageUpload}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import {
  BADGE_MAPPING,
  BUDDY_ME_BADGE_MAPPING,
  BADGES_MILESTONE,
  BUDDY_ME_BADGES_MILESTONE,
  CHALLENGE_UNLOCKED,
  CHALLENGE,
} from '../../../common/challengeConstants';
import { ChallengeShare } from '../../../common';
import { isValidDateTime, thaiDateWOTime } from '../../../utill.func';
import './ChallengeUnlocked.scss';

export default function ChallengeUnlocked({ badgeNumber, onClose, wrapperClass }) {
  const { teamData } = useSelector((state) => state.challengeTeamDashboard);

  const CHALLENGE_TYPE = teamData.challengeType === 'Team' ? CHALLENGE.type.TEAM : CHALLENGE.type.BUDDY_ME;

  const BADGE_IMAGE_MAPPING = {
    BUDDY_ME: {
      MILESTONE: BUDDY_ME_BADGES_MILESTONE,
      BADGE_MAPPING: BUDDY_ME_BADGE_MAPPING,
      GREETING_FONT_CLASS: '--th',
    },
    TEAM: {
      MILESTONE: BADGES_MILESTONE,
      BADGE_MAPPING: BADGE_MAPPING,
      GREETING_FONT_CLASS: '--en',
    }
  };


  const unlock_date = teamData?.stepsBadgeData?.[BADGE_IMAGE_MAPPING[CHALLENGE_TYPE].MILESTONE[`badge${badgeNumber}`]]?.currentUserData?.receivedAt;
  const badgeImgPath = `/images/challenge/badges/steps-${BADGE_IMAGE_MAPPING[CHALLENGE_TYPE].MILESTONE[`badge${badgeNumber}`]}-badge-outlined@5x.png`;

  const handleModalClick = (event) => event.target === event.currentTarget && onClose();

  return (
    <div className={`challenge-unlock-modal ${wrapperClass}`}>
      <div className="line-modal">
        <div className="modal fade show badge-step-modal" id="badgeStep" tabIndex="-1" aria-hidden="true" onClick={handleModalClick}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <img className="challenge-unlock-firework" src="/images/challenge/Firework x2.gif" />
              <div className="modal-body">
                <div className="modal-title">
                  {CHALLENGE_UNLOCKED.MODAL_TITLE}
                </div>
                <div className="modal-subtitle">
                  <span>{CHALLENGE_UNLOCKED.MODAL_GREETING_TEXT}</span>
                  <span className={`ml-2 modal-subtitle-greeting ${BADGE_IMAGE_MAPPING[CHALLENGE_TYPE].GREETING_FONT_CLASS}`}>{BADGE_IMAGE_MAPPING[CHALLENGE_TYPE].BADGE_MAPPING[badgeNumber - 1]?.greetingMessage}</span>
                </div>
                <div className="badge-img-wrapper">
                  <img src="/images/challenge/badges/bg-dark-badge@5x.png" alt="" className="badge-img-bg" />
                  <img src={badgeImgPath} alt="" className="badge-img" />
                </div>
                <ChallengeShare imgPath={`${process.env.REACT_APP_REDIRECT_URL}${badgeImgPath}`}
                  message={`${CHALLENGE_UNLOCKED.UNLOCK_MESSAGE_DATE_PREFIX} ${isValidDateTime(unlock_date) && thaiDateWOTime(new Date(unlock_date), 'dd MMM yy')}`} />
                <button className="btn btn-light-green btn-full" onClick={onClose}>
                  {CHALLENGE_UNLOCKED.MODAL_SUBMIT_BTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

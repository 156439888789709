import React from 'react';
import './ChallengeButton.scss'

const ChallengeButton = (props) => {
    const {successBtn, detailBtn, secBtn, btnTitle, onBtnClick} = props;
    const success = successBtn ? 'successBtn' :'';
    const detail = detailBtn ? 'detailBtn' :'';
    const secondary = secBtn ? 'secondaryBtn':'';
    return (
        <div className={`challenge-btn-container ${success} ${detail} ${secondary}`} onClick={onBtnClick}>
            <div className='challenge-btn-container-title'>{btnTitle}</div>
        </div>
    )
}

export default ChallengeButton
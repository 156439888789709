import React, { useEffect, useRef, useState } from 'react';
import './MyImageCard.scss';
import {
  BEFORE_TYPE,
  PERSONAL_MEMBER_PICTURE,
  AFTER_TYPE,
  PHOTOS_LIST,
  getPhotoName,
  IMAGE_UPLOAD_LOADING_STATE,
} from '../../challengeConstants';
import commonConstant from '../../commonConstant';
import { useHistory } from 'react-router-dom';
import { CommonAndroidWrapper } from '../commonAndroidWrapper/CommonAndroidWrapper';
import { useSelector } from 'react-redux';
import ImageCard from './ImageCard';
import MyDashboardStepsLoader from '../../../components/challenge/challengeMyDashboard/myDashboardLoader/MyDashboardStepsLoader';

const allowImageUpload = (validUser, validPeriod) =>
  validUser ? validPeriod : validUser;

const loadGreenOrGreyIdleImages = (validPeriod, photo) =>
  validPeriod ? photo : `${photo}_grey`;

export default function MyImageCard({
  eventId,
  redirectionEventIdQuery,
  partyId,
  userId,
  aboId,
  isChallengePeriod,
  isUserValid,
  isAfterImageUpload
}) {
  const history = useHistory();
  const imageRef = useRef(null);
  const androidFeatureRef = useRef(null);
  const { images } = useSelector((state) => state.challengeImageUpload);

  const [photos, setPhotos] = useState({
    before_left: null,
    before_center: null,
    before_right: null,
    after_left: null,
    after_center: null,
    after_right: null,
  });
  const [photosLoading, setPhotosLoading] = useState(
    IMAGE_UPLOAD_LOADING_STATE,
  );
  useEffect(() => {
    const onMountHandler = androidFeatureRef?.current?.onMountHandler;
    if (onMountHandler) 
      onMountHandler(images);
  }, [images]);

  const handleBeforeAfterPictureRedirection = () => {
    history.push(
      `${commonConstant.pathMyPictures}?redirectionEventIdQuery=${redirectionEventIdQuery}&eventId=${eventId}&partyId=${partyId}&userId=${userId}&aboId=${aboId}`,
    );
  };

  return (
    <div className="before-after-container">
      <CommonAndroidWrapper
        eventId={eventId}
        partyId={partyId}
        aboId={aboId}
        userId={userId}
        redirectionEventIdQuery={redirectionEventIdQuery}
        ref={androidFeatureRef}
        imageRef={imageRef}
        photos={photos}
        setPhotos={setPhotos}
        setPhotosLoading={setPhotosLoading}
        componentInstance={'PICTURE_COMPONENT'}
      />
      <div className="before-after-header">
        <div className="before-after-header-left">
          <img
            src="/images/challenge/myupload/body_weigh.svg"
            alt="body-weigh-logo"
          />
          <span>
            {PERSONAL_MEMBER_PICTURE.BODY_PICTURE_BEFORE_AND_AFTER_TITLE}
          </span>
        </div>
        {allowImageUpload(isUserValid, isChallengePeriod) ? (
          <div className="before-after-header-right">
            <button className="btn btn-outline-none">
              <img
                src="/images/challenge/myupload/edit_icon.svg"
                alt="edit_icon"
                onClick={handleBeforeAfterPictureRedirection}
              />
            </button>
          </div>
        ) : null}
      </div>
      <div className="before-after-body">
        {[BEFORE_TYPE, AFTER_TYPE].map((type) => (
          <div className="before-after-row" key={type}>
            <div className="before-after-heading">
              <div className="before-after-title">
                {type === BEFORE_TYPE
                  ? PERSONAL_MEMBER_PICTURE.BEFORE_THAI
                  : PERSONAL_MEMBER_PICTURE.AFTER_THAI}
              </div>
              <div className="before-after-subtitle">
                {type === BEFORE_TYPE
                  ? PERSONAL_MEMBER_PICTURE.BEFORE_SUB_THAI
                  : PERSONAL_MEMBER_PICTURE.AFTER_SUB_THAI}
              </div>
            </div>
            <div className="before-after-image-container">
              {PHOTOS_LIST.map((photo, idx) => (
                <React.Fragment key={`${idx}_${getPhotoName(type, photo)}`}>
                  {photosLoading[getPhotoName(type, photo)] ? (
                    <MyDashboardStepsLoader className="before-after-image-single before-after-image-uploaded" />
                  ) : (
                    <ImageCard
                      type={type}
                      photo={photo}
                      handleImageUpload={
                        androidFeatureRef?.current?.handleImageUpload
                      }
                      photos={photos}
                      allowImageUpload={allowImageUpload}
                      loadGreenOrGreyIdleImages={loadGreenOrGreyIdleImages}
                      isChallengePeriod={isChallengePeriod}
                      isUserValid={isUserValid}
                      isAfterImageUpload={isAfterImageUpload}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ChallengeTeamdashboardStatus,
  CHALLENGE_STEPS_FOOD_KEY_MAPPING,
  CHALLENGE_END_STATUS,
  CHALLENGE,
  rectBadgeClassname,
} from '../../../common/challengeConstants';
import './ChallengeMyDashboard.scss';
import {
  ChallengeMyBmiData,
  ChallengeMyCurrentDayBadge,
  ChallengeMyDashboardHeader,
  ChallengeMyProfile,
  ChallengeMyStepsCard,
  ChallengeMyCompositionChangeCard,
  ChallengeMyActivityReward,
  ChallengeMyReward,
  ChallengeMyCompositionCard,
  ChallengeMyBadgesList,
  ChallengeMyBadgesCards,
  MyDashboardStepsLoader,
  MyDashboardMissionLoader,
  ChallengeBuddyMyBadgesCards,
} from './index';
import MyImageCard from '../../../common/challenge/myImageCard/MyImageCard';
import {
  fetchChallengeDetail,
  fetchChallengeTeamDetail,
  fetchUserDetailsData,
} from '../../../actions';
import {
  calculateDaysLeft,
  getDataFromSession,
  getParamsString,
  checkAfterWeightPeriod,
  isEmptyObject,
} from '../../../utill.func';
import queryString from 'query-string';
import { commonConstant } from '../../../common';
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import { DashboardFooter } from '../challengeTeamDashboard';

const ChallengeMyDashboard = () => {
  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const { teamData, userDetail, loading } = useSelector(
    (state) => state.challengeTeamDashboard,
  );
  const selectedChallenge = useSelector(
    (state) => state.challengeListObj?.selectedChallenge.challengeDetails,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const partyId = params['partyId'] || '';
  const eventId = params['eventId'];
  const scrollTo = params['scrollTo'] || null;
  const customer = getDataFromSession('customer');
  const challengeId = params['challengeId'] || teamData?.eventId;
  const [badgesData, setBadgesData] = useState([]);
  const challengeStatus = teamData?.teamDashboardStatus?.status;
  const challengeHeaderDate = teamData?.teamDashboardStatus?.header;
  const challengeRewardDate = teamData?.rewardEndDate;
  const rewardSection = useRef(null);
  const imageCardSectionRef = useRef(null);
  const headerBadge = teamData?.teamDashboardStatus?.titleBadge;
  const [otherBadgesData, setOtherBadgesData] = useState([]);
  const afterWeightOutPeriod = checkAfterWeightPeriod(challengeStatus);
  const [syncLoader, setSyncLoader] = useState(false);
  const [notiLoader, setNotiLoader] = useState(false);
  const [eventType, setEventType] = useState('');

  sessionStorage.setItem('badgeDetail', JSON.stringify('my-dashboard'));
  sessionStorage.setItem('badgeScreen', JSON.stringify('my-dashboard'));
  const challengeData = getDataFromSession('challengeData');

  const scrollToRewardSection = (rewardRef) => {
    rewardRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToImageCardSection = (ref) => {
    if (scrollTo === 'imageCardSection') {
      const callback = ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  scrollToImageCardSection(imageCardSectionRef);

  useEffect(() => {
    setSyncLoader(teamData?.teamName);
  }, []);

  useEffect(() => {
    let customerData = customer?.partyId == undefined;
    setNotiLoader(customerData);
  }, [customer?.partyId]);

  const fetchDetail = async (id) => {
    try {
      await dispatch(fetchChallengeDetail(id));
    } catch (error) {
      throw error;
    } finally {
      setSyncLoader(true);
    }
  };

  useEffect(() => {
    if (!selectedChallenge || isEmptyObject(selectedChallenge)) {
      fetchDetail(challengeId);
    }
  }, []);

  const getChallengeTeamDashboardData = async () => {
    try {
      await dispatch(
        fetchChallengeTeamDetail({
          amwayNumber: customer?.amwayNumber,
          partyId: customer?.partyId,
          challengeId,
        }),
      );
    } catch (error) {
      throw error;
    } finally {
      setSyncLoader(true);
    }
  };

  useEffect(() => {
    let intervalId;
    // calling api after every 2.5 minutes to sync data from challenge api
    getChallengeTeamDashboardData();
    intervalId = setInterval(
      getChallengeTeamDashboardData,
      CHALLENGE.teamDashboard.refreshInterval,
    );
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setSyncLoader(false);
      }
    };
  }, [customer?.partyId]);

  useEffect(() => {
    const didMount = () => {
      if (teamData?.challengePartyIds && userDetail?.length == 0) {
        getChallengeUserProfileData();
      }
    };
    didMount();
  }, [teamData?.challengePartyIds]);

  const getChallengeUserProfileData = async () => {
    if (teamData?.challengePartyIds) {
      await dispatch(fetchUserDetailsData(teamData?.challengePartyIds));
    }
  };
  useEffect(() => {
    const filterUserData = teamData?.users?.find(
      (user) => user?.partyId === partyId,
    );
    filterUserData && setUserData(filterUserData);
  }, [teamData]);

  const _getUserData = useCallback(() => {
    const stepsFoodKeys = Object.keys(CHALLENGE_STEPS_FOOD_KEY_MAPPING);
    const missionData = stepsFoodKeys.reduce((acc, key) => {
      acc[key] = teamData?.missionUserData?.[key]?.userData?.find(
        (user) => user?.partyId === partyId,
      );
      return acc;
    }, {});
    setBadgesData(missionData);
    const otherBadgesData = stepsFoodKeys.reduce((acc, key) => {
      acc[key] = teamData?.otherBadgeData?.[key]?.allUsersData?.find(
        (user) => user?.partyId === partyId,
      );
      return acc;
    }, {});
    setOtherBadgesData(otherBadgesData);
  }, [teamData, partyId]);

  useEffect(() => {
    _getUserData();
  }, [_getUserData]);

  useEffect(() => {
    const challengeData = getDataFromSession('challengeData') || {};

    if (!challengeData.eventType) {
      const newEventType = params['eventType'] || '';
      challengeData.eventType = newEventType;
      sessionStorage.setItem("challengeData", JSON.stringify(challengeData));
      setEventType(newEventType);
    } else {
      setEventType(challengeData?.eventType);
    }
  }, []);

  const handleLoggedInBadgeRedirection = customer?.partyId === partyId;

  const handleBadgeScreenRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      partyId: partyId,
    };
    if (handleLoggedInBadgeRedirection) {
      return history.push(
        `${commonConstant.pathChallengeBadgeScreen}?${getParamsString(params)}`,
      );
    }
  };

  const handleMissionPageRedirection = () => {
    const params = {
      challengeId: challengeId,
    };
    history.push(
      `${commonConstant.pathMissionDetail}?${getParamsString(params)}`,
    );
  };

  const handleTeamDashboardRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      partyId: partyId,
    };
    return history.push(
      `${commonConstant.pathChallengeTeamDashboard}?${getParamsString(params)}`,
    );
  };

  const handleHomeRedirection = () => {
    return history.push(commonConstant.pathHealthnWellness);
  };

  const handleBadgeDetailRedirection = (selectedBadge, type) => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      partyId: partyId,
      badge: selectedBadge,
      type: type,
    };
    if (handleLoggedInBadgeRedirection) {
      return history.push(
        `${commonConstant.pathChallengeBadgeDetail}?${getParamsString(params)}`,
      );
    }
  };

  const handleMapRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
    };
    history.push(
      `${commonConstant.pathChallengeMap}?${getParamsString(params)}`,
    );
  };

  // TODO: WILL BE REMOVED IN NEXT BUILD AFTER TESTING
  console.log("window.location: ", window.location.href)
  return (
    <div className="my-dashboard-container">
      <ChallengeMyDashboardHeader
        challengeDate={challengeHeaderDate}
        badgeClassname={rectBadgeClassname[challengeStatus]}
        handleTeamDashboardRedirection={() => handleTeamDashboardRedirection()}
        handleHomeRedirection={() => handleHomeRedirection()}
        showRectBadge={true}
      />
      {(loading || notiLoader) && !syncLoader ? (
        <MyDashboardStepsLoader />
      ) : (
        <div className="profile-img-container">
          <img
            src={
              challengeData?.myDashboardBg
                ? challengeData?.myDashboardBg
                : selectedChallenge?.teamDashboardImage
            }
            alt=""
            className="profile-bg"
          />
          <ChallengeMyCurrentDayBadge
            teamData={teamData || {}}
            headerBadge={teamData?.challengeDay !== '-' && headerBadge}
            teamDashboardStatus={challengeStatus}
            isNewAbo={userData?.isNewAbo}
            isNewMember={userData?.isNewMember}
          />
          <ChallengeMyProfile
            userData={userData}
            teamDashboardStatus={challengeStatus}
            challengeUserData={userDetail}
            eventType={eventType !== CHALLENGE.type.ME.toLowerCase()}
          />
        </div>
      )}
      {(loading || notiLoader) && !syncLoader ? (
        <MyDashboardStepsLoader />
      ) : (
        <ChallengeMyActivityReward
          rewardButtonClick={() => scrollToRewardSection(rewardSection)}
          rewardDate={challengeRewardDate ? `${calculateDaysLeft(challengeRewardDate)}` : `${calculateDaysLeft(selectedChallenge?.completeDate)}`}
        />
      )}

      {(loading || notiLoader) && !syncLoader ? (
        <MyDashboardStepsLoader />
      ) : (
        <ChallengeMyStepsCard
          userData={userData}
          teamDashboardStatus={challengeStatus}
        />
      )}
      {(loading || notiLoader) && !syncLoader ? (
        <MyDashboardStepsLoader />
      ) : (
        <ChallengeMyBmiData
          bmiHeading={
            userData?.hasUserWeighOut
              ? CHALLENGE.teamDashboard.bmiweighOutHeading
              : CHALLENGE.teamDashboard.bmiHeading
          }
          bmiValue={
            userData?.hasUserWeighOut
              ? userData.weightOutUserData?.bmi
              : userData.registerUserData?.bmi
          }
          teamDashboardStatus={challengeStatus}
        />
      )}
      {(loading || notiLoader) && !syncLoader ? (
        <MyDashboardStepsLoader />
      ) : (
        <ChallengeMyBadgesList
          totalSteps={badgesData?.steps?.data?.value}
          activeBadges={badgesData?.steps?.data?.badges_count}
          partyId={partyId}
          redirectToBadgeScreen={handleBadgeScreenRedirection}
          redirectToBadgeDetail={handleBadgeDetailRedirection}
        />
      )}

      {(loading || notiLoader) && !syncLoader ? (
        <MyDashboardMissionLoader />
      ) : (
        <ChallengeBuddyMyBadgesCards
          badgesData={badgesData}
          otherBadgesData={otherBadgesData}
          partyId={partyId}
          redirectToBadgeScreen={handleBadgeScreenRedirection}
          redirectToBadgeDetail={handleBadgeDetailRedirection}
        />
      )}
      {!loading && userData?.aboId && (
        <ChallengeMyCompositionCard
          registerUserData={userData?.registerUserData}
          weightOutUserData={userData?.weightOutUserData}
          teamDashboardStatus={challengeStatus}
          hasUserWeighOut={userData?.hasUserWeighOut}
          afterWeightOutPeriod={afterWeightOutPeriod}
        />
      )}
      {!loading &&
        challengeStatus !== ChallengeTeamdashboardStatus.not_yet_started && (
          <ChallengeMyCompositionChangeCard
            userData={userData}
            registerUserData={
              afterWeightOutPeriod && userData?.hasUserWeighOut
                ? userData?.weightOutUserData
                : userData?.dailyUserData
            }
            teamDashboardStatus={challengeStatus}
            showWeightOutTitle={
              afterWeightOutPeriod && teamData?.hasTeamWeighOut
            }
          />
        )}
      {!loading && (
        <div className="my-image-upload-card" ref={imageCardSectionRef}>
          <MyImageCard
            eventId={challengeId}
            redirectionEventIdQuery={eventId}
            partyId={partyId}
            userId={userData?.challengeUserId}
            aboId={userData?.aboId}
            isChallengePeriod={CHALLENGE_END_STATUS.includes(challengeStatus)}
            isUserValid={handleLoggedInBadgeRedirection}
            isAfterImageUpload={teamData?.challengeDay > 60 && teamData?.challengeDay <= 67}
          />
        </div>
      )}
      <div ref={rewardSection}>
        {(loading || notiLoader) && !syncLoader ? (
          <MyDashboardStepsLoader />
        ) : (
          <ChallengeMyReward
            userRewardData={userData?.myRewards}
            teamRewardData={teamData?.teamRewards}
          />
        )}
      </div>
      <div className='my-reward-card-container'>
        <DashboardFooter
          firstButtonTitle={
            CHALLENGE.myDashboard.dashboardFooterLeftBtnTitle
          }
          secondButtonTitle={
            CHALLENGE.myDashboard.dashboardFooterRightBtnTitle
          }
          onSecondBtnClick={handleMapRedirection}
          onFirstBtnClick={handleMissionPageRedirection}
          selfDashboard={true}
        /></div>
      <HnWSlideup
        bottomGap='95px'
        scrollHide={true} />
    </div>
  );
};

export default ChallengeMyDashboard;

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallengeDetail } from '../../../actions';
import { decodeHtml, isEmptyObject } from '../../../utill.func';
import { CalendarLoader, ChallengeDetailSlider } from '../../../common';
import { CHALLENGE_DETAIL, contentRegEx } from '../../../common/challengeConstants';
import { useLocation } from 'react-router-dom';

import './MissionDetail.scss';

const MissionDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const challengeId = params['challengeId'];
    const [isLoading, setIsLoading] = useState(false);
    const selectedChallenge = useSelector(
        (state) => state.challengeListObj?.selectedChallenge.challengeDetails,
    );

    const [titleClass, setTitleClass] = useState('');
    const [decodedContent, setDecodedContent] = useState("");

    const fetchDetail = async (id) => {
        setIsLoading(true);
        await dispatch(fetchChallengeDetail(id));
        setIsLoading(false);
    };

    const contentHeight = window.screen.height <= 730 ? CHALLENGE_DETAIL.minContentHeightSm : CHALLENGE_DETAIL.minContentHeight;

    useEffect(() => {
        if (!selectedChallenge || isEmptyObject(selectedChallenge)) {
            fetchDetail(challengeId);
        }
    }, []);

    useEffect(() => {
        if (selectedChallenge?.missionContent) {
            setDecodedContent(decodeHtml(selectedChallenge.missionContent));
        }
    }, [selectedChallenge]);

    const handleDrag = (contentHeight, contentY) => {
        if (contentHeight === CHALLENGE_DETAIL.maxContentHeight) {
            setTitleClass('mission-details-title-wrap');
        } else if (contentHeight <= CHALLENGE_DETAIL.minContentHeight) {
            setTitleClass('');
        }
    }

    return (
        <>
            {selectedChallenge && (
                <div className='mission-details-wrapper'>
                    <div className="mission-details">
                        <img className='mission-details-image' src={selectedChallenge?.challengeDetailImg} alt='mission-details-img' />
                        <ChallengeDetailSlider minHeight={contentHeight} defaultHeight={contentHeight} maxHeight={CHALLENGE_DETAIL.maxContentHeight} showGrab={false} styleObj={{ bottom: '10vh' }} onDrag={handleDrag}>
                            <div className='mission-details-content'>
                                <div className={`mission-details-title ${titleClass}`}>
                                    {selectedChallenge?.name}
                                </div>
                                <div className="mission-details-description">
                                    {selectedChallenge?.description}
                                </div>
                                <div className='mission-details-seperator'>
                                    <img src="/images/challenge/separator.svg" alt="Separator" />
                                </div>
                                <div className="mission-details-info">
                                    {contentRegEx.test(decodedContent) ? (
                                        <div dangerouslySetInnerHTML={{ __html: decodedContent }} />
                                    ) : (
                                        <pre>{decodedContent}</pre>
                                    )}
                                </div>
                            </div>
                        </ChallengeDetailSlider>
                    </div>
                </div>
            )}
            <CalendarLoader showLoading={isLoading} />
        </>
    );
};

export default MissionDetail;

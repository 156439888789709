import React, { useState } from 'react';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CircularChart from '../circularChart/CircularChart';
import { thaiDate } from '../../utill.func';
import variables from '../commonConstant.scss'
import './CustomCalendar.scss';

export default function CustomCalendar({ data,
  colorCircledark,
  colorCirclelight,
  color1Dot,
  color2Dot,
  activeStartDate,
  min,
  max,
  onDateChange,
  trackTotal,
  showCircleWithoutDataStrength = !trackTotal,
  showDataDots,
  showNavigation = false,
  ...props
}) {
  const currentDate = new Date();

  const renderCircularChartData = (inputDate) => {

    const calendarData = data.find(({ date }) => date?.getDate() === inputDate.getDate());
    const isInvertedBG = calendarData?.isInverted;
    return <>
      {inputDate <= currentDate ? <CircularChart
        fill={isInvertedBG && inputDate >= min ? colorCircledark : "none"}
        isInverted={isInvertedBG}
        data1Id={inputDate.getTime()}
        color1dark={inputDate < min || isInvertedBG ? variables.bmigrey : colorCircledark}
        color1light={inputDate < min || isInvertedBG ? variables.bmigrey : colorCirclelight}
        showOverflow={calendarData?.data1 > trackTotal}
        trackTotal={showCircleWithoutDataStrength ? 100 : trackTotal}
        track1Value={showCircleWithoutDataStrength ? (calendarData?.data1 || calendarData?.data2) && 100 : calendarData?.data1}
      /> : <div className="calendar-future-date-tile"></div>}
      {showDataDots && <div className="calendar-data-pointer-list">
        <div className="calendar-data-pointer" style={{ ...calendarData?.data1 ? { background: color1Dot } : {} }}></div>
        <div className="calendar-data-pointer" style={{ ...calendarData?.data2 ? { background: color2Dot } : {} }}></div>
      </div >}
    </>;
  };

  const handleCalenderData = ({ date, view }) =>
    view === 'month' && renderCircularChartData(date);
  return (
    <div className={`custom-calendar${showNavigation ? ' --show-navigation' : ''}`}>
      {!showNavigation &&
        <div className="custom-calendar-header">
          {thaiDate(new Date(min), 'LLLL yy')}
        </div>}
      <ReactCalendar
        locale="th"
        calendarType="gregory"
        activeStartDate={activeStartDate}
        minDate={min}
        maxDate={max}
        prevLabel={<img src='/images/common/Arrow-left.svg' alt="previous-month" />}
        nextLabel={<img src='/images/common/Arrow-right.svg' alt="next-month" />}
        showNeighboringMonth={false}
        tileContent={handleCalenderData}
        showNavigation={showNavigation}
        onChange={onDateChange}
        {...props}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { ChallengeNavButton, TeamRectCard } from "../../../../common";
import { CHALLENGE, CHALLENGE_BODY_CARD } from "../../../../common/challengeConstants";
import { roundOffDecimalVal } from "../../../../utill.func";
import './TeamBodyCard.scss';

const TeamBodyCard = ({
    teamRegisteredAvgData,
    teamWeightOutAvgData,
    teamDashboardStatus,
    afterWeightOutPeriod,
    hasTeamWeighOut,
    isChallengeOngoing
}) => {

    const [registeredData, setRegisteredData] = useState(teamRegisteredAvgData);

    useEffect(() => {
        if(isChallengeOngoing || afterWeightOutPeriod) {
            setRegisteredData(teamRegisteredAvgData);
        }
    },[teamDashboardStatus]);


    const setData = (index) => {
        const value = (index == 0) ? true: false;
        const data = value ? teamRegisteredAvgData : teamWeightOutAvgData;
        setRegisteredData(data);
    }

    const renderNavButtons = () => {
        return (
            <ChallengeNavButton
            tabList={CHALLENGE_BODY_CARD.HOME_TABS_LIST}
            onTabButtonChange={setData}
        />
        )
    }

    return (
        <div className="team-body-container">
            <div className="team-body-heading">{CHALLENGE.teamDashboard.teamBodyHeading}</div>
            {isChallengeOngoing || afterWeightOutPeriod ?
            renderNavButtons()
            : <div className="team-body-subheading">{CHALLENGE.teamDashboard.teamBodySubHeading}</div>}
            <div className="challenge-card-body">
                <TeamRectCard
                    title={CHALLENGE.teamDashboard.averageMuscleHeading}
                    quantity={registeredData?.teamAvgMuscle ? roundOffDecimalVal(registeredData?.teamAvgMuscle) : '-'}
                    unit={CHALLENGE.teamDashboard.kilogram}
                    bodyCard={true}
                ></TeamRectCard>
                <hr className="rounded-dividing-line"></hr>
                <TeamRectCard
                    title={CHALLENGE.teamDashboard.avergeFatHeading}
                    quantity={registeredData?.teamAvgFat ? roundOffDecimalVal(registeredData?.teamAvgFat) : '-'}
                    unit={CHALLENGE.teamDashboard.kilogram}
                    bodyCard={true}
                ></TeamRectCard>
                <hr className="rounded-dividing-line"></hr>
                <TeamRectCard
                    title={CHALLENGE.teamDashboard.averageWeightHeading}
                    quantity={registeredData?.teamAvgWeight ? roundOffDecimalVal(registeredData?.teamAvgWeight) : '-'}
                    unit={CHALLENGE.teamDashboard.kilogram}
                    bodyCard={true}
                ></TeamRectCard>
            </div>
        </div>
    )

}

export default TeamBodyCard;
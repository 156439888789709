import React from "react";
import { formatNumberWithCommas, getStepsBadgesRange, removeDisableForRedirection } from "../../../utill.func";
import { BadgeCarousel, ChallengeButton, ChallengeProgressBar } from "../../index";
import './BadgeRectCard.scss';
import { BUDDY_ME_MAP_DETAIL_MAPPING } from "../../challengeConstants";

const BadgeRectCard = ({ cards, title, badgeImage, badges, values, maxBadgeValue, unit, redirectToBadgeDetail, btnBadge, stepsBadgeCount, maxBadgeActiveValue }) => {

    const renderBadgeImage = () => {
        return (
            <div className="badge-img-container">
                <img src={`/images/challenge/badges/bg-circle-badge@5x.png`} className='badge-img-bg'/>
                <img src={badgeImage} className='badge-img' onClick={redirectToBadgeDetail}/>
            </div>
        )
    }

    const handleBadgeDetailRedirection = (badges, index, event) => {
        event.stopPropagation();
        if(index <= stepsBadgeCount) {
        const checkBadgePath = removeDisableForRedirection(badges);
        redirectToBadgeDetail(checkBadgePath, 'steps');
        }
    }

    const renderBadgesCarousel = () => {
        return (
            <div className="badge-carousel-container">
                <BadgeCarousel
                    data={badges}
                    onClick={handleBadgeDetailRedirection}
                />
            </div>
        )
    }

    const handleProgressOrTeamTotalBadges = (value, index, minValueOfBadge) =>
        index === stepsBadgeCount && (
            <ChallengeProgressBar percentage={getStepsBadgesRange(values, minValueOfBadge)} />
        );

    const renderStepBadgesCard = () => {
        return (
            <div className="my-all-badges-container">
                {badges.map((value, index) => {
                    return (
                        <div className={`my-badges-single`} key={index}>
                            <div className='img-badge-container'>
                                <img src={`/images/challenge/badges/bg-badge@5x.png`} />
                                <img className="badge-img" src={`/images/challenge/badges/steps-${value}-badge@5x.png`} alt="" onClick={(event) => {
                                    handleBadgeDetailRedirection(value, index, event);
                                }}
                                />
                            </div>
                            <p>{BUDDY_ME_MAP_DETAIL_MAPPING[value].thTitle}</p>
                            {handleProgressOrTeamTotalBadges(value, index, BUDDY_ME_MAP_DETAIL_MAPPING[badges[stepsBadgeCount]]?.minValueOfBadge)}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="badge-rect-container">
            <div className="badge-title-font">{title}</div>
            {cards ? renderBadgeImage() : renderStepBadgesCard()}
            <div className="badge-values">
                <span className={`badge-current-values ${maxBadgeActiveValue ? (values >= maxBadgeActiveValue ? 'green-font' : '') : (values > 0 ? 'green-font' : '')}`}>{formatNumberWithCommas(values)}</span>
                <span className="badge-total-values">{maxBadgeValue}</span>
                <span className="badge-unit">{unit}</span>
            </div>
            {btnBadge && <ChallengeButton btnTitle="ทำภารกิจ" successBtn={true} onBtnClick={() => { }} />}
        </div>
    )
}

export default BadgeRectCard;
import { BADGES_MILESTONE } from "../../challengeConstants";

const points = [
  {
    index: 0,
    top: "45",
    left: "77.7",
    subPoint: [
      { top: "45", left: "77.7" },
      { top: "45", left: "77.7" },
      { top: "45", left: "77.7" },
    ],
    type: "main",
    label: "amway",
    totalStep: 0,
  },
  {
    index: 1,
    top: '44.5',
    left: '72.4',
    subPoint: [
      { top: "45", left: "73.4" },
      { top: "45.5", left: "74.4" },
      { top: "46.3", left: "75.3" },
    ],
    type: "pin",
    totalStep: 500,
  },
  {
    index: 2,
    top: "44.5",
    left: "69.2",
    subPoint: [
      { top: "44.3", left: "70.3" },
      { top: "44.4", left: "71.4" },
      { top: "44.6", left: "72.5" },
    ],
    type: "pin",
    totalStep: 20000,
  },
  {
    index: 3,
    top: "45.8",
    left: "66.5",
    subPoint: [
      { top: "45.4", left: "67.4" },
      { top: "44.8", left: "68.3" },
      { top: "44.5", left: "69.2" },
    ],
    type: "pin",
    totalStep: 45000,
  },
  {
    index: 4,
    top: "45.8",
    left: "63.5",
    subPoint: [
      { top: "45.9", left: "64.5" },
      { top: "46", left: "65.5" },
      { top: "45.8", left: "66.5" },
    ],
    type: "pin",
    totalStep: 75000,
  },
  {
    index: 5,
    top: "39.2",
    left: "59.9",
    subPoint: [
      { top: "39.2", left: "59.9" },
      { top: "39.2", left: "59.9" },
      { top: "39.2", left: "59.9" },
    ],
    type: "main",
    label: BADGES_MILESTONE.badge1,
    totalStep: 100000,
    badgeY: 35,
  },
  {
    index: 6,
    top: "47.5",
    left: "51.2",
    subPoint: [
      { top: "45.6", left: "52.5" },
      { top: "44.8", left: "54" },
      { top: "44.7", left: "55.5" },
    ],
    type: "pin",
    totalStep: 100500,
  },
  {
    index: 7,
    top: "56.6",
    left: "45.4",
    subPoint: [
      { top: "54", left: "47" },
      { top: "51.3", left: "48.8" },
      { top: "48.5", left: "50.5" },
    ],
    type: "pin",
    totalStep: 125000,
  },
  {
    index: 8,
    top: "64.6",
    left: "40.4",
    subPoint: [
      { top: "62", left: "42" },
      { top: "59.3", left: "43.7" },
      { top: "56.6", left: "45.4" },
    ],
    type: "pin",
    totalStep: 150000,
  },
  {
    index: 9,
    top: "71.7",
    left: "34",
    subPoint: [
      { top: "70.6", left: "36.5" },
      { top: "67.6", left: "38.5" },
      { top: "64.6", left: "40.4" },
    ],
    type: "pin",
    totalStep: 175000,
  },
  {
    index: 10,
    top: "61",
    left: "29.2",
    subPoint: [
      { top: "61", left: "29.2" },
      { top: "61", left: "29.2" },
      { top: "61", left: "29.2" },
    ],
    type: "main",
    label: BADGES_MILESTONE.badge2,
    totalStep: 200000,
    badgeY: 35,
  },
  {
    index: 11,
    top: "54",
    left: "33.5",
    subPoint: [
      { top: "56.1", left: "33.2" },
      { top: "57.6", left: "32.2" },
      { top: "58.5", left: "30.9" },
    ],
    type: "pin",
    totalStep: 200500,
  },
  {
    index: 12,
    top: "46",
    left: "29",
    subPoint: [
      { top: "47.7", left: "30.2" },
      { top: "49.4", left: "31.4" },
      { top: "51.1", left: "32.6" },
    ],
    type: "pin",
    totalStep: 225000,
  },
  {
    index: 13,
    top: "37",
    left: "23.8",
    subPoint: [
      { top: "40", left: "24.6" },
      { top: "42", left: "26" },
      { top: "43.8", left: "27.3" },
    ],
    type: "pin",
    totalStep: 250000,
  },
  {
    index: 14,
    top: "31.8",
    left: "28.2",
    subPoint: [
      { top: "32.7", left: "26.8" },
      { top: "33.6", left: "25.3" },
      { top: "35.1", left: "24" },
    ],
    type: "pin",
    totalStep: 275000,
  },
  {
    index: 15,
    top: "23",
    left: "30.6",
    subPoint: [
      { top: "23", left: "30.6" },
      { top: "23", left: "30.6" },
      { top: "23", left: "30.6" },
    ],
    type: "main",
    label: BADGES_MILESTONE.badge3,
    totalStep: 300000,
    badgeY: 40,
  },
  {
    index: 16,
    top: "29.5",
    left: "37",
    subPoint: [
      { top: "28.8", left: "35.8" },
      { top: "28.2", left: "34.6" },
      { top: "27.9", left: "33.3" },
    ],
    type: "pin",
    totalStep: 300500,
  },
  {
    index: 17,
    top: "33.3",
    left: "43.7",
    subPoint: [
      { top: "32", left: "41.5" },
      { top: "31", left: "39.6" },
      { top: "30", left: "37.8" },
    ],
    type: "pin",
    totalStep: 335000,
  },
  {
    index: 18,
    top: "34",
    left: "49.5",
    subPoint: [
      { top: "35", left: "46.8" },
      { top: "34.1", left: "45.2" },
      { top: "33.3", left: "43.7" },
    ],
    type: "pin",
    totalStep: 370000,
  },
  {
    index: 19,
    top: "23.5",
    left: "50.9",
    subPoint: [
      { top: "23.5", left: "50.9" },
      { top: "23.5", left: "50.9" },
      { top: "23.5", left: "50.9" },
    ],
    type: "main",
    label: BADGES_MILESTONE.badge4,
    totalStep: 400000,
    badgeY: 45,
  },
  {
    index: 20,
    top: "29.8",
    left: "58.9",
    subPoint: [
      { top: "29.1", left: "57.5" },
      { top: "28.3", left: "56" },
      { top: "28.5", left: "54.5" },
    ],
    type: "pin",
    totalStep: 400500,
  },
  {
    index: 21,
    top: "32",
    left: "63.5",
    subPoint: [
      { top: "31.3", left: "61.9" },
      { top: "30.5", left: "60.4" },
      { top: "29.8", left: "58.9" },
    ],
    type: "pin",
    totalStep: 435000,
  },
  {
    index: 22,
    top: "32.5",
    left: "68.5",
    subPoint: [
      { top: "33.3", left: "66.9" },
      { top: "32.9", left: "65.2" },
      { top: "32", left: "63.5" },
    ],
    type: "pin",
    totalStep: 470000,
  },
  {
    index: 23,
    top: "27.5",
    left: "72.2",
    subPoint: [
      { top: "27.5", left: "72.2" },
      { top: "27.5", left: "72.2" },
      { top: "27.5", left: "72.2" },
    ],
    type: "main",
    label: BADGES_MILESTONE.badge5,
    totalStep: 500000,
    badgeY: 38,
  },
  {
    index: 24,
    top: "32.5",
    left: "80.5",
    subPoint: [
      { top: "33.2", left: "79.3" },
      { top: "33.7", left: "78.1" },
      { top: "34.4", left: "77" },
    ],
    type: "pin",
    totalStep: 500500,
  },
  {
    index: 25,
    top: "31.8",
    left: "83.5",
    subPoint: [
      { top: "31.8", left: "82.3" },
      { top: "32.1", left: "81.3" },
      { top: "32.7", left: "80.3" },
    ],
    type: "pin",
    totalStep: 535000,
  },
  {
    index: 26,
    top: "33.6",
    left: "86.2",
    subPoint: [
      { top: "32.7", left: "85.3" },
      { top: "32.1", left: "84.4" },
      { top: "31.8", left: "83.5" },
    ],
    type: "pin",
    totalStep: 570000,
  },
  {
    index: 27,
    top: '31.2',
    left: '88.3',
    subPoint: [
      { top: "31.2", left: "88.3" },
      { top: "31.2", left: "88.3" },
      { top: "31.2", left: "88.3" },
    ],
    type: "main",
    label: BADGES_MILESTONE.badge6,
    totalStep: 600000,
    badgeY: 43,
  },
];

export default points;

import { fetchCustomerBmi, updateCustomerBmi, fetchCustomerMonthlyBmi } from '../services/healthWellness/healthWellness.service';

import { GET_CUSTOMER_MONTHLY_BMI, GET_CUSTOMER_BMI, GET_LAST_MONTH_BMI, UPDATE_BMI, GET_MONTHLY_BMI_LIST, GET_CUSTOMER_BMI_FAILURE, GET_CUSTOMER_BMI_SUCCESS } from './types';

export const fetchBmiData = () => (dispatch) => {
  dispatch({ type: GET_CUSTOMER_BMI });

  return fetchCustomerBmi()
    .then(({ customers, ...rest }) => {
      dispatch({
        type: GET_CUSTOMER_BMI_SUCCESS,
        payload: rest,
      });
      return rest;
    })
    .catch((error) => {
      dispatch({
        type: GET_CUSTOMER_BMI_FAILURE,
        payload: error.message,
      });
      alert(error.message);
    });
};

export const updateBmiData = (healthInfo) => (dispatch) => updateCustomerBmi(healthInfo)
  .then(({ customers, ...rest }) => {
    dispatch({
      type: UPDATE_BMI,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchMonthlyBmiData = (startDate, endDate, isPrevious) => dispatch => fetchCustomerMonthlyBmi(startDate, endDate)
  .then(res => {
    dispatch({
      type: isPrevious ? GET_LAST_MONTH_BMI : GET_CUSTOMER_MONTHLY_BMI,
      payload: res,
    });
  })
  .catch(function (error) {
    alert(error.message);
  });

const distributeListAcrossMonthsArray = (BmiList) => {
  return Object.values(
    BmiList.reduce((acc, item) => {
      const date = new Date(item.createdDate); // Convert string date to Date object
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`; // Extract Year-Month key

      acc[monthKey] = acc[monthKey] || []; // Initialize array for each month
      acc[monthKey].push(item); // Push the object to its respective month
      return acc;
    }, {})
  );
}

export const fetchListMonthlyBmiData = (startDate, endDate) => dispatch =>
  fetchCustomerMonthlyBmi(startDate, endDate)
    .then(res => {
      dispatch({
        type: GET_MONTHLY_BMI_LIST,
        payload: distributeListAcrossMonthsArray(res),
      })
    })
    .catch(function (error) {
      alert(error.message);
    });



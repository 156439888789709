import { REGEX_YOUTUBE_ID } from "../common/commonConstant";

export class ChallengeVideo {
    constructor(data) {
        if (data) {
            this.link = data.link || '';
            this.videoId = data?.link ? extractVideoId(this.link) : '';
            this.date = data.date || '';
            this.status = data.status || false;
            this.stoppedAt = data.stoppedAt || 0;
            this.day = data.day || null;
            this.updated_at = data.updated_at || '';  
            this.youtubeDetails = [];       
        }
    }
}

const extractVideoId = (url) => {
    const regex = REGEX_YOUTUBE_ID;
    const match = url.match(regex);
    return match.length > 0 ? match[1] : null;
};


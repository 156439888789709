import React, { useRef, useState } from 'react';
import './ChallengeMyPictures.scss';
import { useEffect } from 'react';
import {
  PHOTOS_LIST_PAGE,
  PICTURE_BEFORE_AFTER_TITLE,
  PICTURE_BEFORE_TITLE,
  PICTURE_AFTER_TITLE,
  IMAGE_UPLOAD_LOADING_STATE,
  PICTURE_BEFORE_AFTER_SCREEN_TITLE,
} from '../../../../common/challengeConstants';
import MyPictureCard from '../../../../common/challenge/myPictureCard/MyPictureCard';
import { CommonAndroidWrapper } from '../../../../common/challenge/commonAndroidWrapper/CommonAndroidWrapper';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getChallengeImagesAction } from '../../../../actions/challenge/challengeImageUploadActions';
import MyDashboardStepsLoader from '../myDashboardLoader/MyDashboardStepsLoader';
import { commonConstant } from '../../../../common';
import { getParamsString } from '../../../../utill.func';

export default function ChallengeMyPictures() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = queryString.parse(location.search);
  const eventId = params['eventId'] || '';
  const partyId = params['partyId'] || '';
  const userId = params['userId'] || '';
  const aboId = params['aboId'] || '';
  const redirectionEventIdQuery = params['redirectionEventIdQuery'] || '';
  const [loading, setLoading] = useState(false);
  const { images } = useSelector((state) => state.challengeImageUpload);
  const { teamData } = useSelector((state) => state.challengeTeamDashboard);
  const androidFeatureRef = useRef(null);
  const imageRef = useRef(null);
  const [photos, setPhotos] = useState({
    before_left: null,
    before_center: null,
    before_right: null,
    after_left: null,
    after_center: null,
    after_right: null,
  });
  const [datetimeThaiObj, setDatetimeThaiObj] = useState({
    before_left: '',
    before_center: '',
    before_right: '',
    after_left: '',
    after_center: '',
    after_right: '',
  });
  const [photosLoading, setPhotosLoading] = useState(IMAGE_UPLOAD_LOADING_STATE);
  const handleImageUpload = androidFeatureRef?.current?.handleImageUpload;
  useEffect(() => {
    const onMountHandler = androidFeatureRef?.current?.onMountHandler;
    if (onMountHandler) {
      onMountHandler(images);
    }
  }, [images]);

  useEffect(() => {
    const didMount = async () => {
      if (eventId && userId) {
        try {
          setLoading(true);
          await dispatch(
            getChallengeImagesAction({
              eventId: eventId,
              userId: userId,
              scoreId: null,
            }),
          );
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    didMount();
  }, []);

  const handleHomeRedirection = () => {
    return history.push(commonConstant.pathHealthnWellness);
  };

  const handleMyDashboardRedirection = () => {
    const params = {
      challengeId: eventId,
      eventId: redirectionEventIdQuery,
      partyId: partyId,
    };
    return history.push(
      `${commonConstant.pathChallengeMyDashboard}?${getParamsString(params)}`,
    );
  };

  return (
    <div className="picture-after-before-page">
      <CommonAndroidWrapper
        eventId={eventId}
        partyId={partyId}
        aboId={aboId}
        userId={userId}
        redirectionEventIdQuery={redirectionEventIdQuery}
        ref={androidFeatureRef}
        imageRef={imageRef}
        photos={photos}
        setPhotos={setPhotos}
        setPhotosLoading={setPhotosLoading}
        setDatetimeThaiObj={setDatetimeThaiObj}
        componentInstance={'PICTURE_PAGE'}
      />
      <div className="challenge-picture-header-wrapper">
        <div className="container-fluid challenge-header-bottom">
          <div className="row d-flex align-items-center">
            <div className="col-2">
              <button
                className="btn-white-linear"
                onClick={handleMyDashboardRedirection}
              >
                <img
                  src="/images/challenge/icons/arrow-left.svg"
                  alt="arrow-left"
                />
              </button>
            </div>
            <div className="col-8 screen-title">
              <span>{PICTURE_BEFORE_AFTER_SCREEN_TITLE}</span>
            </div>
            <div className="col-2">
              <button
                className="btn-white-linear pull-right"
                onClick={handleHomeRedirection}
              >
                <img
                  src="/images/challenge/icons/icon-home-navigate.svg"
                  alt="icon-home-navigate"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="after-before-body-container">
          <h4 className="after-before-title">{PICTURE_BEFORE_AFTER_TITLE}</h4>
          <div className="after-before-header">
            <div className="after-before-header-col">
              {PICTURE_BEFORE_TITLE}
            </div>
            <div className="after-before-header-col">{PICTURE_AFTER_TITLE}</div>
          </div>
          <div className="picture-after-before-container">
            {PHOTOS_LIST_PAGE.map((photoPositionObj, id) => (
              <React.Fragment key={`${id}_${photoPositionObj.thaiLabel}`}>
                {loading ? (
                  <MyDashboardStepsLoader />
                ) : (
                  <MyPictureCard
                    photos={photos}
                    photosLoading={photosLoading}
                    photoPositionObj={photoPositionObj}
                    handleImageUpload={handleImageUpload}
                    datetimeThai={datetimeThaiObj}
                    isAfterImageUpload={teamData?.challengeDay > 60 && teamData?.challengeDay <= 67}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

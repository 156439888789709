import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import SearchBar from '../../../common/searchBar/searchBar';
import {
  FoodCaloriesHeader,
  FoodCategoryCarousel,
  FoodNavTabs,
  EmptyListComponent,
  CategoryPopup,
} from './components/';
import { FoodCategory } from '../../../model/FoodCategory.model';
import {
  fetchFoodCategories,
  fetchFoodProducts,
  resetFoodDetail,
} from '../../../actions/foodCalorieActions';
import {
  CATEGORY_TITLE,
  FOOD_TABS_MAPPING,
  SEARCH_TITLE,
  routePathKey,
  NO_FOOD_LIST_TEXT,
  SUPPLEMENT_LIST_MAPPING,
  OTHERS,
  foodTypeReferrers,
} from '../../../common/commonConstant';
import {
  CalendarLoader,
  FoodListingItem,
  InfiniteScroll
} from '../../../common';
import './FoodCalories.scss';
import noResultAnimation from '../../../json/no-food-result.json';
import { getParamsString } from '../../../utill.func';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

const FoodCalories = ({ routeTo, routeReplaceTo }) => {
  const dispatch = useDispatch();
  const pageSize = 10;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referrerPage = params.get('referrer');
  const referrerFrom = params.get('referrerFrom');
  const showSearch = params.get('showSearch');
  const supplementsGoalId = params.get('supplementsGoalId');
  const activeFlow = params.get('activeFlow');
  const suggestedFoodGoal = params.get('suggestedFoodGoal');

  const supplementPageSet = new Set([
    SUPPLEMENT_LIST_MAPPING.GOAL_REFERRER_VALUE,
    SUPPLEMENT_LIST_MAPPING.LOG_REFERRER_VALUE,
  ]);

  const isSupplementOnly = supplementPageSet.has(referrerPage);
  const isFromFoodLog = foodTypeReferrers.includes(referrerPage);
  const defaultTab = isSupplementOnly
    ? FOOD_TABS_MAPPING.Supplement.name
    : FOOD_TABS_MAPPING.Food.name;

  const myMenuCategory = new FoodCategory({
    _id: OTHERS,
    name: 'เมนูของฉัน',
    imageUrl:
      '/images/healthAndWellness/foodCalories/food-category/cat_my_menu.svg',
  });
  const defaultCategory = new FoodCategory({
    _id: 'all',
    name: 'ทั้งหมด',
    imageUrl:
      '/images/healthAndWellness/foodCalories/food-category/cat-all.svg',
  });
  const noCategorySelectedValue = new FoodCategory({ _id: '', name: '' });

  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [pageStart, setPageStart] = useState({
    [FOOD_TABS_MAPPING.Food.name]: 0,
    [FOOD_TABS_MAPPING.Supplement.name]: 0,
  });
  const [isCategoryPopupOpen, setCategoryPopupOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    isSupplementOnly ? noCategorySelectedValue : defaultCategory,
  );
  const categoriesData = useSelector(
    (state) => state?.foodCalorie?.foodCategories,
  );
  const foodListData = useSelector((state) => state?.foodCalorie?.food);
  const supplementListData = useSelector(
    (state) => state?.foodCalorie?.supplement,
  );
  const [activeTab, setActiveTab] = useState(defaultTab);
  const navTabListContainerElem = useRef(null);
  const isCallbackCalled = useRef(false);
  const customerData = useSelector((state) => state.customer);

  const categoryList = [
    myMenuCategory,
    defaultCategory,
    ...categoriesData,
  ].sort((a, b) => a.order - b.order);

  const handleSelectPopupCategory = (category) => {
    setSelectedCategory(category);
    setCategoryPopupOpen(false);
  };

  useEffect(() => {
    if (customerData?.amwayNumber) {
      checkForTnC();
    }
  }, [customerData.amwayNumber]);

  const checkForTnC = () => {
    if (!customerData.isTncAck) {
      routeTo('pathTermsConditions', '?path=pathFoodCalories');
    }
  };

  useEffect(() => {
    setLoading(true);
    setSelectedCategory(
      activeTab === FOOD_TABS_MAPPING.Supplement.name
        ? noCategorySelectedValue
        : selectedCategory?.id
          ? selectedCategory
          : defaultCategory,
    );
    setPageStart({ ...pageStart, [activeTab]: 0 });
  }, [activeTab]);

  const fetchFoodList = () => {
    setPageStart((start) => {
      setListLoading(true);
      !isCallbackCalled.current &&
        dispatch(
          fetchFoodProducts({
            ...(selectedCategory.id === noCategorySelectedValue.id
              ? {}
              : { categoryId: selectedCategory.id }),
            type:
              selectedCategory.id !== noCategorySelectedValue.id
                ? FOOD_TABS_MAPPING.Food.name
                : FOOD_TABS_MAPPING.Supplement.name,
            pageOffset: pageSize,
            pageNo: start[activeTab],
          }),
        ).finally(() => {
          setListLoading(false);
          setLoading(false);
          isCallbackCalled.current = false;
        });
      isCallbackCalled.current = true;
      return { ...start, [activeTab]: start[activeTab] + pageSize };
    });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchFoodCategories()).catch(() => {
      setLoading(false);
    });
    dispatch(resetFoodDetail());
  }, []);

  useEffect(() => {
    setLoading(true);
    selectedCategory.id && setActiveTab(FOOD_TABS_MAPPING.Food.name);
    setPageStart({ ...pageStart, [activeTab]: 0 });
    fetchFoodList();
  }, [selectedCategory.id]);

  const handleSearchClick = (e) => {
    e.preventDefault();
    let pathParamsObj = {
      openSearch: true,
    };
    if (referrerPage) {
      pathParamsObj = {
        ...pathParamsObj,
        referrer: referrerPage,
        ...(referrerFrom && { referrerFrom }),
        ...(suggestedFoodGoal && { suggestedFoodGoal }),
      };
    }
    const params = getParamsString(pathParamsObj);
    if (isSupplementOnly) {
      if (+showSearch === 1) {
        routeTo(routePathKey.searchHistory, `?${params}`);
      } else if (parseInt(activeFlow) === 1) {
        routeReplaceTo(
          routePathKey.goalsSearchHistory,
          `?${params}&activeFlow=${activeFlow}`,
        );
      } else {
        routeTo(routePathKey.goalsSearchHistory, `?${params}`);
      }
    } else {
      if (parseInt(activeFlow) === 1) {
        routeReplaceTo(
          routePathKey.searchHistory,
          `?${params}&activeFlow=${activeFlow}`,
        );
      } else {
        routeTo(routePathKey.searchHistory, `?${params}`);
      }
    }
  };

  const handleClick = (item) => {
    let pathParamsObj = {
      id: item.id,
    };
    if (isSupplementOnly || isFromFoodLog) {
      pathParamsObj = {
        ...pathParamsObj,
        referrer: referrerPage,
        ...(referrerFrom && { referrerFrom }),
        ...(supplementsGoalId && { supplementsGoalId }),
        ...(suggestedFoodGoal && { suggestedFoodGoal }),
      };
    }
    const params = getParamsString(pathParamsObj);
    if (parseInt(activeFlow) === 1) {
      routeReplaceTo(
        routePathKey.foodDetail,
        `?${params}&activeFlow=${activeFlow}`,
      );
    } else {
      routeTo(routePathKey.foodDetail, `?${params}`);
    }
  };

  const ListingDataMapping = {
    [FOOD_TABS_MAPPING.Food.name]: foodListData.data,
    [FOOD_TABS_MAPPING.Supplement.name]: supplementListData.data,
  };

  const renderProductTabsObj = useMemo(() => {
    let recordsTotal = foodListData.recordsTotal;
    let loadedDataLength = foodListData.data.length;
    if (activeTab === FOOD_TABS_MAPPING.Supplement.name) {
      recordsTotal = supplementListData.recordsTotal;
      loadedDataLength = supplementListData.data.length;
    }
    const shouldLoadData = !loadedDataLength || recordsTotal > loadedDataLength;

    return Object.entries(ListingDataMapping).map(
      ([listingName, listingItem]) => (
        <div
          key={listingName}
          className={`tab-pane fade${activeTab === listingName ? ' active show' : ''
            }`}
          id={`nav-${listingName}`}
          role="tabpanel"
          aria-labelledby={`nav-${listingName}-tab`}
        >
          <div className="dietary-supplement-section-wrapper">
            {listingItem.length ? (
              <InfiniteScroll
                items={listingItem}
                loading={listLoading}
                itemComponent={FoodListingItem}
                itemProps={{ handleClick }}
                shouldFetchData={shouldLoadData}
                fetchData={fetchFoodList}
              />
            ) : (
              <EmptyListComponent
                emptyImg={noResultAnimation}
                heading={NO_FOOD_LIST_TEXT.HEADING}
                descriptionTop={NO_FOOD_LIST_TEXT.DESCRIPTION_TOP}
                descriptionBottom={NO_FOOD_LIST_TEXT.DESCRIPTION_BOTTOM}
              />
            )}
          </div>
        </div>
      ),
    );
  }, [foodListData, supplementListData, listLoading]);

  return (
    <div className="food-calories-container">
      {!loading && (
        <div className={`food-list-page${isCategoryPopupOpen ? ' --no_scroll' : ''
          } ${isSupplementOnly ? 'supplement-only-list-container' : ''}`}>
          <FoodCaloriesHeader
            routeTo={routeTo}
            isSupplementGoalHeader={isSupplementOnly}
            referrerPage={referrerPage}
          />
          <div
            className={
              isSupplementOnly
                ? 'supplement-only-list-container__details'
                : 'container-fluid p-0 banner-container'
            }
          >
            <div className="search-img-container">
              <div className="container-fluid">
                <h6 className="search-title">
                  {isSupplementOnly
                    ? SUPPLEMENT_LIST_MAPPING.ITEM_LIST_HEADER
                    : SEARCH_TITLE}
                </h6>
                <SearchBar
                  handleSearchClick={handleSearchClick}
                  {...(isSupplementOnly && {
                    placeholder: SUPPLEMENT_LIST_MAPPING.SEARCH_PLACEHOLDER,
                  })}
                />
              </div>
            </div>
            {!isSupplementOnly && (
              <div className="category-container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="category-title">{CATEGORY_TITLE}</div>
                      <div className="category-container slick-slider">
                        <FoodCategoryCarousel
                          data={categoryList}
                          selectedCategory={selectedCategory}
                          onClick={setSelectedCategory}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                isSupplementOnly
                  ? 'supplement-only-list-container__details-items'
                  : 'supplement-list-container'
              }
              ref={navTabListContainerElem}
            >
              {!isSupplementOnly && (
                <FoodNavTabs
                  handleSearchClick={handleSearchClick}
                  activeTab={activeTab}
                  parentRef={navTabListContainerElem}
                  onMenuClick={() => setCategoryPopupOpen(true)}
                  onTabClick={setActiveTab}
                />
              )}
              <div className="tab-content" id="nav-tabContent">
                {renderProductTabsObj}
              </div>
            </div>
          </div>
        </div>
      )}
      <CalendarLoader showLoading={loading} />
      {!isSupplementOnly && (
        <CategoryPopup
          data={categoryList}
          isOpen={isCategoryPopupOpen}
          onClose={() => setCategoryPopupOpen(false)}
          onClick={handleSelectPopupCategory}
          selectedCategory={selectedCategory}
        />
      )}
    </div>
  );
};

export default FoodCalories;

import React from 'react';
import "./ChallengeVideoHeader.scss";
import { CHALLENGE } from '../../../../common/challengeConstants';

const ChallengeVideoHeader = ({ handleSeeAll, isQuizAnswered }) => {

  return (
    <>
      <div className={`quiz-video-title-wrapper ${isQuizAnswered === CHALLENGE.quizStatus.review ? 'title-padding' : ''}`}>
        <div className="quiz-video-title">
          {
            isQuizAnswered === CHALLENGE.quizStatus.review
              ? CHALLENGE.challengeQuiz.quizReviewHeading
              : CHALLENGE.challengeQuiz.quizVideoText
          }
        </div>
        {
          isQuizAnswered !== CHALLENGE.quizStatus.review &&
          <button onClick={() => handleSeeAll()} className="btn-light-green btn-sm btn-rounded">
            {CHALLENGE.challengeQuiz.seeAllText}
          </button>
        }
      </div>
    </>
  );
};

export default ChallengeVideoHeader;
